import { Card, CardBody, CardHeader, Stack, Text } from "@chakra-ui/react";
import { Box } from "@chakra-ui/layout";
import { HStack } from "@chakra-ui/layout";
import ReactJson from "react-json-view";
import Collapsible from "./Collapsible";
import { MdOutput } from "react-icons/md";
import CollapsibleTooltipButton from "./CollapsibleTooltipButton";

export const ResultPreview: React.FC<{ result: unknown; payload: unknown }> = ({ result, payload }) => {
  return (
    <Card cursor="pointer" borderWidth={1} borderColor={"gray.700"} borderRadius="none">
      <Collapsible
        defaultExpanded={true}
        renderHeader={({ isExpanded, toggle }) => (
          <CardHeader backgroundColor={"green.800"} py={1}>
            <HStack justifyContent="space-between">
              <HStack>
                <MdOutput />
                <Text color={"gray.200"} size="sm">
                  Result
                </Text>
              </HStack>
              <CollapsibleTooltipButton
                isExpanded={isExpanded}
                toggle={toggle}
                collapseLabel="Collapse Inputs"
                expandLabel="Expand Inputs"
              />
            </HStack>
          </CardHeader>
        )}
        body={
          <CardBody overflow={"auto"} py={2} backgroundColor={"gray.800"} px={2}>
            <HStack alignItems={"flex-start"}>
              <Box p={3} w="50%" borderWidth={"thin"} minH="24">
                <ReactJson
                  style={{ backgroundColor: "transparent" }}
                  src={result || {}}
                  theme={"google"}
                  name="response"
                  collapsed={true}
                />
              </Box>
              <Box p={3} w="50%" borderWidth={"thin"} minH="24">
                <ReactJson
                  style={{ backgroundColor: "transparent" }}
                  src={payload || {}}
                  theme={"google"}
                  collapsed={true}
                  name="payload"
                />
              </Box>
            </HStack>
          </CardBody>
        }
      />
    </Card>
  );
};
