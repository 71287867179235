import React, { memo, PropsWithChildren } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { BaseNodeWithChildren } from "./base/BaseNode";
import { Stack } from "@chakra-ui/react";

const StartNode: React.FC<NodeProps<NodeType>> = (props) => {
  return <StartNodeWithChildren {...props} />;
};

export const StartNodeWithChildren: React.FC<NodeProps<NodeType> & PropsWithChildren> = ({ children, ...props }) => {
  return (
    <>
      <BaseNodeWithChildren {...props}>
        <Stack p={2}>
          {children && children}
        </Stack>
      </BaseNodeWithChildren>
    </>
  );
};

export default memo(StartNode);
