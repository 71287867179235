import React, { memo, useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  IconButton,
  Spinner,
  Icon,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { decodeTime } from "ulid";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { AiRequestLogEntry, AiWorkflowRunListResult } from "@worldwidewebb/client-ai";
import ReactJson from "react-json-view";
import { useReactFlow } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { useAiWorkflowRunsContext } from "../../../context/AiWorkflowRunsContext";
import { AxiosError } from "axios";
import { MdInput, MdKeyboard, MdMessage, MdOutput, MdPlayArrow, MdVideogameAsset } from "react-icons/md";
import { AiNpcWorkflowContext } from "@worldwidewebb/client-ai";
import useAi, { AiEditorWorkflowRun } from "../../../api/useAi";
import CollapsibleTooltipButton from "../../reactFlow/CollapsibleTooltipButton";
import Collapsible from "../../reactFlow/Collapsible";

const WorkflowRun = ({ runInfo: { workflowRunId, hasCompleted } }: { runInfo: AiWorkflowRunListResult }) => {
  const { getWorkflowRun, getRunRequestLog } = useAi();
  const { setDebbugedNode, debuggedNode, executeWorkflowMutation } = useAiWorkflowRunsContext();
  const { getNode } = useReactFlow<NodeType>();

  const [isWorkflowRunLoading, setIsWorkflowRunLoading] = useState<boolean>(false);
  const [workflowRunError, setWorkflowRunError] = useState<Error | null>(null);
  const [workflowRun, setWorkflowRun] = useState<AiEditorWorkflowRun | null>(null);

  const [nodeLabelsMap, setNodeLabelsMap] = useState<Record<string, string>>({});
  const [areRequestLogsLoading, setAreRequestLogsLoading] = useState<boolean>(false);
  const [requestLogsError, setRequestLogsError] = useState<Error | null>(null);
  const [requestLogsMap, setRequestLogsMap] = useState<Record<string, AiRequestLogEntry>>({});
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const fetchWorkflowRun = useCallback((runId: string) => {
    setIsWorkflowRunLoading(true);

    return getWorkflowRun(runId)
      .then((workflowRun) => {
        setWorkflowRun(workflowRun);
        return workflowRun;
      })
      .catch((error) => setWorkflowRunError(error))
      .finally(() => setIsWorkflowRunLoading(false));
  }, []);

  const onReRunClick = useCallback(async () => {
    const workflowToReRun = !workflowRun ? await fetchWorkflowRun(workflowRunId) : workflowRun;
    executeWorkflowMutation.mutate({
      context: workflowToReRun?.context as AiNpcWorkflowContext,
      runId: workflowRunId,
    });
  }, []);

  useEffect(() => {
    if (!isExpanded) {
      return;
    }

    fetchWorkflowRun(workflowRunId);
  }, [isExpanded]);

  useEffect(() => {
    if (!workflowRun) {
      return;
    }

    setAreRequestLogsLoading(true);

    const nodeIds = (workflowRun?.nodes || [])
      .filter(({ nodeId }) => getNode(nodeId)?.type === "CreateAiCompletionNode")
      .map(({ nodeId }) => nodeId);

    Promise.all(
      nodeIds.map((nodeId) =>
        getRunRequestLog(workflowRun.systemId, nodeId).catch((e) => {
          const err = e as AxiosError;

          if (err.isAxiosError && err.status === 404) {
            return;
          }

          throw e;
        })
      )
    )
      .then((requestLogs) =>
        setRequestLogsMap(
          requestLogs.reduce(
            (acc, requestLog) => (requestLog ? { ...acc, [requestLog.nodeId]: requestLog } : acc),
            {} as Record<string, AiRequestLogEntry>
          )
        )
      )
      .catch((error) => setRequestLogsError(error))
      .finally(() => setAreRequestLogsLoading(false));
  }, [workflowRun]);

  useEffect(() => {
    setNodeLabelsMap(
      (workflowRun?.nodes || []).reduce((acc, { nodeId }) => {
        const node = getNode(nodeId);

        return {
          ...acc,
          [nodeId]: node?.data.label || nodeId,
        };
      }, {})
    );
  }, [workflowRun]);

  const transformChoicesMessageContentToObject = useCallback((obj: { [key: string]: unknown }) => {
    const newObj = { ...obj };

    if (!Array.isArray(newObj.choices)) {
      return newObj;
    }

    for (const choice of newObj.choices) {
      if (!choice.message) {
        continue;
      }

      try {
        choice.message.content = JSON.parse(choice.message.content as string);
      } catch (e) {
        continue;
      }
    }

    return newObj;
  }, []);

  const errorNodeId = workflowRun?.errorData?.nodeId as string;

  useEffect(() => {
    if (errorNodeId && errorNodeId !== "unknown") {
      setDebbugedNode({ nodeId: errorNodeId, hasFailed: true });
    }
  }, [errorNodeId]);

  const isMutationPendingForRun = executeWorkflowMutation.isPending || isWorkflowRunLoading || hasCompleted === false;

  const tryToParseJsonStringValuesToObject = (obj: { [key: string]: unknown }) => {
    if (typeof obj !== "object") {
      return obj;
    }

    return Object.keys(obj).reduce((acc, key) => {
      if (typeof obj[key] === "string") {
        try {
          acc[key] = JSON.parse(obj[key] as string);
        } catch (e) {
          acc[key] = obj[key];
        }
      }

      if (typeof obj[key] === "object") {
        acc[key] = tryToParseJsonStringValuesToObject(obj[key] as { [key: string]: unknown });
      }

      return acc;
    }, {} as { [key: string]: unknown });
  };

  return (
    <>
      <Stack px={4} py={2}>
        <Stack direction={"row"} flex="1" justifyContent="space-between" flexWrap="wrap" alignItems={"center"}>
          <Box as="div" textAlign="left" w="72">
            {workflowRunId}
          </Box>
          <Box as="div" color="gray.500" fontSize="sm" w="28">
            {new Date(decodeTime(workflowRunId)).toLocaleString()}
          </Box>
          <Tooltip label={"Re-run"}>
            <IconButton
              color={"white"}
              icon={isMutationPendingForRun ? <Spinner size={"sm"} color={"white"} /> : <Icon as={MdPlayArrow} />}
              aria-label={"expand"}
              onClick={onReRunClick}
              disabled={isMutationPendingForRun}
            />
          </Tooltip>
          <Tooltip label={isExpanded ? "Collapse" : "Expand"}>
            <IconButton
              color={"white"}
              icon={
                isMutationPendingForRun ? (
                  <Spinner size={"sm"} color={"white"} />
                ) : isExpanded ? (
                  <MinusIcon fontSize="12px" />
                ) : (
                  <AddIcon fontSize="12px" />
                )
              }
              disabled={isMutationPendingForRun}
              aria-label={"expand"}
              onClick={() => setIsExpanded(!isExpanded)}
            />
          </Tooltip>
        </Stack>
      </Stack>
      {isExpanded && (
        <Stack backgroundColor={"gray.900"}>
          {isWorkflowRunLoading && <Spinner size={"md"} color={"white"} />}
          {!isWorkflowRunLoading && workflowRunError && (
            <HStack p={4}>
              <Text color={"white"} casing={"uppercase"}>
                {workflowRunError.message}
              </Text>
            </HStack>
          )}
          {!isWorkflowRunLoading && !workflowRunError && (
            <Accordion allowToggle defaultIndex={[1]}>
              <AccordionItem>
                <h2>
                  <AccordionButton backgroundColor={"cyan.900"}>
                    <Box as="span" flex="1" textAlign="left">
                      Context
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <ReactJson
                    style={{ backgroundColor: "transparent" }}
                    src={workflowRun?.context ?? {}}
                    theme={"google"}
                  />
                </AccordionPanel>
              </AccordionItem>
              {workflowRun?.error && (
                <AccordionItem>
                  <h2>
                    <AccordionButton
                      backgroundColor={"red.800"}
                      onClick={() =>
                        setDebbugedNode({
                          nodeId: errorNodeId,
                          hasFailed: true,
                        })
                      }
                    >
                      <Box as="span" flex="1" textAlign="left">
                        Error
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <ReactJson
                      style={{ backgroundColor: "transparent" }}
                      src={
                        tryToParseJsonStringValuesToObject(workflowRun.errorData as { [key: string]: unknown }) ?? {
                          message: workflowRun.error,
                        }
                      }
                      theme={"google"}
                    />
                  </AccordionPanel>
                </AccordionItem>
              )}
              <AccordionItem>
                <h2>
                  <AccordionButton backgroundColor={"purple.800"}>
                    <Box as="span" flex="1" textAlign="left">
                      Nodes
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} pt={0} px={0}>
                  {workflowRun?.nodes.map((node, i) => (
                    <Card
                      key={`node-${i}`}
                      cursor="pointer"
                      _hover={{
                        borderColor: node.nodeId === debuggedNode?.nodeId ? undefined : "purple.300",
                        opacity: 1,
                      }}
                      borderWidth={2}
                      borderColor={node.nodeId === debuggedNode?.nodeId ? "indigo.600" : "gray.500"}
                      borderRadius="none"
                      opacity={node.nodeId === debuggedNode?.nodeId ? 1 : 0.6}
                      onClick={() => setDebbugedNode({ nodeId: node.nodeId, hasFailed: node.nodeId === errorNodeId })}
                    >
                      <CardHeader backgroundColor={"purple.800"} py={2} px={2}>
                        <Stack
                          direction={"row"}
                          flex="1"
                          justifyContent="space-between"
                          flexWrap="nowrap"
                          alignItems={"baseline"}
                        >
                          <Box as="div" color={"white"}>
                            #{`${i + 1} `}
                            {nodeLabelsMap[node.nodeId]}
                          </Box>
                          <Box as="div" color={"gray.200"} fontSize={"xs"}>
                            {node.nodeId}
                          </Box>
                        </Stack>
                      </CardHeader>
                      <CardBody p={0}>
                        <Card>
                          <Collapsible
                            renderHeader={({ isExpanded, toggle }) => (
                              <CardHeader backgroundColor={"gray.700"} py={1}>
                                <HStack justifyContent="space-between">
                                  <HStack>
                                    <MdInput />
                                    <Text color={"gray.200"} size="sm">
                                      Inputs
                                    </Text>
                                  </HStack>
                                  <CollapsibleTooltipButton
                                    isExpanded={isExpanded}
                                    toggle={toggle}
                                    collapseLabel="Collapse Inputs"
                                    expandLabel="Expand Inputs"
                                  />
                                </HStack>
                              </CardHeader>
                            )}
                            body={
                              <CardBody overflow={"auto"} py={2} backgroundColor={"gray.800"} px={2}>
                                {Object.keys(node.inputs || {}).length < 1 && <Text color={"white"}>No inputs</Text>}
                                {Object.keys(node.inputs || {}).map((key, i) => (
                                  <Box key={`node-input-${i}`} color={"white"}>
                                    {key}:{" "}
                                    {typeof node.inputs?.[key] === "object" ? (
                                      <ReactJson
                                        style={{ backgroundColor: "transparent" }}
                                        src={node.inputs?.[key] ?? {}}
                                        theme={"google"}
                                      />
                                    ) : (
                                      JSON.stringify(node.inputs?.[key])
                                    )}
                                  </Box>
                                ))}
                              </CardBody>
                            }
                          />
                        </Card>
                        <Card>
                          <Collapsible
                            renderHeader={({ isExpanded, toggle }) => (
                              <CardHeader backgroundColor={"gray.800"} py={1}>
                                <HStack justifyContent="space-between">
                                  <HStack>
                                    <MdOutput />
                                    <Text color={"gray.200"} size="sm">
                                      Outputs
                                    </Text>
                                  </HStack>
                                  <Tooltip label={isExpanded ? "Collapse" : "Expand"}>
                                    <CollapsibleTooltipButton
                                      isExpanded={isExpanded}
                                      toggle={toggle}
                                      collapseLabel="Collapse Outputs"
                                      expandLabel="Expand Outputs"
                                    />
                                  </Tooltip>
                                </HStack>
                              </CardHeader>
                            )}
                            body={
                              <CardBody overflow={"auto"} py={2} backgroundColor={"gray.900"} px={2}>
                                {Object.keys(node.outputs || {}).length < 1 && <Text color={"white"}>No outputs</Text>}
                                {Object.keys(node.outputs || {}).map((key, i) => (
                                  <Box key={`node-output-${i}`} color={"white"}>
                                    {key}:{" "}
                                    {typeof node.outputs?.[key] === "object" ? (
                                      <ReactJson
                                        style={{ backgroundColor: "transparent" }}
                                        src={node.outputs?.[key] ?? {}}
                                        theme={"google"}
                                      />
                                    ) : (
                                      JSON.stringify(node.outputs?.[key])
                                    )}
                                    {node.outputs === undefined && "No outputs"}
                                  </Box>
                                ))}
                              </CardBody>
                            }
                          />
                        </Card>
                        {requestLogsMap[node.nodeId] && (
                          <Card>
                            <Collapsible
                              renderHeader={({ isExpanded, toggle }) => (
                                <CardHeader backgroundColor={"cyan.900"} py={1}>
                                  <HStack justifyContent={"space-between"}>
                                    <HStack>
                                      <MdKeyboard />
                                      <Text color={"gray.200"} size="sm">
                                        Prompt
                                      </Text>
                                    </HStack>
                                    <HStack>
                                      <Text color={"gray.200"} size="sm" fontStyle={"italic"}>
                                        Execution Time: {requestLogsMap[node.nodeId].timeMS}ms
                                      </Text>
                                      <CollapsibleTooltipButton
                                        isExpanded={isExpanded}
                                        toggle={toggle}
                                        collapseLabel="Collapse Prompt Info"
                                        expandLabel="Expand Prompt Info"
                                      />
                                    </HStack>
                                  </HStack>
                                </CardHeader>
                              )}
                              body={
                                <CardBody backgroundColor={"gray.900"} p={0}>
                                  <Box backgroundColor={"blackAlpha.900"} p={2}>
                                    <ReactJson
                                      style={{ backgroundColor: "transparent" }}
                                      src={{
                                        promptId: requestLogsMap[node.nodeId].promptId,
                                        promptVersion: requestLogsMap[node.nodeId].promptVersion,
                                        forceStop: requestLogsMap[node.nodeId].prompt.forceStop,
                                        model: requestLogsMap[node.nodeId].prompt.model,
                                        temperature: requestLogsMap[node.nodeId].prompt.temperature,
                                        topP: requestLogsMap[node.nodeId].prompt.topP,
                                        maxTokens: requestLogsMap[node.nodeId].prompt.maxTokens,
                                      }}
                                      theme={"google"}
                                    />
                                  </Box>
                                  <Stack backgroundColor={"blackAlpha.500"} alignContent={"flex-start"}>
                                    <HStack backgroundColor={"facebook.900"} px={4}>
                                      <MdMessage />
                                      <Text color={"gray.200"} fontWeight="bold" size="sm" pt={2} pb={1}>
                                        Prompt Messages:
                                      </Text>
                                    </HStack>
                                    <Stack fontSize={"sm"} style={{ marginTop: 0 }}>
                                      {(requestLogsMap[node.nodeId].prompt?.messages || []).map(
                                        (message: { role: string; name?: string; content: string }, index: number) => (
                                          <Stack
                                            key={`prompt-msg-${index}`}
                                            backgroundColor={"blackAlpha.500"}
                                            style={{ marginTop: 0 }}
                                          >
                                            <HStack backgroundColor={"gray.800"} px={4} py={2}>
                                              <Text color={"gray.200"}>#{index + 1}</Text>
                                              <HStack>
                                                <Text color={"gray.200"}>Role:</Text>
                                                <Text color={"cyan.600"}>{message.role}</Text>
                                              </HStack>
                                              <HStack>
                                                <Text color={"gray.200"}>Name:</Text>
                                                <Text color={"pink.400"}>{message.name ?? "<empty>"}</Text>
                                              </HStack>
                                            </HStack>
                                            <Box p={2}>
                                              <Box maxH={80} overflowY={"auto"} color={"ButtonText"}>
                                                <pre
                                                  style={{ whiteSpace: "pre-wrap", fontSize: "0.9em" }}
                                                  dangerouslySetInnerHTML={{
                                                    __html: message.content.replace(/\n/g, "<br />"),
                                                  }}
                                                ></pre>
                                              </Box>
                                            </Box>
                                          </Stack>
                                        )
                                      )}
                                    </Stack>
                                  </Stack>
                                  <Box>
                                    <HStack backgroundColor={"green.900"} px={4}>
                                      <MdVideogameAsset />
                                      <Text color={"gray.200"} fontWeight="bold" size="sm" pt={2} pb={1}>
                                        AI Response:
                                      </Text>
                                    </HStack>
                                    <Box p={2}>
                                      <ReactJson
                                        style={{ backgroundColor: "transparent" }}
                                        src={transformChoicesMessageContentToObject(
                                          requestLogsMap[node.nodeId].response
                                        )}
                                        theme={"google"}
                                      />
                                    </Box>
                                  </Box>
                                </CardBody>
                              }
                            />
                          </Card>
                        )}
                      </CardBody>
                    </Card>
                  ))}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
        </Stack>
      )}
    </>
  );
};

export default memo(WorkflowRun);
