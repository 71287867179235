import { useRouteError } from "react-router";
import { Button, Card, CardBody, CardHeader, Center, ChakraProvider, Stack, Text } from "@chakra-ui/react";
import { theme } from "@worldwidewebb/frontend-components";
import { Link } from "react-router-dom";

const Error = () => {
  const {
    data: { message, details },
    status,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useRouteError() as any;

  let ErrorInstructions = () => (
    <Stack>
      <Text casing={"uppercase"} color={"white"}>
        Solution
      </Text>
      <Text>Contact Army</Text>
    </Stack>
  );

  if (status === 401) {
    ErrorInstructions = () => (
      <Stack>
        <Text casing={"uppercase"} color={"white"}>
          Solution
        </Text>
        <Text>Please log into the game in the current browser, otherwise contact Army</Text>
        <Button as={Link} to={"/login"}>
          Login
        </Button>
      </Stack>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <Center minH={"100vh"} bg={"theme.dark.background"}>
        <Card p={8}>
          <CardHeader>
            <Text casing={"uppercase"} color={"white"} fontWeight={700}>
              Something went wrong!
            </Text>
          </CardHeader>
          <CardBody>
            <Stack>
              <Text casing={"uppercase"} color={"white"}>
                Details
              </Text>
              <Text>&times; {message}</Text>
              <Text>&times; {details}</Text>

              <ErrorInstructions />
            </Stack>
          </CardBody>
        </Card>
      </Center>
    </ChakraProvider>
  );
};

export default Error;
