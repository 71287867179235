import {
  Box,
  Flex,
  Heading,
  Text,
  Card,
  CardHeader,
  CardBody,
  Stack,
  StackDivider,
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EmailCredentials } from "@worldwidewebb/client-auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { authApi } from "../api";

export default function Login() {
  const { register, handleSubmit, formState } = useForm<{ otp: string; email: string }>();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const requestEmailOtpMutation = useMutation({
    mutationFn: ({ email }: { email: string }) => authApi.loginEmailLink({ email }),
  });

  const requestIsEmailOtpValidMutation = useMutation({
    mutationFn: ({ email, loginToken }: EmailCredentials) => authApi.loginEmailToken({ email, loginToken }),
    onSuccess: (data) => {
      queryClient.setQueryData(["user"], data);
      navigate("/");
    },
  });

  const submitOtp = handleSubmit((data) => {
    requestIsEmailOtpValidMutation.mutate({
      email: data.email,
      loginToken: data.otp,
    });
  });

  const submitEmail = handleSubmit((data) => {
    requestEmailOtpMutation.mutate({
      email: data.email,
    });
  });

  return (
    <Flex flexDirection="column" width="100wh" height="100vh" justifyContent="center" alignItems="center">
      <Card>
        <CardHeader>
          <Heading>Login</Heading>
        </CardHeader>
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box as="form" onSubmit={requestEmailOtpMutation.isSuccess ? submitOtp : submitEmail}>
              <FormControl isInvalid={!formState.isValid}>
                <FormLabel>{requestEmailOtpMutation.isSuccess ? "OTP" : "Email"}</FormLabel>
                <Flex gap={3}>
                  <Input {...register("otp")} hidden={!requestEmailOtpMutation.isSuccess} type="password" />
                  {requestIsEmailOtpValidMutation.isError ? (
                    <FormErrorMessage>{requestIsEmailOtpValidMutation.error.message}</FormErrorMessage>
                  ) : null}
                  <Input {...register("email")} hidden={requestEmailOtpMutation.isSuccess} type="email" />
                  {requestEmailOtpMutation.isError ? (
                    <FormErrorMessage>{requestEmailOtpMutation.error.message}</FormErrorMessage>
                  ) : null}
                  <Button
                    w="40%"
                    isLoading={requestEmailOtpMutation.isPending || requestIsEmailOtpValidMutation.isPending}
                    isDisabled={requestEmailOtpMutation.isPending || requestIsEmailOtpValidMutation.isPending}
                    type="submit"
                  >
                    {requestEmailOtpMutation.isSuccess ? "Login" : "Get OTP"}
                  </Button>
                </Flex>
              </FormControl>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Wallet
              </Heading>
              <Text pt="2" fontSize="sm">
                Not implemented yet.
              </Text>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    </Flex>
  );
}
