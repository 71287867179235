import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { NodeProps, useUpdateNodeInternals } from "reactflow";
import { NodeType, SourceHandle } from "../../../models/nodeType";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Select as ChakraSelect,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Control, Controller, FieldPath, useFieldArray, useForm, UseFormReturn } from "react-hook-form";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CreatableSelect, Select } from "chakra-react-select";
import { aiApi } from "../../../api";
import { MdPlayCircle, MdPlaylistPlay, MdUndo } from "react-icons/md";
import { useLoaderData } from "react-router";
import { WorkflowWithId } from "../../../models/api/workflow";
import { PromptRevisionInDatabase, WorkflowNodeClass } from "@worldwidewebb/client-ai";
import MustacheEditor from "../../editor/MustacheEditor";
import { SchemaData } from "./SchemaPassThroughNodeWithChildren";
import useNodeLookup from "../../../hooks/useNodeLookup";
import { Editor } from "@monaco-editor/react";
import { BaseNodeWithChildren } from "./base/BaseNode";
import { ResultPreview } from "../ResultPreview";
import { ConfigureCustomAiCompletionRunModal } from "../ConfigureCustomAiCompletionRunModal";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import { useAiCompletionSchemaContext } from "../../../context/AiCompletionSchemaContext";
import JSONValidator from "ajv";
import useAi from "../../../api/useAi";
import { useAiWorkflowRunsContext } from "../../../context/AiWorkflowRunsContext";

const jsonValidator = new JSONValidator();

enum GptModel {
  GPT4_latest = "gpt-4",
  GPT4_0125 = "gpt-4-0125-preview",
  GPT3Turbo_latest = "gpt-3.5-turbo",
  GPT3TurboJSON_v1 = "gpt-3.5-turbo-0613",
  GPT3TurboJSON_v2 = "gpt-3.5-turbo-1106",
  GPT4_32k_latest = "gpt-4-32k",
  GPT4Turbo = "gpt-4-1106-preview",
  GPT4Vision = "gpt-4-vision-preview",
  GPT4JSON = "gpt-4-0613",
  GPT4JSON_32k = "gpt-4-32k-0613",
  GPT4_TURBO = "gpt-4-turbo",
  GPT4_TURBO_20240409 = "gpt-4-turbo-2024-04-09",
  GPT4_O = "gpt-4o",
  GPT4_O_20240513 = "gpt-4o-2024-05-13",
  Babbage = "babbage-002",
  Davinci = "davinci-002",
}

interface MessageTemplate {
  role: string;
  name?: string;
  template: string;
}

interface AiCompletion extends SchemaData {
  isDataSourceEnabled: boolean;
  promptId: string;
  promptVersion: string;
  systemPrompt: string;
  model: GptModel;
  temperature: number;
  topP: number;
  maxTokens: number;
  responseFormat: "text" | "json_object";
  includePreviousMessages: boolean;
  messagePrompts: MessageTemplate[];
}

const modelOptions = [
  GptModel.GPT4_latest,
  GptModel.GPT4_0125,
  GptModel.GPT3Turbo_latest,
  GptModel.GPT3TurboJSON_v1,
  GptModel.GPT3TurboJSON_v2,
  GptModel.GPT4_32k_latest,
  GptModel.GPT4Turbo,
  GptModel.GPT4Vision,
  GptModel.GPT4JSON,
  GptModel.GPT4JSON_32k,
  GptModel.GPT4_TURBO,
  GptModel.GPT4_TURBO_20240409,
  GptModel.GPT4_O,
  GptModel.GPT4_O_20240513,
  GptModel.Babbage,
  GptModel.Davinci,
];

const InputNumberWithStepper: React.FC<{
  name: FieldPath<AiCompletion>;
  control: Control<AiCompletion>;
  defaultValue: number;
  min?: number;
  max?: number;
  step?: number;
}> = ({ name, control, defaultValue, min, max, step }) => {
  return (
    <Controller
      render={({ field: { onChange, onBlur, value, name, ref } }) => (
        <NumberInput
          defaultValue={defaultValue}
          name={name}
          step={step}
          min={min}
          max={max}
          precision={3}
          value={value as number}
          onChange={(value) => onChange(Number(value))}
          onBlur={onBlur}
          ref={ref}
        >
          <NumberInputField value={value as number} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      )}
      name={name}
      control={control}
    />
  );
};

const ConfigurePromptModalContent: React.FC<{
  data: {
    nodeId: string;
    nodeName: string;
    nodeClass: WorkflowNodeClass;
    sourceHandles: SourceHandle[];
    inputSchema: string;
    systemPrompt: string;
    outputSchema: string;
  };
  formHandle: UseFormReturn<AiCompletion>;
  handleUpdate: (completion: AiCompletion) => void;
  onClose: () => void;
  isOpen: boolean;
}> = ({ data, formHandle, handleUpdate, onClose, isOpen }) => {
  const { formState, control, watch, reset, register, getValues, setValue, handleSubmit } = formHandle;
  const { nodeId, nodeName, nodeClass, sourceHandles, inputSchema, systemPrompt } = data;
  const { append, remove, fields } = useFieldArray({
    control,
    name: "messagePrompts",
    rules: {
      minLength: 1,
    },
  });
  const { getRunRequestLog } = useAi();
  const { workflow } = useLoaderData() as { workflow: WorkflowWithId };
  const {
    isOpen: isCustomRunModalOpen,
    onOpen: onCustomRunModalOpen,
    onClose: onCustomRunModalClose,
  } = useDisclosure();

  const [newPromptIdOption, setNewPromptIdOption] = useState<{ label: string; value: string }>();
  const [promptVersions, setPromptVersions] = useState<PromptRevisionInDatabase[]>([]);
  const [promptVersionsLoading, setPromptVersionsLoading] = useState<boolean>(false);
  const [promptVersionsError, setPromptVersionsError] = useState<Error | null>(null);
  const [lastFormValues, setLastFormValues] = useState<AiCompletion | undefined>(undefined);

  const { promptId: _promptId, promptVersion: _promptVersion, ...dirtyFields } = formState.dirtyFields;
  const isFormDirty = Object.keys(dirtyFields).length > 0;
  const isNewPromptVersion = Boolean(newPromptIdOption || isFormDirty);

  const selectedPromptId = watch("promptId");
  const selectedVersion = watch("promptVersion");

  const { setInputSchema, currentWorkflowSuggestionMap, getAiCompletionSchemaQuery } = useAiCompletionSchemaContext();
  const {
    workflowRunsQuery,
    workflowRunQuery: { data: workflowRun, setWorkflowRunId },
  } = useAiWorkflowRunsContext();
  const [trackedWorkflowRunId, setTrackedWorkflowRunId] = useState<string | undefined>();

  useEffect(() => {
    setInputSchema(inputSchema);
  }, [inputSchema]);

  const getPromptIdsQuery = useQuery({
    queryKey: ["prompts"],
    queryFn: () => aiApi.getAllPrompts(),
  });

  const promptMutation = useMutation({
    mutationFn: async (completion: AiCompletion) => {
      await aiApi.updatePrompt(completion.promptId, {
        messages: completion.messagePrompts,
        params: {
          model: completion.model,
          temperature: completion.temperature,
          topP: completion.topP,
          maxTokens: completion.maxTokens,
        },
        systemTemplate: completion.systemPrompt,
        comments: "",
        responseFormat: completion.responseFormat,
        externalMessagesOptions: completion.includePreviousMessages
          ? {
              dataKey: "userChat.messages",
              operation: "prepend",
            }
          : undefined,
      });

      await aiApi.versionPrompt(completion.promptId, completion.promptVersion);
    },
  });

  useEffect(() => {
    if (promptMutation.status === "success") {
      const promptId = selectedPromptId;
      onClose();
      handleUpdate(getValues());
      setNewPromptIdOption(undefined);
      getPromptIdsQuery
        .refetch()
        .then(() => loadPromptVersions(promptId))
        .then(() => reset(getValues()));
    }
  }, [promptMutation.status]);

  useEffect(() => {
    if (!workflowRunsQuery.data || trackedWorkflowRunId !== undefined) {
      return;
    }

    // if no tracked workflow run id set yet, find the last completed run and set it as tracked
    setTrackedWorkflowRunId(workflowRunsQuery.data.find((run) => run.hasCompleted)?.workflowRunId);
  }, [workflowRunsQuery.data, trackedWorkflowRunId]);

  const workflowRunMatchingNode = useMemo(() => {
    return workflowRun?.nodes.find((node) => node.nodeId === nodeId);
  }, [workflowRun, nodeId]);

  const hasTrackedWorkflowRunCompleted = Boolean(
    workflowRunsQuery.data?.find((run) => run.workflowRunId === trackedWorkflowRunId && run.hasCompleted)
  );

  const executeWorkflowNodeMutation = useMutation({
    mutationFn: async ({
      input,
      context,
      completion,
    }: {
      input: string;
      context: string;
      completion: AiCompletion;
    }) => {
      const result = await aiApi.createWorkflowNodeExecution({
        aiNpcWorkflowContext: JSON.parse(context),
        workflowNodeContext: {
          node: {
            aiId: workflow.workflowId,
            nodeClass,
            nodeData: completion,
            nodeId,
            nodeInputs: [],
            nodeOutputs: sourceHandles.map((handle) => ({
              nodeId: "",
              nodeType: handle.handleType,
              propertyName: handle.handleName,
            })),
            nodeType: nodeName,
          },
          data: JSON.parse(input),
        },
      });

      return result.data;
    },
  });

  useEffect(() => {
    if (executeWorkflowNodeMutation.status === "success") {
      // set the tracked workflow run id to the newly created run
      setTrackedWorkflowRunId(executeWorkflowNodeMutation.data.runId);
    }
  }, [executeWorkflowNodeMutation.status]);

  useEffect(() => {
    if (!workflowRunsQuery.data || !trackedWorkflowRunId) {
      return;
    }

    // find the tracked workflow run info and load it only if it has completed
    const trackedWorkflowRunInfo = workflowRunsQuery.data.find((run) => run.workflowRunId === trackedWorkflowRunId);

    if (trackedWorkflowRunInfo?.hasCompleted) {
      // set workflow run id to load the full run data
      setWorkflowRunId(trackedWorkflowRunId);
    }
  }, [trackedWorkflowRunId, workflowRunsQuery.data]);

  const getRunRequestLogQuery = useQuery({
    queryKey: ["runRequestLog"],
    queryFn: () => getRunRequestLog(workflowRun?.systemId as string, nodeId),
    enabled: Boolean(workflowRun?.systemId) && isOpen,
  });

  const hoverDataMap = getRunRequestLogQuery.data?.payload ?? {};

  const loadVersionData = useCallback((selectedVersion: string, promptRevisions: PromptRevisionInDatabase[]) => {
    if (!isNewPromptVersion && promptRevisions.length > 0) {
      const revision = promptRevisions.find((v) => v.version === selectedVersion);

      if (revision) {
        setLastFormValues(() => ({
          ...structuredClone(getValues()),
        }));

        setValue("systemPrompt", revision.systemTemplate, {
          shouldDirty: false,
        });
        setValue("messagePrompts", revision.messages ?? [], {
          shouldDirty: false,
        });
        (["maxTokens", "temperature", "topP"] as const).forEach((key) => {
          if (revision?.params[key] !== undefined) {
            setValue(key, revision.params[key], {
              shouldDirty: false,
            });
          }
        });
        setValue("model", revision.params.model as GptModel, { shouldDirty: false });
        setValue("includePreviousMessages", !!revision.externalMessagesOptions, {
          shouldDirty: false,
        });
      }
    }
  }, []);

  const loadPromptVersions = useCallback(async (selectedPromptId: string) => {
    setPromptVersionsError(null);
    setPromptVersionsLoading(true);

    try {
      const result = await aiApi.getPromptVersions(selectedPromptId);
      const newVersions = result.data.versions.sort((a, b) => {
        if (a.version === "latest") {
          return -1;
        }

        if (b.version === "latest") {
          return 1;
        }

        return new Date(b.createdAt || new Date()).getTime() - new Date(a.createdAt || new Date()).getTime();
      });
      setPromptVersions(newVersions);
      loadVersionData(newVersions[0].version, newVersions);
    } catch (error) {
      setPromptVersionsError(error as Error);
    } finally {
      setPromptVersionsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (selectedPromptId) {
      loadPromptVersions(selectedPromptId);
    }
  }, [selectedPromptId]);

  useEffect(() => {
    if (selectedVersion) {
      loadVersionData(selectedVersion, promptVersions);
    }
  }, [selectedVersion, promptVersions]);

  const promptIdOptions = [
    ...(getPromptIdsQuery.data?.data.prompts.map((prompt) => ({
      label: prompt.promptId,
      value: prompt.promptId,
    })) ?? []),
    ...(newPromptIdOption ? [newPromptIdOption] : []),
  ];
  const promptVersionOptions =
    promptVersions.map((revision) => ({
      label: revision.version,
      value: revision.version,
    })) ?? [];

  return (
    <ModalContent minW="md" maxW={"container.lg"} bg={"theme.dark.background"}>
      <ModalHeader m={0} p={0}>
        <Stack direction={"row"} alignItems={"baseline"} px={4} py={2}>
          <Box>Configure Prompt</Box>
          <Tooltip
            label={
              !workflowRunMatchingNode ? "Previous run with this node could not be found" : "Re-run with last input"
            }
          >
            <Box>
              <IconButton
                variant={"solid"}
                icon={
                  executeWorkflowNodeMutation.isPending || !hasTrackedWorkflowRunCompleted ? (
                    <Spinner size={"xs"} color={"white"} />
                  ) : (
                    <Icon as={MdPlayCircle} />
                  )
                }
                disabled={
                  executeWorkflowNodeMutation.isPending || !hasTrackedWorkflowRunCompleted || !workflowRunMatchingNode
                }
                aria-label={"execute prompt"}
                onClick={() =>
                  executeWorkflowNodeMutation.mutate({
                    input: JSON.stringify(
                      (Array.isArray(workflowRunMatchingNode?.inputs)
                        ? workflowRunMatchingNode?.inputs.at(-1)
                        : workflowRunMatchingNode?.inputs) || "{}"
                    ),
                    context: JSON.stringify(workflowRun?.context || "{}"),
                    completion: getValues(),
                  })
                }
              />
            </Box>
          </Tooltip>
          <Tooltip label="Custom run">
            <Box>
              <IconButton
                variant={"solid"}
                icon={
                  executeWorkflowNodeMutation.isPending || !hasTrackedWorkflowRunCompleted ? (
                    <Spinner size={"xs"} color={"white"} />
                  ) : (
                    <Icon as={MdPlaylistPlay} />
                  )
                }
                disabled={executeWorkflowNodeMutation.isPending || !hasTrackedWorkflowRunCompleted}
                aria-label={"execute custom prompt"}
                onClick={onCustomRunModalOpen}
              />
            </Box>
          </Tooltip>
          {executeWorkflowNodeMutation.error && (
            <Text color={"red.500"} size="sm">
              {executeWorkflowNodeMutation.error.message}
            </Text>
          )}
          <ConfigureCustomAiCompletionRunModal
            nodeId={nodeId}
            isOpen={isCustomRunModalOpen}
            onClose={onCustomRunModalClose}
            onSubmit={(input, context) =>
              executeWorkflowNodeMutation.mutate({
                input,
                context,
                completion: getValues(),
              })
            }
          />
        </Stack>
        {hasTrackedWorkflowRunCompleted && workflowRunMatchingNode && (
          <Stack py={1} fontSize="xs">
            <ResultPreview
              result={workflowRunMatchingNode?.outputs || {}}
              payload={getRunRequestLogQuery.data?.payload || {}}
            />
          </Stack>
        )}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl
          className={"nodrag"}
          onSubmit={handleSubmit(handleUpdate)}
          onBlur={handleSubmit(handleUpdate)}
          isInvalid={!formState.isValid}
        >
          <HStack spacing={3}>
            <VStack spacing={2} alignItems={"flex-start"}>
              <Box w={"72"}>
                <FormLabel>Prompt ID</FormLabel>
                {getPromptIdsQuery.isError && <Text color={"red.500"}>{getPromptIdsQuery.error.message}</Text>}
                <Controller
                  name={"promptId"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value, name, ref } }) => {
                    return (
                      <>
                        <CreatableSelect
                          onBlur={onBlur}
                          name={name}
                          ref={ref}
                          onCreateOption={(inputValue) => {
                            setNewPromptIdOption({ label: inputValue, value: inputValue });
                            onChange(inputValue);
                            reset({
                              promptId: inputValue,
                              promptVersion: "",
                              systemPrompt: "",
                              messagePrompts: [
                                {
                                  role: "user",
                                  name: "",
                                  template: "",
                                },
                              ],
                              model: GptModel.GPT4_latest,
                              temperature: 0.4,
                              topP: 1,
                              maxTokens: 50,
                              includePreviousMessages: false,
                            });
                          }}
                          isLoading={getPromptIdsQuery.isFetching}
                          isDisabled={
                            isNewPromptVersion ||
                            getPromptIdsQuery.isFetching ||
                            getPromptIdsQuery.isError ||
                            !getPromptIdsQuery.data?.data.prompts.length
                          }
                          value={
                            promptIdOptions && value ? promptIdOptions.find((option) => option.value === value) : null
                          }
                          onChange={(option) => {
                            if (option) {
                              if (selectedPromptId !== option.value) {
                                setValue("promptVersion", "latest");
                              }

                              setNewPromptIdOption(undefined);
                              onChange(option.value);
                            }
                          }}
                          options={promptIdOptions}
                        />
                        {!value && <Text color="red.500">Select / Create Prompt ID</Text>}
                      </>
                    );
                  }}
                />
              </Box>
              <Box w={"72"}>
                {!isNewPromptVersion ? (
                  <Controller
                    render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, error } }) => (
                      <>
                        <FormLabel>Prompt Version</FormLabel>

                        <Select
                          onBlur={onBlur}
                          name={name}
                          ref={ref}
                          isInvalid={invalid}
                          isLoading={promptVersionsLoading}
                          isDisabled={promptVersionsLoading || !!promptVersionsError || promptVersions.length === 0}
                          value={{
                            label: value,
                            value,
                          }}
                          onChange={(option) => option && onChange(option.value)}
                          options={promptVersionOptions}
                        />
                        <FormErrorMessage>{error && error.message}</FormErrorMessage>
                      </>
                    )}
                    name={"promptVersion"}
                    control={control}
                    rules={{ required: "Please select a version." }}
                  />
                ) : (
                  <HStack alignItems={"flex-end"}>
                    <Box>
                      <FormLabel>Prompt Version</FormLabel>
                      <Input disabled defaultValue="Autogenerated" />
                    </Box>
                    <Box>
                      <Button
                        onClick={() => {
                          if (newPromptIdOption) {
                            setNewPromptIdOption(undefined);
                          }
                          return reset(lastFormValues ?? {});
                        }}
                      >
                        <MdUndo />
                        &nbsp;Revert
                      </Button>
                    </Box>
                  </HStack>
                )}
              </Box>
            </VStack>
            <Wrap>
              <WrapItem>
                <Box>
                  <FormLabel>Model</FormLabel>
                  <ChakraSelect {...register("model")}>
                    {modelOptions.map((model) => (
                      <option key={model} value={model}>
                        {model}
                      </option>
                    ))}
                  </ChakraSelect>
                </Box>
              </WrapItem>
              <WrapItem>
                <Box>
                  <FormLabel>Temperature</FormLabel>
                  <InputNumberWithStepper
                    name={"temperature"}
                    control={control}
                    defaultValue={0.4}
                    step={0.1}
                    min={0}
                    max={2}
                  />
                </Box>
              </WrapItem>
              <WrapItem>
                <Box>
                  <FormLabel>Top P</FormLabel>
                  <InputNumberWithStepper name={"topP"} control={control} defaultValue={1} step={0.1} min={0} max={1} />
                </Box>
              </WrapItem>
              <WrapItem>
                <Box>
                  <FormLabel>Max Tokens</FormLabel>
                  <InputNumberWithStepper
                    name={"maxTokens"}
                    control={control}
                    defaultValue={50}
                    step={10}
                    min={1}
                    max={32000}
                  />
                </Box>
              </WrapItem>
            </Wrap>
          </HStack>
          <HStack h="full" mt={2}>
            <Box>
              <FormLabel>Response Mode</FormLabel>
              <ChakraSelect {...register("responseFormat")}>
                <option key="text" value="text">
                  Text
                </option>
                <option key="json" value="json_object">
                  JSON
                </option>
              </ChakraSelect>
            </Box>
            <HStack pt={6}>
              <FormLabel mb={0} ml={2}>
                Include Message History
              </FormLabel>
              <Controller
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Checkbox m={0} name={name} isChecked={value} onChange={onChange} onBlur={onBlur} ref={ref} />
                )}
                name={"includePreviousMessages"}
                control={control}
              />
            </HStack>
          </HStack>
          <HStack mt={4} alignItems="flex-start">
            <Box w={"62%"}>
              <FormLabel>
                <Text casing={"uppercase"}>System Prompt Template</Text>
              </FormLabel>
              {getAiCompletionSchemaQuery.isFetching && <Spinner size={"md"} color={"white"} />}
              {getAiCompletionSchemaQuery.status === "success" && (
                <MustacheEditor
                  suggestionMap={currentWorkflowSuggestionMap}
                  hoverDataMap={hoverDataMap}
                  onChange={(value) =>
                    setValue("systemPrompt", value || "", {
                      shouldDirty: true,
                    })
                  }
                  value={watch("systemPrompt") ?? systemPrompt}
                />
              )}
            </Box>
            <Box w="38%" pb={6}>
              <FormLabel>
                <Text casing={"uppercase"}>Output JSON Schema</Text>
              </FormLabel>
              <Controller
                rules={{
                  validate: (value) => {
                    try {
                      const isValid = jsonValidator.compile(JSON.parse(value ?? ""));

                      if (!isValid) {
                        throw new Error();
                      }

                      return true;
                    } catch (e) {
                      return "Invalid JSON schema";
                    }
                  },
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <Editor
                      height="30vh"
                      language="json"
                      theme="vs-dark"
                      value={value}
                      onChange={(value) => onChange(value || "{}")}
                      options={{
                        lineNumbers: "off",
                        minimap: { enabled: false },
                        wordWrap: "on",
                      }}
                    />
                    {error && <Text color="red.500">{error.message}</Text>}
                  </>
                )}
                name={"outputSchema"}
                control={control}
              />
            </Box>
          </HStack>
          <Box>
            <Stack spacing={5} direction="row" alignItems={"baseline"} py={2}>
              <Box as={"span"} mr={2} fontSize="lg">
                <Text casing={"uppercase"}>Message Templates</Text>
              </Box>
              <Button
                onClick={() =>
                  append({
                    role: "user",
                    name: "",
                    template: "",
                  })
                }
              >
                + Add Template
              </Button>
            </Stack>
            {fields.map((messagePrompt, index) => (
              <Box my={2} p={3} borderWidth={"thin"} key={`message-prompt-${index}`}>
                <Stack spacing={5} direction="row" alignItems={"center"} pb={4} justify={"left"}>
                  <Box>#{index + 1}</Box>
                  <Box>
                    <Input
                      placeholder="Name"
                      {...register(`messagePrompts.${index}.name`, {
                        pattern: {
                          value: /^[a-zA-Z0-9_\-.{}]{1,64}$/,
                          message:
                            "Invalid name format, only letters and numbers or mustache placeholders are accepted",
                        },
                      })}
                    />
                    {formState.errors.messagePrompts?.[index]?.name && (
                      <Text color="red.500">{formState.errors.messagePrompts?.[index]?.name?.message}</Text>
                    )}
                  </Box>
                  <Box w={"full"}>
                    <Controller
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <RadioGroup onChange={onChange} name={name} ref={ref} onBlur={onBlur} value={value}>
                          <Stack direction="row">
                            <Radio colorScheme="purple" value="user">
                              User
                            </Radio>
                            <Radio colorScheme="purple" value="assistant">
                              Assistant
                            </Radio>
                            <Radio colorScheme="purple" value="function">
                              Function
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                      name={`messagePrompts.${index}.role`}
                      control={control}
                    />
                  </Box>
                  <Box>{fields.length > 1 && <Button onClick={() => remove(index)}>Delete</Button>}</Box>
                </Stack>
                {getAiCompletionSchemaQuery.isFetching && <Spinner size={"md"} color={"white"} />}
                {getAiCompletionSchemaQuery.status === "success" && (
                  <MustacheEditor
                    suggestionMap={currentWorkflowSuggestionMap}
                    hoverDataMap={hoverDataMap}
                    onChange={(value) =>
                      setValue(`messagePrompts.${index}.template`, value || "", {
                        shouldDirty: true,
                      })
                    }
                    value={watch(`messagePrompts.${index}.template`) ?? messagePrompt.template}
                  />
                )}
              </Box>
            ))}
          </Box>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button
          mr={3}
          isDisabled={!formState.isValid || promptMutation.isPending}
          onClick={() => {
            if (!isNewPromptVersion) {
              handleUpdate(getValues());
              return onClose();
            }

            const promptVersion = new Date().toISOString().split(".")[0];
            setValue("promptVersion", promptVersion);

            promptMutation.mutate({
              ...getValues(),
              promptVersion,
            });
          }}
          variant={"outline"}
        >
          {promptMutation.isPending && (
            <>
              <Spinner size={"sm"} />
              &nbsp;
            </>
          )}
          Save
        </Button>
        {promptMutation.isError && <Text color={"red.500"}>{promptMutation.error.message}</Text>}
      </ModalFooter>
    </ModalContent>
  );
};

const CreateAiCompletionNode: React.FC<NodeProps<NodeType<AiCompletion>>> = (props) => {
  const {
    id: nodeId,
    data: { nodeClass, nodeName, nodeData, targetHandles = [], sourceHandles = [] },
  } = props;
  const promptId = nodeData?.promptId ?? "";
  const promptVersion = nodeData?.promptVersion ?? "latest";
  const isDataSourceEnabled = nodeData?.isDataSourceEnabled ?? false;
  const systemPrompt = nodeData?.systemPrompt ?? "";
  const responseFormat = nodeData?.responseFormat ?? "text";
  const model = nodeData?.model ?? GptModel.GPT4_latest;
  const temperature = nodeData?.temperature ?? 0.4;
  const topP = nodeData?.topP ?? 1;
  const maxTokens = nodeData?.maxTokens ?? 150;
  const includePreviousMessages = nodeData?.includePreviousMessages ?? false;
  const messagePrompts = nodeData?.messagePrompts ?? [
    {
      role: "user",
      name: "",
      template: "",
    },
  ];
  const outputSchema = nodeData?.outputSchema ?? "{}";

  const formHandle = useForm<AiCompletion>({
    defaultValues: {
      promptId,
      promptVersion,
      systemPrompt,
      messagePrompts,
      model,
      temperature,
      topP,
      maxTokens,
      responseFormat,
      includePreviousMessages,
      outputSchema,
      isDataSourceEnabled,
    },
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { updateNodeData } = useUpdateNodeData(nodeId);
  const { updateNodeSourceHandles } = useUpdateNodeHandles(nodeId);
  const updateNodeInternals = useUpdateNodeInternals();

  const handleUpdate = useCallback((completion: AiCompletion) => {
    updateNodeData(completion);
  }, []);

  const { getTargetNodeOutputSchema } = useNodeLookup();
  const inputSchema = getTargetNodeOutputSchema(targetHandles.map(({ handleId }) => handleId));

  useEffect(() => {
    updateNodeData({
      ...nodeData,
      inputSchema,
    });
  }, [inputSchema]);

  useEffect(() => {
    if (isDataSourceEnabled) {
      if (sourceHandles.find(({ handleName }) => handleName === "data") == null) {
        updateNodeSourceHandles([
          ...sourceHandles,
          {
            label: "DATA",
            handleName: "data",
            handleType: "source",
            handleCategory: "data",
          },
        ]);
      }
    } else {
      updateNodeSourceHandles(sourceHandles.filter(({ handleName }) => handleName !== "data"));
    }

    updateNodeInternals(nodeId);
  }, [isDataSourceEnabled]);

  return (
    <BaseNodeWithChildren {...props}>
      <VStack spacing={2} alignItems={"flex-start"}>
        <HStack alignItems="baseline">
          <FormLabel>
            <Text casing={"uppercase"}>Output Data Port Enabled</Text>
          </FormLabel>
          <Checkbox
            onChange={() => {
              formHandle.setValue("isDataSourceEnabled", !isDataSourceEnabled);
              formHandle.handleSubmit(handleUpdate)();
            }}
            checked={isDataSourceEnabled}
          />
        </HStack>
        <HStack>
          <Text>Prompt ID:</Text>
          <Badge colorScheme="red">{promptId}</Badge>
        </HStack>
        <HStack>
          <Text>Prompt Version:</Text>
          <Badge colorScheme="blue">{promptVersion}</Badge>
        </HStack>
      </VStack>
      <Box p={2}>
        <Button onClick={onOpen} textTransform={"uppercase"} w={"100%"}>
          Configure Prompt
        </Button>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        {isOpen && (
          <ConfigurePromptModalContent
            formHandle={formHandle}
            isOpen={isOpen}
            onClose={onClose}
            handleUpdate={handleUpdate}
            data={{
              inputSchema: inputSchema ?? "{}",
              nodeClass: nodeClass as WorkflowNodeClass,
              nodeId,
              nodeName,
              outputSchema,
              sourceHandles,
              systemPrompt,
            }}
          />
        )}
      </Modal>
    </BaseNodeWithChildren>
  );
};

export default memo(CreateAiCompletionNode);
