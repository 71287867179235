/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormLabel } from "@chakra-ui/form-control";
import { Text } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { useQuery } from "@tanstack/react-query";
import { Select } from "chakra-react-select";
import { Control, Controller } from "react-hook-form";
import { aiApi } from "../../api";

interface LoreTopicSelectData {
  topicId: string;
}

interface LoreTopicSelectProps<T extends Record<string, any>> {
  control: Control<LoreTopicSelectData & T, unknown>;
  onChange?: (topicId?: string) => void;
  color?: string;
}

function LoreTopicSelect<T extends Record<string, any>>(props: LoreTopicSelectProps<T>) {
  const { control, onChange: onSelectChange, color } = props;

  const getLoreTopicsResult = useQuery({
    queryKey: ["topics"],
    queryFn: () => aiApi.getLoreTopics(),
  });

  const topicIdOptions = [
    { label: "All Topics", value: "_all" },
    ...(getLoreTopicsResult.data?.data.map((topic) => ({
      label: topic,
      value: topic,
    })) ?? []),
  ];

  return (
    <Box w="full">
      <FormLabel>
        <Text casing={"uppercase"} color={color}>
          Lore Topic
        </Text>
      </FormLabel>
      {getLoreTopicsResult.isError && <Text color={"red.500"}>{getLoreTopicsResult.error.message}</Text>}
      <Controller
        name={"topicId" as any}
        control={control}
        render={({ field: { onChange, onBlur, value, name, ref } }) => (
          <Select
            onBlur={onBlur}
            name={name}
            ref={ref}
            isLoading={getLoreTopicsResult.isFetching}
            isDisabled={
              getLoreTopicsResult.isFetching || getLoreTopicsResult.isError || !getLoreTopicsResult.data?.data.length
            }
            value={topicIdOptions && value ? topicIdOptions.find((option) => option.value === value) : null}
            onChange={(option) => {
              if (onSelectChange) {
                onSelectChange(option?.value);
              }
              return option && onChange(option.value);
            }}
            options={topicIdOptions}
          />
        )}
      />
    </Box>
  );
}

export default LoreTopicSelect;
