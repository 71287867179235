import React, { memo, useCallback, useEffect } from "react";
import { NodeProps } from "reactflow";
import { NodeType, TargetHandle } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import useNodeLookup from "../../../hooks/useNodeLookup";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import { FlowNodeData } from "./FlowNode";
import { BaseNodeWithChildren } from "./base/BaseNode";
import { Editor } from "@monaco-editor/react";
import { JSONSchema7 } from "@worldwidewebb/client-ai";
import { MdDangerous } from "react-icons/md";

interface IntersectionJoinData {
  inputSchemas: string[];
  outputSchema?: string;
  flowInputCount: number;
}

const IntersectionJoinNode: React.FC<NodeProps<NodeType>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData = {}, targetHandles = [] },
  } = props;

  const formData = nodeData as IntersectionJoinData;
  const outputSchema = formData?.outputSchema;
  const flowInputCount = formData?.flowInputCount ?? 1;
  const inputSchemas = formData?.inputSchemas ?? [];

  const { getNodeTypeByTargetHandles } = useNodeLookup();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { register, watch, handleSubmit, setValue } = useForm<IntersectionJoinData>({
    defaultValues: {
      outputSchema,
      inputSchemas,
      flowInputCount,
    },
    mode: "onChange",
  });

  const inputFlowNodes = getNodeTypeByTargetHandles(
    targetHandles.map(({ handleId }) => handleId)
  ) as NodeType<FlowNodeData>[];

  useEffect(() => {
    const newInputSchemas = inputFlowNodes.map((node) => node.nodeData?.outputSchema ?? "");

    if (JSON.stringify(newInputSchemas) === JSON.stringify(inputSchemas)) {
      return;
    }

    setValue(
      "inputSchemas",
      inputFlowNodes.map((node) => node.nodeData?.outputSchema ?? "")
    );
    handleSubmit(handleUpdate)();
  }, [inputFlowNodes]);

  const { updateNodeData } = useUpdateNodeData(nodeId);
  const handleUpdate = useCallback((data: IntersectionJoinData) => {
    updateNodeData(data);
  }, []);

  const tryToParseJsonSchema = useCallback((schema: string) => {
    try {
      return JSON.parse(schema);
    } catch (e) {
      return {};
    }
  }, []);

  const areAllItemsIdentical = useCallback(<T,>(arr: T[]) => {
    return arr.every((v) => v === arr[0]);
  }, []);

  const getSchemaIntersection = useCallback(
    (flowInputCount: number) => {
      if (flowInputCount === 1 && inputSchemas.length === 1) {
        return inputSchemas[0];
      }
      if (areAllItemsIdentical(inputSchemas)) {
        return inputSchemas[0];
      }
      const parsedSchemas = inputSchemas.map((schema) => tryToParseJsonSchema(schema ?? ""));
      if (
        !parsedSchemas.every((schema) => schema.type === "object") &&
        !parsedSchemas.every((schema) => schema.type === "array")
      ) {
        return undefined;
      }
      const intersectSchemaProperties = (
        properties1: JSONSchema7["properties"],
        properties2: JSONSchema7["properties"]
      ) => {
        const result: JSONSchema7["properties"] = {};
        for (const key in properties1) {
          if (!properties2?.[key] || properties1[key].type !== properties2[key].type) {
            continue;
          }
          if (properties1[key].type === "object") {
            const intersected = intersectObjectSchemas([properties1[key], properties2[key]]);
            if (JSON.stringify(intersected.properties) !== "{}") {
              result[key] = intersected;
            }
          } else if (properties1[key].type === "array") {
            const intersected = intersectArraySchemas([properties1[key], properties2[key]]);
            if (JSON.stringify(intersected.items) !== "{}") {
              result[key] = intersected;
            }
          } else if (JSON.stringify(properties1[key]) === JSON.stringify(properties2[key])) {
            result[key] = properties1[key];
          }
        }
        return result;
      };
      const intersectObjectSchemas = (schemas: JSONSchema7[]) => {
        const result = schemas[0];
        for (const schema of schemas.slice(1)) {
          result.properties = intersectSchemaProperties(result.properties, schema.properties ?? {});
        }
        return result;
      };
      const intersectArraySchemas = (schemas: JSONSchema7[]) => {
        const result = schemas[0];
        if (schemas.every((schema) => schema.items?.type === "object")) {
          for (const schema of schemas.slice(1)) {
            (result.items as JSONSchema7).properties = intersectSchemaProperties(
              result.items?.properties as JSONSchema7["properties"],
              schema.items?.properties as JSONSchema7["properties"]
            );
          }
        } else if (schemas.every((schema) => schema.items?.type === "array")) {
          let result = schemas[0];
          for (const schema of schemas.slice(1)) {
            result = intersectArraySchemas([result.items as JSONSchema7, schema.items as JSONSchema7]);
          }
          return result;
        }
        return result;
      };
      if (parsedSchemas[0].type === "object") {
        if (!parsedSchemas.every((schema) => schema.properties)) {
          return undefined;
        }
        return JSON.stringify(intersectObjectSchemas(parsedSchemas), null, 2);
      } else {
        if (!parsedSchemas.every((schema) => schema.items)) {
          return undefined;
        }
        return JSON.stringify(intersectArraySchemas(parsedSchemas), null, 2);
      }
    },
    [inputSchemas]
  );

  useEffect(() => {
    const newOutputSchema = getSchemaIntersection(flowInputCount);
    setValue("outputSchema", newOutputSchema);

    if (newOutputSchema !== outputSchema) {
      handleSubmit(handleUpdate)();
    }
  }, [flowInputCount, inputSchemas]);

  const { updateNodeTargetHandles } = useUpdateNodeHandles(nodeId);

  useEffect(() => {
    const currentFlowTargetCount = targetHandles.filter(({ handleName }) => handleName === "in").length;

    if (currentFlowTargetCount === flowInputCount) {
      return;
    }

    const targetHandlesExcludingIn = targetHandles.filter(({ handleName }) => handleName !== "in");

    const targetHandlesIncludingIn = targetHandles.filter(({ handleName }) => handleName === "in");

    if (currentFlowTargetCount < flowInputCount) {
      const handlesToInsert = flowInputCount - currentFlowTargetCount;

      targetHandlesIncludingIn.push(
        ...[...Array(handlesToInsert)].map(() => {
          const targetHandle: TargetHandle = {
            label: "IN",
            handleName: "in",
            handleType: "target",
            handleCategory: "flow",
          };

          return targetHandle;
        })
      );
    } else {
      const handlesToRemove = currentFlowTargetCount - flowInputCount;

      targetHandlesIncludingIn.splice(-handlesToRemove);
    }

    updateNodeTargetHandles([...targetHandlesIncludingIn, ...targetHandlesExcludingIn]);
  }, [flowInputCount]);

  return (
    <BaseNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        {!outputSchema && (
          <HStack pb={3} pr={2}>
            <MdDangerous color="red.300" />
            <Text color={"red"}>Incompatible input schemas</Text>
          </HStack>
        )}

        {flowInputCount !== inputSchemas.length && (
          <HStack pb={3} pr={2}>
            <MdDangerous color="red.300" />
            <Text color={"red"}>All input schemas must be connected to the node.</Text>
          </HStack>
        )}

        <FormControl>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              IN Port Count
            </Text>
          </FormLabel>
          <NumberInput defaultValue={flowInputCount} step={1} min={1} max={20}>
            <NumberInputField
              id={"flowInputCount"}
              {...register("flowInputCount", { valueAsNumber: true, min: 1, max: 20 })}
              color={color}
            />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
      </form>
      <Button onClick={onOpen} textTransform={"uppercase"} w={"100%"} mt={2} color={color}>
        Preview Output Schema
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          bg={"theme.dark.background"}
          borderColor="yellow.500"
          borderRadius={0}
          borderWidth={1}
          minW="md"
          maxW="container.md"
        >
          <ModalHeader>
            <Text color={color}>Output Schema Preview</Text>
          </ModalHeader>

          <ModalBody>
            <FormLabel>
              <Text casing={"uppercase"} color={color}>
                Output JSON Schema
              </Text>
            </FormLabel>
            <Editor
              height="50vh"
              language="json"
              theme="vs-dark"
              value={watch("outputSchema")}
              options={{ readOnly: true }}
            />
          </ModalBody>

          <ModalFooter gap={1}>
            <Button onClick={onClose} color={"white"} variant={"outline"}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </BaseNodeWithChildren>
  );
};

export default memo(IntersectionJoinNode);
