import { Edge, Node } from "reactflow";
import { NodeHandle, NodeType } from "../models/nodeType";
import { useCallback } from "react";
import { createHandleId } from "../utils/handleId";
import useNodeTypeNameLookup from "./useNodeTypeNameLookup";

export interface NodeDiff {
  actionType: "created" | "updated" | "deleted";
  nodeId: string;
  nodeType?: string;
  nodeDataDiffs?: NodePropertyDiff<NodeType>[];
  nodeHandleDiffs?: NodePropertyDiff<NodeHandle>[];
}

export interface NodePropertyDiff<T> {
  actionType: "created" | "updated" | "deleted";
  property?: keyof T | "type";
  oldValue?: string | number | boolean;
  newValue?: string | number | boolean;
}

const useNodeTypesDiff = (currentEdges: Edge[], currentNodes: Node<NodeType>[], updatedNodeTypes: NodeType[]) => {
  const { getLatestType } = useNodeTypeNameLookup();

  const updatedNodeTypesDictionary = Object.fromEntries(
    updatedNodeTypes.map((updatedNodeType) => [updatedNodeType.nodeName, updatedNodeType])
  );

  const createUpdatedNodeHandles = useCallback(
    <T extends NodeHandle>(oldNodeHandles: T[] = [], newNodeHandles: T[] = []) => {
      const oldNodeHandleNames = oldNodeHandles.map(({ handleName }) => handleName);
      const newNodeHandleNames = newNodeHandles.map(({ handleName }) => handleName);

      const removedNodeHandles: T[] = oldNodeHandles.filter(
        ({ handleName }) => !newNodeHandleNames.includes(handleName)
      );

      const removedNodeHandleDiffs: NodePropertyDiff<NodeHandle>[] = removedNodeHandles.map((removedNodeHandle) => ({
        actionType: "deleted",
        property: "handleName",
        oldValue: removedNodeHandle.handleName,
        newValue: "",
      }));

      const updatedNodeHandles: T[] = oldNodeHandles
        .filter(({ handleName }) => newNodeHandleNames.includes(handleName))
        .map((oldNodeHandle) => {
          const { handleType, handleCategory } = oldNodeHandle;
          const newNodeHandle = newNodeHandles.find(({ handleName }) => handleName === oldNodeHandle.handleName);

          if (newNodeHandle == null) {
            return oldNodeHandle;
          }

          let handleId = oldNodeHandle.handleId;

          if (handleType !== newNodeHandle.handleType || handleCategory !== newNodeHandle.handleCategory) {
            handleId = createHandleId(newNodeHandle);
          }

          return {
            ...oldNodeHandle,
            label: newNodeHandle.label,
            color: newNodeHandle.color,
            handleId,
            handleType: newNodeHandle.handleType,
            handleCategory: newNodeHandle.handleCategory,
          };
        });

      const updatedNodeHandleDiffs: NodePropertyDiff<NodeHandle>[] = updatedNodeHandles.flatMap(
        ({ label, color, handleName, handleId, handleType, handleCategory }) => {
          const oldNodeHandle = oldNodeHandles.find((oldNodeHandle) => oldNodeHandle.handleName === handleName);

          if (oldNodeHandle == null) {
            throw new Error("NodeHandle not found. This should not happen");
          }

          const nodeHandleDiffs: NodePropertyDiff<NodeHandle>[] = [];

          if (label !== oldNodeHandle.label) {
            nodeHandleDiffs.push({
              actionType: "updated",
              property: "label",
              oldValue: oldNodeHandle.label,
              newValue: label,
            });
          }

          if (color !== oldNodeHandle.color) {
            nodeHandleDiffs.push({
              actionType: "updated",
              property: "color",
              oldValue: oldNodeHandle.color,
              newValue: color,
            });
          }

          if (handleId !== oldNodeHandle.handleId) {
            nodeHandleDiffs.push({
              actionType: "updated",
              property: "handleId",
              oldValue: oldNodeHandle.handleId,
              newValue: handleId,
            });
          }

          if (handleType !== oldNodeHandle.handleType) {
            nodeHandleDiffs.push({
              actionType: "updated",
              property: "handleType",
              oldValue: oldNodeHandle.handleType,
              newValue: handleType,
            });
          }

          if (handleCategory !== oldNodeHandle.handleCategory) {
            nodeHandleDiffs.push({
              actionType: "updated",
              property: "handleCategory",
              oldValue: oldNodeHandle.handleCategory,
              newValue: handleCategory,
            });
          }

          return nodeHandleDiffs;
        }
      );

      const createdNodeHandles: T[] = newNodeHandles.filter(
        ({ handleName }) => !oldNodeHandleNames.includes(handleName)
      );

      createdNodeHandles.forEach((createdNodeHandle) => {
        createdNodeHandle.handleId = createHandleId(createdNodeHandle);
      });

      const createdNodeHandleDiffs: NodePropertyDiff<NodeHandle>[] = createdNodeHandles.map((createdNodeHandle) => ({
        actionType: "created",
        property: "handleName",
        oldValue: "",
        newValue: createdNodeHandle.handleName,
      }));

      const orderedNodeHandles = newNodeHandleNames.map((newNodeHandleName) => {
        const createdNodeHandle = createdNodeHandles.find(({ handleName }) => handleName === newNodeHandleName);

        if (createdNodeHandle != null) {
          return createdNodeHandle;
        }

        const updatedNodeHandle = updatedNodeHandles.find(({ handleName }) => handleName === newNodeHandleName);

        if (updatedNodeHandle != null) {
          return updatedNodeHandle;
        }
      });

      return {
        nodeHandles: orderedNodeHandles,
        nodeHandleDiffs: [...createdNodeHandleDiffs, ...updatedNodeHandleDiffs, ...removedNodeHandleDiffs],
      };
    },
    []
  );

  const removedNodes = currentNodes.filter(
    ({ data: { nodeName } }) => !Object.keys(updatedNodeTypesDictionary).includes(nodeName)
  );

  const removedNodeDiffs: NodeDiff[] = removedNodes.map((removedNode) => ({
    actionType: "deleted",
    nodeId: removedNode.id,
    nodeType: removedNode.data.nodeName,
  }));

  const updatedNodes: Node[] = [];
  const updatedNodeDiffs: NodeDiff[] = [];

  currentNodes
    .filter(({ data: { nodeName } }) => Object.keys(updatedNodeTypesDictionary).includes(nodeName))
    .forEach((currentNode) => {
      const updatedNode = structuredClone(currentNode);

      const nodeDataDiffs: NodePropertyDiff<NodeType>[] = [];

      const {
        id: nodeId,
        data: { nodeName: nodeType },
        type,
      } = updatedNode;

      if (nodeType == null) {
        return;
      }

      const updatedNodeType = updatedNodeTypesDictionary[nodeType];

      if (updatedNodeType == null) {
        return;
      }

      const updatedType = getLatestType(updatedNodeType);

      if (type !== updatedType) {
        nodeDataDiffs.push({
          actionType: "updated",
          property: "type",
          oldValue: updatedNode.type,
          newValue: updatedType,
        });

        updatedNode.type = updatedType;
      }

      if (updatedNode.data.label !== updatedNodeType.label) {
        nodeDataDiffs.push({
          actionType: "updated",
          property: "label",
          oldValue: updatedNode.data.label,
          newValue: updatedNodeType.label,
        });

        updatedNode.data.label = updatedNodeType.label;
      }

      if (updatedNode.data.color !== updatedNodeType.color) {
        nodeDataDiffs.push({
          actionType: "updated",
          property: "color",
          oldValue: updatedNode.data.color,
          newValue: updatedNodeType.color,
        });

        updatedNode.data.color = updatedNodeType.color;
      }

      if (updatedNode.data.nodeDescription !== updatedNodeType.nodeDescription) {
        nodeDataDiffs.push({
          actionType: "updated",
          property: "nodeDescription",
          oldValue: updatedNode.data.nodeDescription,
          newValue: updatedNodeType.nodeDescription,
        });

        updatedNode.data.nodeDescription = updatedNodeType.nodeDescription;
      }

      if (updatedNode.data.nodeCategory !== updatedNodeType.nodeCategory) {
        nodeDataDiffs.push({
          actionType: "updated",
          property: "nodeCategory",
          oldValue: updatedNode.data.nodeCategory,
          newValue: updatedNodeType.nodeCategory,
        });

        updatedNode.data.nodeCategory = updatedNodeType.nodeCategory;
      }

      if (updatedNode.data.nodeClass !== updatedNodeType.nodeClass) {
        nodeDataDiffs.push({
          actionType: "updated",
          property: "nodeClass",
          oldValue: updatedNode.data.nodeClass,
          newValue: updatedNodeType.nodeClass,
        });

        updatedNode.data.nodeClass = updatedNodeType.nodeClass;
      }

      if (updatedNode.data.isOrigin !== updatedNodeType.isOrigin) {
        nodeDataDiffs.push({
          actionType: "updated",
          property: "isOrigin",
          oldValue: updatedNode.data.isOrigin,
          newValue: updatedNodeType.isOrigin,
        });

        updatedNode.data.isOrigin = updatedNodeType.isOrigin;
      }

      if (updatedNode.data.isEditable !== updatedNodeType.isEditable) {
        nodeDataDiffs.push({
          actionType: "updated",
          property: "isEditable",
          oldValue: updatedNode.data.isEditable,
          newValue: updatedNodeType.isEditable,
        });

        updatedNode.data.isEditable = updatedNodeType.isEditable;
      }

      if (updatedNode.data.isReady !== updatedNodeType.isReady) {
        nodeDataDiffs.push({
          actionType: "updated",
          property: "isReady",
          oldValue: updatedNode.data.isReady,
          newValue: updatedNodeType.isReady,
        });

        updatedNode.data.isReady = updatedNodeType.isReady;
      }

      if (updatedNode.data.ignoreTargetHandlesDiff !== updatedNodeType.ignoreTargetHandlesDiff) {
        nodeDataDiffs.push({
          actionType: "updated",
          property: "ignoreTargetHandlesDiff",
          oldValue: updatedNode.data.ignoreTargetHandlesDiff,
          newValue: updatedNodeType.ignoreTargetHandlesDiff,
        });

        updatedNode.data.ignoreTargetHandlesDiff = updatedNodeType.ignoreTargetHandlesDiff;
      }

      if (updatedNode.data.shouldValidate !== updatedNodeType.shouldValidate) {
        nodeDataDiffs.push({
          actionType: "updated",
          property: "shouldValidate",
          oldValue: updatedNode.data.shouldValidate,
          newValue: updatedNodeType.shouldValidate,
        });

        updatedNode.data.shouldValidate = updatedNodeType.shouldValidate;
      }

      let targetNodeHandles = updatedNode.data.targetHandles;
      let targetNodeHandleDiffs: NodePropertyDiff<NodeHandle>[] = [];

      if (!updatedNodeType.ignoreTargetHandlesDiff) {
        const { nodeHandles, nodeHandleDiffs } = createUpdatedNodeHandles(
          updatedNode.data.targetHandles,
          updatedNodeType.targetHandles
        );

        targetNodeHandles = nodeHandles;
        targetNodeHandleDiffs = nodeHandleDiffs;
      }

      updatedNode.data.targetHandles = targetNodeHandles;

      if (updatedNode.data.isTargetHandlesEditable !== updatedNodeType.isTargetHandlesEditable) {
        nodeDataDiffs.push({
          actionType: "updated",
          property: "isTargetHandlesEditable",
          oldValue: updatedNode.data.isTargetHandlesEditable,
          newValue: updatedNodeType.isTargetHandlesEditable,
        });

        updatedNode.data.isTargetHandlesEditable = updatedNodeType.isTargetHandlesEditable;
      }

      if (updatedNode.data.ignoreSourceHandlesDiff !== updatedNodeType.ignoreSourceHandlesDiff) {
        nodeDataDiffs.push({
          actionType: "updated",
          property: "ignoreSourceHandlesDiff",
          oldValue: updatedNode.data.ignoreSourceHandlesDiff,
          newValue: updatedNodeType.ignoreSourceHandlesDiff,
        });

        updatedNode.data.ignoreSourceHandlesDiff = updatedNodeType.ignoreSourceHandlesDiff;
      }

      let sourceNodeHandles = updatedNode.data.sourceHandles;
      let sourceNodeHandleDiffs: NodePropertyDiff<NodeHandle>[] = [];

      if (!updatedNodeType.ignoreSourceHandlesDiff) {
        const { nodeHandles, nodeHandleDiffs } = createUpdatedNodeHandles(
          updatedNode.data.sourceHandles,
          updatedNodeType.sourceHandles
        );

        sourceNodeHandles = nodeHandles;
        sourceNodeHandleDiffs = nodeHandleDiffs;
      }

      updatedNode.data.sourceHandles = sourceNodeHandles;

      if (updatedNode.data.isSourceHandlesEditable !== updatedNodeType.isSourceHandlesEditable) {
        nodeDataDiffs.push({
          actionType: "updated",
          property: "isSourceHandlesEditable",
          oldValue: updatedNode.data.isSourceHandlesEditable,
          newValue: updatedNodeType.isSourceHandlesEditable,
        });

        updatedNode.data.isSourceHandlesEditable = updatedNodeType.isSourceHandlesEditable;
      }

      const nodeHandleDiffs: NodePropertyDiff<NodeHandle>[] = [...targetNodeHandleDiffs, ...sourceNodeHandleDiffs];

      if (nodeDataDiffs.length !== 0 || nodeHandleDiffs.length !== 0) {
        updatedNodeDiffs.push({
          actionType: "updated",
          nodeId,
          nodeType,
          nodeDataDiffs,
          nodeHandleDiffs,
        });
      }

      updatedNodes.push(updatedNode);
    });

  const updatedNodeHandleIds = updatedNodes.flatMap(({ data: { targetHandles, sourceHandles } }) =>
    [...targetHandles, ...sourceHandles].map(({ handleId }) => handleId)
  );

  const updatedEdges: Edge[] = currentEdges.filter(
    ({ sourceHandle, targetHandle }) =>
      sourceHandle &&
      targetHandle &&
      updatedNodeHandleIds.includes(sourceHandle) &&
      updatedNodeHandleIds.includes(targetHandle)
  );

  return {
    updatedEdges,
    updatedNodes,
    updatedNodeDiffs: [...removedNodeDiffs, ...updatedNodeDiffs],
  };
};

export default useNodeTypesDiff;
