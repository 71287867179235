import React, { memo, useCallback, useEffect } from "react";
import { NodeProps, useUpdateNodeInternals } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { Box, Checkbox, FormControl, FormLabel, HStack, Input, Text, Textarea, VStack } from "@chakra-ui/react";
import { Control, Controller, useForm, UseFormRegister } from "react-hook-form";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { Select } from "chakra-react-select";
import SchemaPassThroughNodeWithChildren from "./SchemaPassThroughNodeWithChildren";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";

const possibleActions = [
  "chat",
  "end",
  "walk",
  "wander",
  "end-meeting",
  "npc-chat",
  "offer-quest",
  "offer-quest-progression",
  "assign-quest",
  "reject-quest",
  "progress-quest",
  "npc-talk",
  "npc-sleep",
  "npc-attribute-set",
  "npc-attribute-change",
  "npc-emoji",
] as const;

const actionOptions = possibleActions.map((action) => ({ label: action, value: action }));

interface NPCAction {
  npcId: string;
  action: typeof possibleActions[number];
  body: string;
  data: string;
  hasErroredPortEnabled: boolean;
}

const ChatActionInput: React.FC<{
  register: UseFormRegister<NPCAction>;
  control: Control<NPCAction, unknown>;
}> = ({ register }) => {
  return (
    <>
      <FormLabel>Chat Message</FormLabel>
      <Textarea {...register("body")} placeholder="Message template" />
    </>
  );
};

const EmptyActionInput: React.FC<{
  register: UseFormRegister<NPCAction>;
  control: Control<NPCAction, unknown>;
}> = ({ register }) => {
  return (
    <>
      <Input hidden={true} {...register("body")} />
    </>
  );
};

const QuestActionInput: React.FC<{
  register: UseFormRegister<NPCAction>;
  control: Control<NPCAction, unknown>;
}> = ({ register }) => {
  return (
    <>
      <FormLabel>Quest ID</FormLabel>
      <Input {...register("body")} placeholder="Quest ID" />
    </>
  );
};

const WalkActionInput: React.FC<{
  register: UseFormRegister<NPCAction>;
  control: Control<NPCAction, unknown>;
}> = ({ register }) => {
  return (
    <>
      <FormLabel>Walk To</FormLabel>
      <Input {...register("body")} placeholder="Location" />
    </>
  );
};

const NpcChatActionInput: React.FC<{
  register: UseFormRegister<NPCAction>;
  control: Control<NPCAction, unknown>;
}> = ({ register }) => {
  return (
    <>
      <FormLabel>NPC-NPC Chat Message</FormLabel>
      <Textarea {...register("body")} placeholder="Message template" />
    </>
  );
};

const NpcTalkActionInput: React.FC<{
  register: UseFormRegister<NPCAction>;
  control: Control<NPCAction, unknown>;
}> = ({ register }) => {
  return (
    <>
      <FormLabel>NPC Talk Message</FormLabel>
      <Textarea {...register("body")} placeholder="Message template" />
    </>
  );
};

const NpcSleepActionInput: React.FC<{
  register: UseFormRegister<NPCAction>;
  control: Control<NPCAction, unknown>;
}> = ({ register }) => {
  return (
    <>
      <FormLabel>Duration in minutes</FormLabel>
      <Input {...register("body")} type={"number"} min={0} placeholder="Duration in minutes" />
    </>
  );
};

const NpcAttributeSetActionInput: React.FC<{
  register: UseFormRegister<NPCAction>;
  control: Control<NPCAction, unknown>;
}> = ({ register }) => {
  return (
    <>
      <FormLabel>Attribute Set Data</FormLabel>
      <Textarea
        {...register("body")}
        placeholder={JSON.stringify(
          {
            attribute: "<name>",
            value: 0,
            description: "<description>",
            min: 0,
            max: 100,
          },
          null,
          2
        )}
        rows={8}
      />
    </>
  );
};

const NpcAttributeChangeActionInput: React.FC<{
  register: UseFormRegister<NPCAction>;
  control: Control<NPCAction, unknown>;
}> = ({ register }) => {
  return (
    <>
      <FormLabel>Attribute Change Data</FormLabel>
      <Textarea
        {...register("body")}
        placeholder={JSON.stringify(
          {
            attribute: "<name>",
            value: 0,
          },
          null,
          2
        )}
        rows={6}
      />
    </>
  );
};

const emojiOptions = [
  "Wave",
  "Laugh",
  "Jazz Hands",
  "Tea Bag",
  "Sweaty",
  "Exclaim",
  "Applaud",
  "Fisting Gentle",
  "UWU",
  "Heart",
  "Bok Bok",
  "Sit Down",
  "Cat Black",
  "Cat Tabby",
  "Cat Calico",
  "Clench",
].map((emoji) => ({ label: emoji, value: emoji }));

const NpcEmojiActionInput: React.FC<{
  register: UseFormRegister<NPCAction>;
  control: Control<NPCAction, unknown>;
}> = ({ register, control }) => {
  return (
    <>
      <FormLabel>Emoji Name</FormLabel>
      <Controller
        name={"body"}
        control={control}
        render={({ field: { onChange, onBlur, value, name, ref } }) => (
          <Select
            onBlur={onBlur}
            name={name}
            ref={ref}
            value={emojiOptions.find((option) => option.value === value)}
            onChange={(option) => {
              return option && onChange(option.value);
            }}
            options={emojiOptions}
          />
        )}
      />
    </>
  );
};

const actionNameToActionInputMap: Record<
  string,
  React.FC<{
    register: UseFormRegister<NPCAction>;
    control: Control<NPCAction, unknown>;
  }>
> = {
  chat: ChatActionInput,
  end: EmptyActionInput,
  walk: WalkActionInput,
  wander: EmptyActionInput,
  "assign-quest": QuestActionInput,
  "offer-quest": QuestActionInput,
  "offer-quest-progression": QuestActionInput,
  "reject-quest": QuestActionInput,
  "progress-quest": QuestActionInput,
  "end-meeting": EmptyActionInput,
  "npc-chat": NpcChatActionInput,
  "npc-talk": NpcTalkActionInput,
  "npc-sleep": NpcSleepActionInput,
  "npc-attribute-set": NpcAttributeSetActionInput,
  "npc-attribute-change": NpcAttributeChangeActionInput,
  "npc-emoji": NpcEmojiActionInput,
};

const NPCActionNode: React.FC<NodeProps<NodeType<NPCAction>>> = (props) => {
  const {
    id: nodeId,
    data: { nodeData, sourceHandles = [] },
  } = props;
  const { npcId = "", action = "chat", body = "", data = "", hasErroredPortEnabled = false } = nodeData ?? {};

  const { handleSubmit, control, formState, register, watch, setValue } = useForm<NPCAction>({
    defaultValues: {
      npcId,
      action,
      body,
      data,
    },
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);
  const { updateNodeSourceHandles } = useUpdateNodeHandles(nodeId);
  const updateNodeInternals = useUpdateNodeInternals();

  const handleUpdate = useCallback((npcAction: NPCAction) => {
    switch (npcAction.action) {
      case "chat":
        return updateNodeData({
          ...npcAction,
          data: JSON.stringify({ message: npcAction.body }),
        });
      case "end":
        return updateNodeData({
          ...npcAction,
          data: JSON.stringify({}),
        });
      case "walk":
        return updateNodeData({
          ...npcAction,
          data: JSON.stringify({ name: npcAction.body }),
        });
      case "wander":
        return updateNodeData({
          ...npcAction,
          data: JSON.stringify({}),
        });
      case "end-meeting":
        return updateNodeData({
          ...npcAction,
          data: JSON.stringify({}),
        });
      case "offer-quest":
      case "offer-quest-progression":
      case "reject-quest":
      case "progress-quest":
      case "assign-quest":
        return updateNodeData({
          ...npcAction,
          data: JSON.stringify({ questId: npcAction.body }),
        });
      case "npc-chat":
        return updateNodeData({
          ...npcAction,
          data: JSON.stringify({ message: npcAction.body }),
        });
      case "npc-talk":
        return updateNodeData({
          ...npcAction,
          data: JSON.stringify({ message: npcAction.body }),
        });
      case "npc-sleep":
        return updateNodeData({
          ...npcAction,
          data: JSON.stringify({ durationInMinutes: parseInt(npcAction.body) }),
        });
      case "npc-attribute-set":
      case "npc-attribute-change":
        return updateNodeData({
          ...npcAction,
          data: npcAction.body,
        });
      case "npc-emoji":
        return updateNodeData({
          ...npcAction,
          data: JSON.stringify({ emoji: npcAction.body }),
        });
    }
  }, []);

  useEffect(() => {
    if (hasErroredPortEnabled) {
      if (sourceHandles.find(({ handleName }) => handleName === "boolean") == null) {
        updateNodeSourceHandles([
          ...sourceHandles,
          {
            label: "Has Errored",
            handleName: "boolean",
            handleType: "source",
            handleCategory: "data",
          },
        ]);
      }
    } else {
      updateNodeSourceHandles(sourceHandles.filter(({ handleName }) => handleName !== "boolean"));
    }

    updateNodeInternals(nodeId);
  }, [hasErroredPortEnabled]);

  const ActionInput = actionNameToActionInputMap[watch("action")] ?? <></>;

  return (
    <SchemaPassThroughNodeWithChildren {...props}>
      <FormControl
        className={"nodrag"}
        onSubmit={handleSubmit(handleUpdate)}
        onBlur={handleSubmit(handleUpdate)}
        isInvalid={!formState.isValid}
      >
        <VStack spacing={4}>
          <Box w="full">
            <HStack alignItems="baseline">
              <FormLabel>
                <Text casing={"uppercase"}>Has Errored Port Enabled</Text>
              </FormLabel>
              <Checkbox
                onChange={() => {
                  setValue("hasErroredPortEnabled", !hasErroredPortEnabled);
                  handleSubmit(handleUpdate)();
                }}
                checked={hasErroredPortEnabled}
              />
            </HStack>
          </Box>
          <Box w="full">
            <FormLabel>Action</FormLabel>
            <Controller
              name={"action"}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Select
                  onBlur={onBlur}
                  name={name}
                  ref={ref}
                  value={actionOptions.find((option) => option.value === value)}
                  onChange={(option) => {
                    setValue("body", "");
                    return option && onChange(option.value);
                  }}
                  options={actionOptions}
                />
              )}
            />
          </Box>
          <Box w="full">
            <ActionInput register={register} control={control} />
          </Box>
        </VStack>
      </FormControl>
    </SchemaPassThroughNodeWithChildren>
  );
};

export default memo(NPCActionNode);
