import { forwardRef } from "react";
import { IconButton, Tooltip } from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";

const CollapsibleTooltipButton = forwardRef<
  HTMLDivElement,
  {
    isExpanded: boolean;
    toggle: () => void;
    collapseLabel?: string;
    expandLabel?: string;
  }
>(({ isExpanded, toggle, collapseLabel = "Collapse", expandLabel = "Expand" }, ref) => {
  return (
    <Tooltip label={isExpanded ? collapseLabel : expandLabel} ref={ref}>
      <IconButton
        color={"white"}
        size={"xs"}
        icon={isExpanded ? <MinusIcon fontSize="12px" /> : <AddIcon fontSize="12px" />}
        aria-label={"expand"}
        onClick={() => toggle()}
      />
    </Tooltip>
  );
});

export default CollapsibleTooltipButton;
