import { useCallback } from "react";
import { json, LoaderFunctionArgs } from "react-router";
import { AxiosError } from "axios";
import { Workflow } from "../../models/api/workflow";

const useWorkflowLoader = (getWorkflow: (workflowId: string) => Promise<Workflow>, getDisplayName: () => Promise<string>) => {
  const loader = useCallback(
    async ({ params: { id: workflowId } }: LoaderFunctionArgs) => {
      if (!workflowId) {
        throw json(
          {
            message: "Error occurred with workflow identifier...",
          },
          {
            status: 400,
          }
        );
      }

      try {
        return {
          workflow: await getWorkflow(workflowId),
          displayName: await getDisplayName(),
        };
      } catch (error) {
        console.error(error);

        if (error instanceof AxiosError) {
          throw json(
            {
              message: error.response?.data.message,
              details: error.response?.data.details,
            },
            {
              status: error.response?.data.message === "Unauthorized" ? 401 : error.status,
            }
          );
        }

        throw json(
          {
            message: "Error occurred fetching workflow...",
          },
          {
            status: 500,
          }
        );
      }
    },
    [getWorkflow, getDisplayName]
  );

  return { loader };
};

export default useWorkflowLoader;
