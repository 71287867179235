/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormLabel } from "@chakra-ui/form-control";
import { Text } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { useQuery } from "@tanstack/react-query";
import { Select } from "chakra-react-select";
import { Control, Controller } from "react-hook-form";
import { npcsApi } from "../../api";

interface NpcSelectData {
  npcId: string;
}

interface NpcSelectProps<T extends Record<string, any>> {
  control: Control<NpcSelectData & T, unknown>;
  onChange?: (npcId?: string) => void;
  color?: string;
}

function NpcSelect<T extends Record<string, any>>(props: NpcSelectProps<T>) {
  const { control, onChange: onSelectChange, color } = props;

  const getNpcsResult = useQuery({
    queryKey: ["npcs"],
    queryFn: () => npcsApi.getNpcs(),
  });

  const promptIdOptions = [
    { label: "{{npcId}}", value: "{{npcId}}" },
    ...(getNpcsResult.data?.data.map((npc) => ({
      label: npc.displayName,
      value: npc.npcId,
    })) ?? []),
  ];

  return (
    <Box w="full">
      <FormLabel>
        <Text casing={"uppercase"} color={color}>
          NPC
        </Text>
      </FormLabel>
      {getNpcsResult.isError && <Text color={"red.500"}>{getNpcsResult.error.message}</Text>}
      <Controller
        name={"npcId" as any}
        control={control}
        render={({ field: { onChange, onBlur, value, name, ref } }) => (
          <Select
            onBlur={onBlur}
            name={name}
            ref={ref}
            isLoading={getNpcsResult.isFetching}
            isDisabled={getNpcsResult.isFetching || getNpcsResult.isError || !getNpcsResult.data?.data.length}
            value={promptIdOptions && value ? promptIdOptions.find((option) => option.value === value) : null}
            onChange={(option) => {
              if (onSelectChange) {
                onSelectChange(option?.value);
              }
              return option && onChange(option.value);
            }}
            options={promptIdOptions}
          />
        )}
      />
    </Box>
  );
}

export default NpcSelect;
