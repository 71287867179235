import React, { memo } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import SchemaPassThroughNodeWithChildren from "./SchemaPassThroughNodeWithChildren";

const IterationEndNode: React.FC<NodeProps<NodeType>> = (props) => {
  const passThroughProps = {
    ...props,
    data: {
      ...props.data,
      nodeClass: "flow",
    },
  };

  return <SchemaPassThroughNodeWithChildren {...passThroughProps} />;
};

export default memo(IterationEndNode);
