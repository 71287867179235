import React from "react";
import { Center, List, ListProps, Text } from "@chakra-ui/react";
import WorkflowListItem from "./WorkflowListItem";
import { WorkflowWithId } from "../models/api/workflow";

interface WorkflowListProps extends ListProps {
  workflows: WorkflowWithId[];
  onUpdate: (workflow: WorkflowWithId) => void;
  isUpdating: boolean;
  onDelete: (workflowId: string) => void;
  isDeleting: boolean;
  onDuplicate: (workflowId: string) => void;
  isDuplicating: boolean;
  openIndexes: number[];
  onToggleOpenIndex: (index: number) => void;
}

const WorkflowList: React.FC<WorkflowListProps> = ({
  workflows,
  onUpdate,
  isUpdating,
  onDelete,
  isDeleting,
  onDuplicate,
  isDuplicating,
  openIndexes,
  onToggleOpenIndex,
  ...listProps
}) => {
  if (workflows.length === 0) {
    return (
      <Center flexGrow={1}>
        <Text color={"white"} casing={"uppercase"}>
          No workflows found
        </Text>
      </Center>
    );
  }

  return (
    <List borderWidth={2} borderColor={"indigo.600"} borderRadius={5} {...listProps}>
      {workflows.map((workflow, index) => (
        <WorkflowListItem
          key={workflow.workflowId}
          index={index}
          workflow={workflow}
          onUpdate={onUpdate}
          isUpdating={isUpdating}
          onDelete={onDelete}
          isDeleting={isDeleting}
          onDuplicate={onDuplicate}
          isDuplicating={isDuplicating}
          openIndexes={openIndexes}
          onToggleOpenIndex={onToggleOpenIndex}
        />
      ))}
    </List>
  );
};

export default WorkflowList;
