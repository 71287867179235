// noinspection ES6ConvertVarToLetConst

// injected into index.html
declare global {
  // eslint-disable-next-line no-var
  var SERVICE_DOMAIN: string;
}

const buildClientApiUrl = (clientName: string): string => {
  // slice away trailing /
  return `https://play.${SERVICE_DOMAIN}/api/${clientName}`;
};

export default buildClientApiUrl;
