import React, { memo, useCallback } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { Box, FormControl, FormLabel, Text } from "@chakra-ui/react";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import MustacheEditor from "../../editor/MustacheEditor";
import { useAiCompletionSchemaContext } from "../../../context/AiCompletionSchemaContext";
import SchemaPassThroughNodeWithChildren from "./SchemaPassThroughNodeWithChildren";

interface AssignQuestData {
  questNodeId: string;
}

const AssignQuestNode: React.FC<NodeProps<NodeType>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData = {} },
  } = props;

  const formData = nodeData as AssignQuestData | undefined;
  const questNodeId = formData?.questNodeId;

  const { setValue, watch, handleSubmit } = useForm<AssignQuestData>({
    defaultValues: {
      questNodeId,
    },
    mode: "onChange",
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);
  const handleUpdate = useCallback((data: AssignQuestData) => {
    updateNodeData(data);
  }, []);

  const { currentWorkflowSuggestionMap } = useAiCompletionSchemaContext();

  return (
    <SchemaPassThroughNodeWithChildren {...props}>
      <Box p={2} borderWidth={1}>
        <form
          className={"nodrag"}
          onSubmit={handleSubmit(handleUpdate)}
          onBlur={handleSubmit(handleUpdate)}
          onChange={handleSubmit(handleUpdate)}
        >
          <FormControl>
            <FormLabel textTransform={"uppercase"} color={color}>
              Quest Node ID
            </FormLabel>
            <MustacheEditor
              suggestionMap={currentWorkflowSuggestionMap}
              onChange={(value) =>
                setValue("questNodeId", value || "", {
                  shouldDirty: true,
                })
              }
              value={watch("questNodeId") ?? questNodeId}
              hoverDataMap={{}}
              height="5vh"
            />
          </FormControl>
        </form>
      </Box>
    </SchemaPassThroughNodeWithChildren>
  );
};

export default memo(AssignQuestNode);
