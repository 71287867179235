import React, { memo, useCallback, useEffect, useState } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { Box, Button, Checkbox, FormControl, FormLabel, HStack, Select, Text, useDisclosure } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { SchemaObject } from "ajv";
import { BaseNodeWithChildren } from "./base/BaseNode";
import useNodeLookup from "../../../hooks/useNodeLookup";
import { PassThroughNodeOutputOnlyPreviewModal, SchemaData } from "./SchemaPassThroughNodeWithChildren";
import { JSONSchema7 } from "@worldwidewebb/client-ai";

export interface ArrayLoopNodeData extends SchemaData {
  parallelExecution: boolean;
  field: string;
  elementSchema?: string;
}

const ArrayLoopNode: React.FC<NodeProps<NodeType<ArrayLoopNodeData>>> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fields, setFields] = useState<string[]>([]);

  const {
    id: nodeId,
    data: { color, nodeData, targetHandles = [] },
  } = props;

  const field = nodeData?.field ?? "";
  const parallelExecution = nodeData?.parallelExecution ?? false;
  const inputSchema = nodeData?.inputSchema ?? "";
  const outputSchema = nodeData?.outputSchema ?? "";
  const elementSchema = nodeData?.elementSchema ?? "";

  const { register, handleSubmit, setValue } = useForm<ArrayLoopNodeData>({
    defaultValues: {
      field,
      parallelExecution,
      inputSchema,
      outputSchema,
      elementSchema,
    },
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);
  const { getTargetNodeOutputSchema } = useNodeLookup();
  const previousNodeOutputSchema = getTargetNodeOutputSchema(targetHandles.map(({ handleId }) => handleId));

  useEffect(() => {
    updateNodeData({
      ...nodeData,
      inputSchema: previousNodeOutputSchema,
    });

    setValue("inputSchema", previousNodeOutputSchema);

    parseSchema(previousNodeOutputSchema ?? "{}");
  }, [previousNodeOutputSchema]);

  const iterationEndSchema = getTargetNodeOutputSchema(
    targetHandles.map(({ handleId }) => handleId),
    { nodeClass: "element" }
  );

  useEffect(() => {
    if (field && inputSchema) {
      const extractJsonSchema = (path: string, schema: SchemaObject): JSONSchema7 => {
        const paths = path.split(".");

        if (paths.length === 1) {
          return schema.properties?.[path].items;
        }

        const [currentPath, ...rest] = paths;
        const nextSchema = schema.properties?.[currentPath];

        return extractJsonSchema(rest.join("."), nextSchema);
      };

      const schema = JSON.parse(inputSchema);

      const elementSchema = JSON.stringify(extractJsonSchema(field, schema), null, 2);

      setValue("elementSchema", elementSchema);

      updateNodeData({
        ...nodeData,
        elementSchema,
      });
    }
  }, [field, inputSchema]);

  useEffect(() => {
    updateNodeData({
      ...nodeData,
      outputSchema: JSON.stringify(
        {
          type: "array",
          items: !iterationEndSchema
            ? { type: "object" }
            : {
                ...JSON.parse(iterationEndSchema || "{}"),
              },
        },
        null,
        2
      ),
    });
  }, [iterationEndSchema]);

  const extractFields = (schema: SchemaObject, prefix = "") => {
    let fields: string[] = [];

    if (schema.type === "array" && schema.items && !prefix) {
      return [];
    }

    if (schema.type === "array" && schema.items) {
      return [prefix];
    }

    if (schema.type === "object" && schema.properties) {
      for (const key in schema.properties) {
        const path = prefix ? `${prefix}.${key}` : key;

        fields = fields.concat(extractFields(schema.properties[key], path));
      }
    }

    return fields;
  };

  const parseSchema = useCallback(
    (rawSchema: string) => {
      try {
        const schema = JSON.parse(rawSchema);
        const fields = extractFields(schema);
        setFields(fields);
      } catch (e) {
        console.error(e);
        setFields([]);
      }
    },
    [setFields]
  );

  const handleUpdate = useCallback((data: ArrayLoopNodeData) => {
    updateNodeData(data);
  }, []);

  return (
    <BaseNodeWithChildren {...props}>
      <form
        className={"nodrag"}
        onSubmit={handleSubmit(handleUpdate)}
        onBlur={handleSubmit(handleUpdate)}
        onChange={handleSubmit(handleUpdate)}
      >
        <FormControl>
          <Box>
            <FormLabel>
              <Text casing={"uppercase"} color={color}>
                Array Field
              </Text>
            </FormLabel>
            <Select {...register("field")} color={color} placeholder={"Select an array to iterate over"}>
              {fields.map((item) => (
                <option key={item} value={item} selected={item === field}>
                  {item}
                </option>
              ))}
            </Select>
            {fields.length === 0 && (
              <Text color={"red.500"} fontSize={"sm"} mt={2}>
                &nbsp;No array fields found in the input schema
              </Text>
            )}
          </Box>
          <HStack py={3} alignContent="baseline">
            <FormLabel>
              <Text casing={"uppercase"} color={color}>
                Parallel Execution?
              </Text>
            </FormLabel>
            <Checkbox {...register("parallelExecution")} color={color} />
          </HStack>
        </FormControl>
      </form>
      <Button onClick={onOpen} color={color} textTransform={"uppercase"} w={"100%"} variant={"outline"}>
        Preview Schema
      </Button>
      <PassThroughNodeOutputOnlyPreviewModal
        isOpen={isOpen}
        onClose={onClose}
        color={color}
        outputSchema={outputSchema}
      />
    </BaseNodeWithChildren>
  );
};

export default memo(ArrayLoopNode);
