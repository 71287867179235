import { useCallback } from "react";
import { json } from "react-router";
import { AxiosError } from "axios";
import { Workflow } from "../../models/api/workflow";

const useWorkflowsLoader = (getWorkflows: () => Promise<Workflow[]>) => {
  const loader = useCallback(async () => {
    try {
      return {
        workflows: await getWorkflows(),
      };
    } catch (error) {
      console.error(error);

      if (error instanceof AxiosError) {
        throw json(
          {
            message: error.response?.data.message,
            details: error.response?.data.details,
          },
          {
            status: error.response?.data.message === "Unauthorized" ? 401 : error.status,
          }
        );
      }

      throw json(
        {
          message: "Error occurred fetching workflows...",
        },
        {
          status: 500,
        }
      );
    }
  }, []);

  return { loader };
};

export default useWorkflowsLoader;
