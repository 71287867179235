import React, { memo, useEffect } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { BaseNodeWithChildren } from "./base/BaseNode";
import { Button, useDisclosure } from "@chakra-ui/react";
import { PassThroughNodeOutputOnlyPreviewModal } from "./SchemaPassThroughNodeWithChildren";
import useNodeLookup from "../../../hooks/useNodeLookup";
import { ArrayLoopNodeData } from "./ArrayLoopNode";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";

const IterationStartNode: React.FC<NodeProps<NodeType>> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    id: nodeId,
    data: { color, nodeData = {}, targetHandles = [] },
  } = props;

  const { getNodeTypeByTargetHandles } = useNodeLookup();
  const { updateNodeData } = useUpdateNodeData(nodeId);

  const previousNodeSchemas = getNodeTypeByTargetHandles(
    targetHandles.map(({ handleId }) => handleId)
  ) as NodeType<ArrayLoopNodeData>[];

  const elementSchema = previousNodeSchemas[0]?.nodeData?.elementSchema;

  useEffect(() => {
    if (elementSchema) {
      updateNodeData({
        ...nodeData,
        outputSchema: elementSchema,
      });
    }
  }, [elementSchema]);

  return (
    <BaseNodeWithChildren {...props}>
      <Button onClick={onOpen} color={color} textTransform={"uppercase"} w={"100%"} variant={"outline"}>
        Preview Schema
      </Button>
      <PassThroughNodeOutputOnlyPreviewModal
        isOpen={isOpen}
        onClose={onClose}
        color={color}
        outputSchema={elementSchema}
      />
    </BaseNodeWithChildren>
  );
};

export default memo(IterationStartNode);
