import React, { useCallback, useMemo, useState } from "react";
import { useNavigate, useRevalidator } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  HStack,
  Icon,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { MdAdd, MdRefresh } from "react-icons/md";
import SearchBar from "../components/base/SearchBar";
import useWorkflows from "../api/useWorkflows";
import { useLoaderData } from "react-router";
import WorkflowList from "../components/WorkflowList";
import { BiCollapseAlt, BiExpandAlt } from "react-icons/bi";
import { WorkflowWithId } from "../models/api/workflow";

const Workflows: React.FC = () => {
  const { workflows } = useLoaderData() as { workflows: WorkflowWithId[] };
  const { createWorkflow, updateWorkflow, deleteWorkflow, duplicateWorkflow } = useWorkflows();

  const [isReloadingWorkflows, setIsReloadingWorkflows] = useState<boolean>(false);
  const [isCreatingWorkflow, setIsCreatingWorkflow] = useState<boolean>(false);
  const [isUpdatingWorkflow, setIsUpdatingWorkflow] = useState<boolean>(false);
  const [isDeletingWorkflow, setIsDeletingWorkflow] = useState<boolean>(false);
  const [isDuplicatingWorkflow, setIsDuplicatingWorkflow] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>("");

  const filteredWorkflows = useMemo(
    () =>
      workflows
        .filter(({ name }) => name.toLowerCase().includes(searchValue.toLowerCase().trim()))
        .sort(({ name: a }, { name: b }) => a.localeCompare(b)),
    [workflows, searchValue]
  );

  const filteredWorkflowCount = filteredWorkflows.length;

  const toast = useToast();
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();

  const handleReloadWorkflows = useCallback(async () => {
    try {
      setIsReloadingWorkflows(true);

      revalidate();

      toast({
        title: "Reloaded workflows",
        status: "success",
      });
    } catch (error) {
      console.error(error);

      toast({
        title: "Error occurred reloading workflows",
        description: (error as Error).message,
        status: "error",
      });
    } finally {
      setIsReloadingWorkflows(false);
    }
  }, []);

  const handleCreateWorkflow = useCallback(async () => {
    try {
      setIsCreatingWorkflow(true);

      const { workflowId } = await createWorkflow();

      navigate(workflowId);

      toast({
        title: "Created workflow",
        status: "success",
      });
    } catch (error) {
      console.error(error);

      toast({
        title: "Error occurred creating workflow",
        description: (error as Error).message,
        status: "error",
      });
    } finally {
      setIsCreatingWorkflow(false);
    }
  }, [createWorkflow]);

  const handleUpdateWorkflow = useCallback(
    async (workflow: WorkflowWithId) => {
      try {
        setIsUpdatingWorkflow(true);

        await updateWorkflow(workflow);

        revalidate();

        toast({
          title: "Updated workflow",
          status: "success",
        });
      } catch (error) {
        console.error(error);

        toast({
          title: "Error occurred updating workflow",
          description: (error as Error).message,
          status: "error",
        });
      } finally {
        setIsUpdatingWorkflow(false);
      }
    },
    [updateWorkflow]
  );

  const handleDeleteWorkflow = useCallback(
    async (workflowId: string) => {
      try {
        setIsDeletingWorkflow(true);

        await deleteWorkflow(workflowId);

        revalidate();

        toast({
          title: "Deleted workflow",
          status: "success",
        });
      } catch (error) {
        console.error(error);

        toast({
          title: "Error occurred deleting workflow",
          description: (error as Error).message,
          status: "error",
        });
      } finally {
        setIsDeletingWorkflow(false);
      }
    },
    [deleteWorkflow]
  );

  const handleDuplicateWorkflow = useCallback(
    async (workflowId: string) => {
      try {
        setIsDuplicatingWorkflow(true);

        await duplicateWorkflow(workflowId);

        revalidate();

        toast({
          title: "Duplicated workflow",
          status: "success",
        });
      } catch (error) {
        console.error(error);

        toast({
          title: "Error occurred duplicating workflow",
          description: (error as Error).message,
          status: "error",
        });
      } finally {
        setIsDuplicatingWorkflow(false);
      }
    },
    [duplicateWorkflow]
  );

  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const handleCollapseWorkflows = useCallback(() => {
    setOpenIndexes([]);
  }, [filteredWorkflows]);

  const handleExpandWorkflows = useCallback(() => {
    setOpenIndexes(Array.from({ length: filteredWorkflows.length }, (_, index) => index));
  }, [filteredWorkflows]);

  const handleToggleOpenIndex = useCallback(
    (index: number) => {
      if (openIndexes.includes(index)) {
        setOpenIndexes((openIndexes) => openIndexes.filter((openIndex) => openIndex !== index));
      } else {
        setOpenIndexes((openIndexes) => openIndexes.concat(index));
      }
    },
    [openIndexes]
  );

  return (
    <Container maxW={"4xl"} display={"flex"}>
      <Card bg={"theme.dark.background"} borderRadius={0} flexGrow={1}>
        <CardHeader bg={"theme.dark.background"} borderBottomWidth={2} position={"sticky"} top={0} zIndex={1}>
          <Stack>
            <HStack>
              <SearchBar
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                inputFocusKey={"/"}
                placeholder={"Search for workflows"}
              />

              <Tooltip label={"reload workflows"}>
                <IconButton
                  color={"white"}
                  variant={"outline"}
                  icon={<Icon as={MdRefresh} />}
                  aria-label={"reload workflows"}
                  isLoading={isReloadingWorkflows}
                  onClick={handleReloadWorkflows}
                />
              </Tooltip>
              <Tooltip label={"create workflow"}>
                <IconButton
                  color={"white"}
                  variant={"outline"}
                  icon={<Icon as={MdAdd} />}
                  aria-label={"create workflow"}
                  isLoading={isCreatingWorkflow}
                  onClick={handleCreateWorkflow}
                />
              </Tooltip>
            </HStack>

            <HStack>
              <Tooltip label={"expand workflows"}>
                <IconButton
                  color={"white"}
                  variant={"outline"}
                  icon={<Icon as={BiExpandAlt} />}
                  aria-label={"expand workflows"}
                  onClick={handleExpandWorkflows}
                />
              </Tooltip>
              <Tooltip label={"collapse workflows"}>
                <IconButton
                  color={"white"}
                  variant={"outline"}
                  icon={<Icon as={BiCollapseAlt} />}
                  aria-label={"collapse workflows"}
                  onClick={handleCollapseWorkflows}
                />
              </Tooltip>
            </HStack>
          </Stack>
        </CardHeader>

        <CardBody display={"flex"}>
          <WorkflowList
            flexGrow={1}
            workflows={filteredWorkflows}
            onUpdate={handleUpdateWorkflow}
            isUpdating={isUpdatingWorkflow}
            onDelete={handleDeleteWorkflow}
            isDeleting={isDeletingWorkflow}
            onDuplicate={handleDuplicateWorkflow}
            isDuplicating={isDuplicatingWorkflow}
            openIndexes={openIndexes}
            onToggleOpenIndex={handleToggleOpenIndex}
          />
        </CardBody>

        <CardFooter bg={"theme.dark.background"} borderTopWidth={2} position={"sticky"} bottom={0}>
          <Text color={"white"} casing={"uppercase"} fontWeight={500}>
            Showing {filteredWorkflowCount} workflow{filteredWorkflowCount !== 1 ? "s" : ""}
          </Text>
        </CardFooter>
      </Card>
    </Container>
  );
};

export default Workflows;
