import { Box, FormControl, FormLabel, NumberInput, NumberInputField, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Select } from "chakra-react-select";
import React, { memo, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { NodeProps } from "reactflow";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { NodeType } from "../../../models/nodeType";
import { npcsApi } from "../../../api";
import SchemaPassThroughNodeWithChildren from "./SchemaPassThroughNodeWithChildren";
import MustacheEditor from "../../editor/MustacheEditor";
import { useAiCompletionSchemaContext } from "../../../context/AiCompletionSchemaContext";
import useNodeLookup from "../../../hooks/useNodeLookup";

interface LongTermMemory {
  npcId: string;
  memory: string;
  importance: number;
}

const LongTermMemoryNode: React.FC<NodeProps<NodeType<LongTermMemory>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData, targetHandles = [] },
  } = props;

  const npcId = nodeData?.npcId ?? "";
  const memory = nodeData?.memory ?? "";
  const importance = nodeData?.importance ?? 0;

  const { currentWorkflowSuggestionMap, setInputSchema } = useAiCompletionSchemaContext();
  const { getTargetNodeOutputSchema } = useNodeLookup();
  const inputSchema = getTargetNodeOutputSchema(targetHandles.map(({ handleId }) => handleId));

  const { register, handleSubmit, control, formState, setValue, watch } = useForm<LongTermMemory>({
    defaultValues: {
      npcId,
      memory,
      importance,
    },
  });

  const getNpcsResult = useQuery({
    queryKey: ["npcs"],
    queryFn: () => npcsApi.getNpcs(),
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);

  const handleUpdate = useCallback((longTermMemory: LongTermMemory) => {
    updateNodeData(longTermMemory);
  }, []);

  useEffect(() => {
    updateNodeData({
      ...nodeData,
      inputSchema,
    });

    setInputSchema(inputSchema ?? "{}");
  }, [inputSchema]);

  const promptIdOptions = [
    { label: "{{npcId}}", value: "{{npcId}}" },
    ...(getNpcsResult.data?.data.map((npc) => ({
      label: npc.displayName,
      value: npc.npcId,
    })) ?? []),
  ];

  return (
    <SchemaPassThroughNodeWithChildren {...props}>
      <FormControl className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <Box w="full">
          <FormLabel>NPC</FormLabel>
          {getNpcsResult.isError && <Text color={"red.500"}>{getNpcsResult.error.message}</Text>}
          <Controller
            name={"npcId"}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Select
                onBlur={onBlur}
                name={name}
                ref={ref}
                isLoading={getNpcsResult.isFetching}
                isDisabled={getNpcsResult.isFetching || getNpcsResult.isError || !getNpcsResult.data?.data.length}
                value={promptIdOptions && value ? promptIdOptions.find((option) => option.value === value) : null}
                onChange={(option) => option && onChange(option.value)}
                options={promptIdOptions}
              />
            )}
          />
        </Box>
        <FormLabel>
          <Text casing={"uppercase"} color={color}>
            Memory
          </Text>
        </FormLabel>
        <Box w="100%">
          <FormLabel>
            <Text casing={"uppercase"}>Memory</Text>
          </FormLabel>
          <MustacheEditor
            suggestionMap={currentWorkflowSuggestionMap}
            onChange={(value) =>
              setValue("memory", value || "", {
                shouldDirty: true,
              })
            }
            value={watch("memory") ?? memory}
            hoverDataMap={{}}
            height="15vh"
          />
        </Box>
        <FormLabel>
          <Text casing={"uppercase"} color={color}>
            Importance
          </Text>
        </FormLabel>
        <NumberInput min={0} max={100}>
          <NumberInputField {...register("importance", { valueAsNumber: true })} color={color} />
        </NumberInput>
      </FormControl>
    </SchemaPassThroughNodeWithChildren>
  );
};

export default memo(LongTermMemoryNode);
