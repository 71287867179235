import {
  Box,
  Button,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Editor } from "@monaco-editor/react";
import { useEffect, useState } from "react";
import { CopyFromPreviousRunsSelect, useAiWorkflowRunsContext } from "../../context/AiWorkflowRunsContext";

export const ConfigureCustomAiCompletionRunModal: React.FC<{
  nodeId: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (input: string, context: string) => void;
}> = ({ nodeId, isOpen, onClose, onSubmit }) => {
  const [input, setInput] = useState<string>("{}");
  const [context, setContext] = useState<string>("{}");
  const {
    workflowRunQuery: { data: workflowRun },
  } = useAiWorkflowRunsContext();

  useEffect(() => {
    const foundNode = workflowRun?.nodes.find((n) => n.nodeId === nodeId);
    setInput(
      JSON.stringify((Array.isArray(foundNode?.inputs) ? foundNode?.inputs.at(-1) : foundNode?.inputs) || {}, null, 2)
    );
    setContext(JSON.stringify(workflowRun?.context || {}, null, 2));
  }, [workflowRun]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        minW="md"
        maxW={"container.lg"}
        bg={"theme.dark.background"}
        borderColor={"purple.600"}
        borderRadius={0}
        borderWidth={1}
      >
        <ModalHeader>
          <Box>Configure Custom Run</Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Box my={3} w="38%">
              <FormLabel>
                <Text casing={"uppercase"}>Copy from previous runs</Text>
              </FormLabel>
              <CopyFromPreviousRunsSelect />
            </Box>
            <HStack>
              <Box w="38%" pb={6}>
                <FormLabel>
                  <Text casing={"uppercase"}>Input</Text>
                </FormLabel>
                <Editor
                  height="30vh"
                  language="json"
                  theme="vs-dark"
                  value={input}
                  onChange={(value) => setInput(value ?? "")}
                  options={{
                    lineNumbers: "off",
                    minimap: { enabled: false },
                    wordWrap: "on",
                  }}
                />
              </Box>
              <Box w="62%" pb={6}>
                <FormLabel>
                  <Text casing={"uppercase"}>Context</Text>
                </FormLabel>
                <Editor
                  height="30vh"
                  language="json"
                  theme="vs-dark"
                  value={context}
                  onChange={(value) => setContext(value ?? "")}
                  options={{
                    lineNumbers: "off",
                    minimap: { enabled: false },
                    wordWrap: "on",
                  }}
                />
              </Box>
            </HStack>
            <HStack justifyContent={"flex-end"}>
              <Box>
                <Button
                  mr={3}
                  onClick={() => {
                    onSubmit(input, context);
                    onClose();
                  }}
                  variant={"outline"}
                >
                  Run
                </Button>
              </Box>
            </HStack>
          </Box>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
