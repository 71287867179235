import { FormControl } from "@chakra-ui/react";
import React, { memo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { NodeProps } from "reactflow";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { NodeType } from "../../../models/nodeType";
import NpcSelect from "../../base/NpcSelect";
import { BaseNodeWithChildren } from "./base/BaseNode";

interface SessionData {
  npcId: string;
}

// Use base node with children
const CreateFetchSessionNode: React.FC<NodeProps<NodeType<SessionData>>> = (props) => {
  const {
    id: nodeId,
    data: { color },
  } = props;

  const { control, handleSubmit } = useForm<SessionData>({
    defaultValues: {
      npcId: "{{npcId}}",
    },
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);

  const handleUpdate = useCallback((session: SessionData) => {
    updateNodeData(session);
  }, []);

  return (
    <BaseNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <FormControl>
          <NpcSelect control={control} color={color} />
        </FormControl>
      </form>
    </BaseNodeWithChildren>
  );
};

export default memo(CreateFetchSessionNode);
