import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { memo, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { NodeProps, useUpdateNodeInternals } from "reactflow";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { NodeType } from "../../../models/nodeType";
import MustacheEditor from "../../editor/MustacheEditor";
import { useAiCompletionSchemaContext } from "../../../context/AiCompletionSchemaContext";
import useNodeLookup from "../../../hooks/useNodeLookup";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import { MdAdd } from "react-icons/md";
import { BaseNodeWithChildren } from "./base/BaseNode";
import { Editor } from "@monaco-editor/react";
import { JSONSchema7 } from "@worldwidewebb/client-ai";

interface CappyEvaluationMetadata {
  npcId: string;
  dataSourceEnabled: boolean;
  outputSchema: string;
  questionAnswers: {
    key: string;
    question: string;
    answer: string;
  }[];
}

const CappyEvaluationNode: React.FC<NodeProps<NodeType<CappyEvaluationMetadata>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData, targetHandles = [], sourceHandles = [] },
  } = props;

  const dataSourceEnabled = nodeData?.dataSourceEnabled ?? false;
  const npcId = nodeData?.npcId ?? "";
  const outputSchema =
    nodeData?.outputSchema ??
    JSON.stringify(
      {
        type: "object",
        properties: {
          response: {
            type: "array",
            items: {
              key: { type: "string" },
              score: { type: "number" },
            },
          },
        },
      },
      null,
      2
    );

  const questionAnswers = nodeData?.questionAnswers ?? [
    {
      key: "",
      question: "",
      answer: "",
    },
  ];

  const { currentWorkflowSuggestionMap, setInputSchema } = useAiCompletionSchemaContext();
  const { getTargetNodeOutputSchema } = useNodeLookup();
  const inputSchema = getTargetNodeOutputSchema(targetHandles.map(({ handleId }) => handleId));
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { register, handleSubmit, control, setValue, watch } = useForm<CappyEvaluationMetadata>({
    defaultValues: {
      dataSourceEnabled,
      npcId,
      outputSchema,
      questionAnswers,
    },
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);

  const handleUpdate = useCallback((data: CappyEvaluationMetadata) => {
    updateNodeData(data);
  }, []);

  useEffect(() => {
    updateNodeData({
      ...nodeData,
      inputSchema,
    });

    setInputSchema(inputSchema ?? "{}");
  }, [inputSchema]);

  const { updateNodeSourceHandles } = useUpdateNodeHandles(nodeId);
  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    if (dataSourceEnabled) {
      if (sourceHandles.find(({ handleName }) => handleName === "data") == null) {
        updateNodeSourceHandles([
          ...sourceHandles,
          {
            label: "DATA",
            handleName: "data",
            handleType: "source",
            handleCategory: "data",
          },
        ]);
      }
    } else {
      updateNodeSourceHandles(sourceHandles.filter(({ handleName }) => handleName !== "data"));
    }

    updateNodeInternals(nodeId);
  }, [dataSourceEnabled]);

  const handleAddQuestionAnswer = () => {
    setValue("questionAnswers", [...questionAnswers, { key: "", question: "", answer: "" }]);

    handleSubmit(handleUpdate)();
  };

  const handleRemoveQuestionAnswer = (index: number) => {
    setValue("questionAnswers", [...questionAnswers.slice(0, index), ...questionAnswers.slice(index + 1)]);

    handleSubmit(handleUpdate)();
  };

  useEffect(() => {
    const responseMap = {
      type: "object",
      properties: questionAnswers.reduce((acc, { key, answer }) => {
        acc[key] = {
          type: "number",
          description: "Score",
        };

        return acc;
      }, {} as Record<string, JSONSchema7>),
    };

    const parsedSchema = JSON.parse(outputSchema);
    parsedSchema.properties.responseMap = responseMap;

    setValue("outputSchema", JSON.stringify(parsedSchema, null, 2));
  }, [questionAnswers]);

  return (
    <BaseNodeWithChildren {...props}>
      <FormControl className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <VStack spacing={2} alignItems={"flex-start"} w="25rem" maxHeight="30rem" overflowY="auto">
          <Button onClick={() => handleAddQuestionAnswer()} alignSelf={"flex-end"} color={"green.300"}>
            <Icon as={MdAdd} />
            Add evaluation
          </Button>
          {questionAnswers.map((item, index) => (
            <Box key={index} w="100%" p={2}>
              <FormLabel>
                <HStack>
                  <HStack>
                    <Text casing={"uppercase"} color="green.300">
                      #{index + 1}
                    </Text>
                  </HStack>
                  {index > 0 && (
                    <Button size={"xs"} onClick={() => handleRemoveQuestionAnswer(index)} color={"red.300"}>
                      Remove
                    </Button>
                  )}
                </HStack>
              </FormLabel>
              <Text casing={"uppercase"}>key</Text>
              <Input {...register(`questionAnswers.${index}.key`)} placeholder="Key" mb={2} />
              <Text casing={"uppercase"}>question</Text>
              <MustacheEditor
                suggestionMap={currentWorkflowSuggestionMap}
                onChange={(value) => {
                  setValue(`questionAnswers.${index}.question`, value || "", {
                    shouldDirty: true,
                  });
                }}
                value={watch(`questionAnswers.${index}.question`) ?? item.question}
                hoverDataMap={{}}
                height="8vh"
              />
              <Text casing={"uppercase"}>answer</Text>
              <MustacheEditor
                suggestionMap={currentWorkflowSuggestionMap}
                onChange={(value) => {
                  setValue(`questionAnswers.${index}.answer`, value || "", {
                    shouldDirty: true,
                  });
                }}
                value={watch(`questionAnswers.${index}.answer`) ?? item.answer}
                hoverDataMap={{}}
                height="8vh"
              />
            </Box>
          ))}
        </VStack>
        <Button onClick={onOpen} textTransform={"uppercase"} w={"100%"} mt={2}>
          Preview Output Schema
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent
            bg={"theme.dark.background"}
            borderColor="yellow.500"
            borderRadius={0}
            borderWidth={1}
            minW="md"
            maxW="container.md"
          >
            <ModalHeader>
              <Text color={color}>Output Schema Preview</Text>
            </ModalHeader>

            <ModalBody>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Output JSON Schema
                </Text>
              </FormLabel>
              <Editor
                height="50vh"
                language="json"
                theme="vs-dark"
                value={watch("outputSchema")}
                options={{ readOnly: true }}
              />
            </ModalBody>

            <ModalFooter gap={1}>
              <Button onClick={onClose} color={"white"} variant={"outline"}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </FormControl>
    </BaseNodeWithChildren>
  );
};

export default memo(CappyEvaluationNode);
