import { memo, useCallback, useEffect, useState } from "react";
import Editor, { useMonaco, Monaco } from "@monaco-editor/react";
import { editor, IDisposable, languages, Position } from "monaco-editor";
import { Box, Icon, HStack, IconButton } from "@chakra-ui/react";
import { MdFullscreen, MdFullscreenExit } from "react-icons/md";

interface MustacheEditorProps {
  value?: string;
  onChange: (value?: string) => void;
  suggestionMap: SuggestionMap;
  hoverDataMap: HoverDataMap;
  height?: string;
}

export enum SuggestionDataType {
  Object = "object",
  ArrayOfPrimitives = "arrayOfPrimitives",
  ArrayOfObjects = "arrayOfObjects",
  String = "string",
  Number = "number",
  Boolean = "boolean",
}

export type SuggestionMap = {
  [key: string]: Suggestion;
};

export type HoverDataMap = {
  [key: string]: HoverDataMap | unknown;
};

export type Suggestion = {
  name: string;
  detail: string;
  documentation: string;
  type: SuggestionDataType;
  children?: SuggestionMap;
};

type FormattedSuggestion = {
  name: string;
  detail: string;
  type: SuggestionDataType;
  documentation: string;
};

const MustacheEditor: React.FC<MustacheEditorProps> = ({ suggestionMap, hoverDataMap, value, onChange, height }) => {
  const monaco = useMonaco();
  const [completionProvider, setCompletionProvider] = useState<IDisposable>();
  const [hoverProvider, setHoverProvider] = useState<IDisposable>();
  const [isMaximized, setIsMaximized] = useState(false);

  useEffect(() => {
    if (!monaco) {
      return;
    }

    monaco.editor.defineTheme(`mustache-vs-dark`, {
      base: "vs-dark",
      inherit: true,
      rules: [
        { token: "tag", foreground: "7F47E5" }, // Purple color for tags
        { token: "tag.special", foreground: "FF4081" }, // Pink color for special tags
        { token: "text", foreground: "FFFFFF" }, // White color for text
      ],
      colors: {},
    });

    monaco.editor.setTheme("mustache-vs-dark");
  }, [monaco]);

  const onEditorMount = useCallback(
    (editor: editor.IStandaloneCodeEditor, monaco: Monaco) => {
      monaco.languages.register({ id: "mustache" });

      monaco.languages.setMonarchTokensProvider("mustache", {
        tokenizer: {
          root: [
            // Match standard Mustache tags
            [/\{\{[^#/^][^}]*\}\}/, "tag"],

            // Match opening tag for sections, inverted sections, or blocks
            [/\{\{[#/^][^}]*\}\}/, "tag.special"],

            // Match opening tag for sections or blocks
            [/\{\{#[^}]*\}\}/, "tag.special"],

            // Match closing tag for sections or blocks
            [/\{\{\/[^}]*\}\}/, "tag.special"],

            // Catch all other text
            [/./, "text"],
          ],
        },
      });
    },
    [suggestionMap]
  );

  const findSuggestions = useCallback((inputParts: string[], suggestionsList: SuggestionMap): FormattedSuggestion[] => {
    if (inputParts.length === 0) {
      return Object.values(suggestionsList).map(({ name, detail, type, documentation }) => ({
        name,
        detail,
        type,
        documentation,
      }));
    }

    const currentPart = inputParts[0].replaceAll("#", "").replaceAll("^", "");
    const restParts = inputParts.slice(1);

    const children = suggestionsList[currentPart]?.children;
    const type = suggestionsList[currentPart]?.type;

    if (!children) {
      return findSuggestions([], suggestionsList);
    }

    if (type === SuggestionDataType.ArrayOfPrimitives) {
      return findSuggestions([], {
        value: {
          name: ".",
          detail: children.items.detail,
          type: children.items.type,
          documentation: children.items.documentation,
        },
      });
    }

    return findSuggestions(restParts, children);
  }, []);

  const filterTagChainArray = useCallback((tagChain: string[], suggestionList: SuggestionMap): string[] => {
    if (tagChain.length === 0) {
      return [];
    }

    // if the current first tag is nested, split it and add it to the tagChain
    tagChain = tagChain[0].includes(".") ? [...tagChain[0].split("."), ...tagChain.slice(1)] : tagChain;
    const children = suggestionList[tagChain[0]]?.children ?? {};

    if (
      suggestionList[tagChain[0]]?.type == SuggestionDataType.ArrayOfObjects ||
      // if last processed tag in the chain is an array of primitives, return the chain
      (suggestionList[tagChain[0]]?.type === SuggestionDataType.ArrayOfPrimitives && tagChain.length === 1) ||
      // if the current tag is an object and there are still array tags in the chain, return the chain
      (suggestionList[tagChain[0]]?.type === SuggestionDataType.Object &&
        filterTagChainArray(tagChain.slice(1), children).length > 0)
    ) {
      return tagChain;
    } else {
      return filterTagChainArray(tagChain.slice(1), suggestionList);
    }
  }, []);

  const formatSuggestions = useCallback(
    (
      editor: NonNullable<typeof monaco>,
      suggestionList: FormattedSuggestion[],
      position: Position,
      startColumnOffset = 1,
      parts: string[] = [],
      contextLevel = 0
    ) => {
      const isSpecial = parts.length > 0 && (parts[0].startsWith("#") || parts[0].startsWith("^"));

      return suggestionList.flatMap(({ name, detail, type, documentation }) => {
        const typedTextAfterBraces: string = parts.length > 0 ? parts.slice(contextLevel).join(".") + "." : "";
        const text: string = `${name}`.replaceAll("#", "").replaceAll("^", "");
        const filterText: string = `${typedTextAfterBraces === "." ? "" : typedTextAfterBraces}${name}`;
        const isNested = parts.length > 0;

        let completionType = "";
        switch (type) {
          case SuggestionDataType.Object:
            completionType = "object";
            break;
          case SuggestionDataType.ArrayOfObjects:
          case SuggestionDataType.ArrayOfPrimitives:
            completionType = "array";
            break;
          default:
            completionType = "primitive";
        }

        /**
         * Creates a completion item for the editor suggestion
         * @param label - defines the label displayed in the autocomplete
         * special char will be prepended, type defines the type of the label where:
         * - "opened" doesn't show any braces e.g. name
         * - "closed" show both braces e.g. {{name}}
         * - "right": shows braces on the right side only e.g name}}
         * - "left": shows braces on the left side only e.g. {{name
         * @param insert - defines the text to be inserted in the editor when the suggestion is selected
         * text defines the text to be inserted, specialChar defines the special char to be prepended to the text
         * closing defines whether to insert the closing tag or not (e.g. for {{#name}} it will add {{/name}} after the cursor)
         * closed defines whether to add closing braces if suggestion is selected f.e. for {{name it will add }}
         * @param filterTextPrefix - defines the prefix to be added to the filter text
         */
        const createCompletionItem = ({
          label,
          insert,
          filterTextPrefix,
        }: {
          label: {
            text: string;
            specialChar: "" | "#" | "^" | "#..." | "^...";
            type: "opened" | "closed" | "left" | "right";
          };
          insert: {
            text: string;
            specialChar: "" | "#" | "^";
            closing: boolean;
            closed: boolean;
          };
          filterTextPrefix: "" | "#" | "^";
        }): languages.CompletionItem => {
          let labelText = label.text;
          switch (label.type) {
            case "opened":
              labelText = `${label.specialChar}${labelText}`;
              break;
            case "closed":
              labelText = `{{${label.specialChar}${labelText}}}`;
              break;
            case "right":
              labelText = `${label.specialChar}${labelText}}}`;
              break;
          }

          let insertText = insert.text;
          let insertTextRules: languages.CompletionItemInsertTextRule | undefined = undefined;

          if (insert.closing) {
            insertText = `${insert.specialChar}${insert.text}}}\n\t$0\n{{/${insert.text
              .replaceAll("#", "")
              .replaceAll("^", "")}}}`;
            insertTextRules = editor.languages.CompletionItemInsertTextRule.InsertAsSnippet;
          } else if (insert.closed && !insert.closing) {
            insertText = `${insert.specialChar}${insertText}}}`;
          } else {
            insertText = `${insert.specialChar}${insertText}`;
          }

          return {
            label: labelText,
            kind: editor.languages.CompletionItemKind.Variable,
            insertText,
            insertTextRules,
            filterText: `${filterTextPrefix}${filterText}`,
            range: {
              startLineNumber: position.lineNumber,
              endLineNumber: position.lineNumber,
              startColumn: startColumnOffset,
              endColumn: position.column,
            },
            sortText: `{{ ${label.text}}}`,
            detail,
            documentation,
          };
        };

        // decision tree for completion items ("_" represents current position of the cursor)
        const completionItemsDecisionTree = {
          // non nested suggestions - first level e.g. {{_ or {{#_ or {{^_
          nonNested: {
            object: [
              createCompletionItem({
                label: { text, specialChar: "", type: "opened" },
                insert: { text: filterText, specialChar: "", closing: false, closed: false },
                filterTextPrefix: "",
              }),
              createCompletionItem({
                label: { text, specialChar: "#", type: "right" },
                insert: { text: filterText, specialChar: "#", closing: true, closed: true },
                filterTextPrefix: "#",
              }),
              createCompletionItem({
                label: { text, specialChar: "^", type: "right" },
                insert: { text: filterText, specialChar: "^", closing: true, closed: true },
                filterTextPrefix: "^",
              }),
              createCompletionItem({
                label: { text, specialChar: "#", type: "opened" },
                insert: { text: filterText, specialChar: "#", closing: false, closed: false },
                filterTextPrefix: "#",
              }),
              createCompletionItem({
                label: { text, specialChar: "^", type: "opened" },
                insert: { text: filterText, specialChar: "^", closing: false, closed: false },
                filterTextPrefix: "^",
              }),
            ],
            array: [
              createCompletionItem({
                label: { text, specialChar: "#", type: "right" },
                insert: { text: filterText, specialChar: "#", closing: true, closed: true },
                filterTextPrefix: "#",
              }),
              createCompletionItem({
                label: { text: `${text}.length`, specialChar: "#", type: "right" },
                insert: { text: `${filterText}.length`, specialChar: "#", closing: true, closed: true },
                filterTextPrefix: "#",
              }),
              createCompletionItem({
                label: { text: `${text}.length`, specialChar: "^", type: "right" },
                insert: { text: `${filterText}.length`, specialChar: "^", closing: true, closed: true },
                filterTextPrefix: "^",
              }),
            ],
            primitive: [
              createCompletionItem({
                label: { text, specialChar: "", type: "right" },
                insert: { text: filterText, specialChar: "", closing: false, closed: true },
                filterTextPrefix: "",
              }),
              createCompletionItem({
                label: { text, specialChar: "#", type: "right" },
                insert: { text: filterText, specialChar: "#", closing: true, closed: true },
                filterTextPrefix: "#",
              }),
              createCompletionItem({
                label: { text, specialChar: "^", type: "right" },
                insert: { text: filterText, specialChar: "^", closing: true, closed: true },
                filterTextPrefix: "^",
              }),
            ],
          },
          // nested suggestions e.g. object children {{obj._ or in context {{#arr}} {{obj._ {{/arr}}
          nested: {
            // within special tag context - first level has special char e.g. {{#obj.firstChild._
            special: {
              object: [
                createCompletionItem({
                  label: { text, specialChar: "", type: "opened" },
                  insert: { text: filterText, specialChar: "", closing: false, closed: false },
                  filterTextPrefix: "",
                }),
                createCompletionItem({
                  label: { text, specialChar: "", type: "right" },
                  insert: { text: filterText, specialChar: "", closing: true, closed: true },
                  filterTextPrefix: "",
                }),
              ],
              array: [
                createCompletionItem({
                  label: { text, specialChar: "", type: "right" },
                  insert: { text: filterText, specialChar: "", closing: true, closed: true },
                  filterTextPrefix: "",
                }),
                createCompletionItem({
                  label: { text: `${text}.length`, specialChar: "", type: "right" },
                  insert: { text: `${filterText}.length`, specialChar: "", closing: true, closed: true },
                  filterTextPrefix: "",
                }),
              ],
              primitive: [
                createCompletionItem({
                  label: { text, specialChar: "", type: "right" },
                  insert: { text: filterText, specialChar: "", closing: true, closed: true },
                  filterTextPrefix: "",
                }),
              ],
            },
            // not in special tag context - first level doesn't have special char e.g. {{obj.firstChild._
            nonSpecial: {
              // inside the context of another tag e.g. {{#arr}} {{childObj._ {{/arr}}
              inContext: {
                object: [
                  createCompletionItem({
                    label: { text, specialChar: "", type: "opened" },
                    insert: { text: filterText, specialChar: "", closing: false, closed: false },
                    filterTextPrefix: "",
                  }),
                  createCompletionItem({
                    label: { text, specialChar: "#", type: "right" },
                    insert: { text: filterText, specialChar: "#", closing: true, closed: true },
                    filterTextPrefix: "#",
                  }),
                  createCompletionItem({
                    label: { text, specialChar: "^", type: "right" },
                    insert: { text: filterText, specialChar: "^", closing: true, closed: true },
                    filterTextPrefix: "^",
                  }),
                ],
                array: [
                  createCompletionItem({
                    label: { text, specialChar: "#...", type: "right" },
                    insert: { text: filterText, specialChar: "#", closing: true, closed: true },
                    filterTextPrefix: "#",
                  }),
                  createCompletionItem({
                    label: { text: `${text}.length`, specialChar: "#...", type: "right" },
                    insert: { text: `${filterText}.length`, specialChar: "#", closing: true, closed: true },
                    filterTextPrefix: "#",
                  }),
                  createCompletionItem({
                    label: { text: `${text}.length`, specialChar: "^...", type: "right" },
                    insert: { text: `${filterText}.length`, specialChar: "^", closing: true, closed: true },
                    filterTextPrefix: "^",
                  }),
                ],
                primitive: [
                  createCompletionItem({
                    label: { text, specialChar: "", type: "right" },
                    insert: { text: filterText, specialChar: "", closing: false, closed: true },
                    filterTextPrefix: "",
                  }),
                  createCompletionItem({
                    label: { text, specialChar: "#", type: "right" },
                    insert: { text: filterText, specialChar: "#", closing: true, closed: true },
                    filterTextPrefix: "#",
                  }),
                  createCompletionItem({
                    label: { text, specialChar: "^", type: "right" },
                    insert: { text: filterText, specialChar: "^", closing: true, closed: true },
                    filterTextPrefix: "^",
                  }),
                ],
              },
              // not in context of another tag e.g. {{childObj._
              notInContext: {
                object: [
                  createCompletionItem({
                    label: { text, specialChar: "", type: "opened" },
                    insert: { text: filterText, specialChar: "", closing: false, closed: false },
                    filterTextPrefix: "",
                  }),
                ],
                array: [
                  createCompletionItem({
                    label: { text, specialChar: "#...", type: "right" },
                    insert: { text: filterText, specialChar: "#", closing: true, closed: true },
                    filterTextPrefix: "",
                  }),
                  createCompletionItem({
                    label: { text: `${text}.length`, specialChar: "#...", type: "right" },
                    insert: { text: `${filterText}.length`, specialChar: "#", closing: true, closed: true },
                    filterTextPrefix: "",
                  }),
                  createCompletionItem({
                    label: { text: `${text}.length`, specialChar: "^...", type: "right" },
                    insert: { text: `${filterText}.length`, specialChar: "^", closing: true, closed: true },
                    filterTextPrefix: "",
                  }),
                ],
                primitive: [
                  createCompletionItem({
                    label: { text, specialChar: "", type: "right" },
                    insert: { text: filterText, specialChar: "", closing: false, closed: true },
                    filterTextPrefix: "",
                  }),
                ],
              },
            },
          },
        } as {
          nonNested: { [key: string]: languages.CompletionItem[] };
          nested: {
            special: {
              [key: string]: languages.CompletionItem[];
            };
            nonSpecial: {
              inContext: { [key: string]: languages.CompletionItem[] };
              notInContext: { [key: string]: languages.CompletionItem[] };
            };
          };
        };

        // return completion items based on the decision tree
        let completionItems = [] as languages.CompletionItem[];
        if (isNested) {
          if (isSpecial) {
            completionItems = completionItemsDecisionTree.nested.special[completionType];
          } else {
            completionItems =
              completionItemsDecisionTree.nested.nonSpecial[contextLevel > 0 ? "inContext" : "notInContext"][
                completionType
              ];
          }
        } else {
          completionItems = completionItemsDecisionTree.nonNested[completionType];
        }

        return completionItems;
      });
    },
    []
  );

  /**
   * Returns an array of tags opened until the current cursor position e.g.
   * for {{#obj}} {{childObj}} {{/obj}} it will return ["obj", "childObj"]
   * detects and ignores conditionals and the special length tags used for checking if an array is empty
   */
  const getOpeningTagsChainUntilPosition = useCallback((text: string, suggestionMap: SuggestionMap) => {
    const tagChain = [] as string[];
    const tagRegex = /{\{([^}]*)\}\}/g;

    let match;
    while ((match = tagRegex.exec(text)) !== null) {
      const tag = match[1].trim();
      const isClosingTag = tag.startsWith("/");
      const isOpeningTag = tag.startsWith("#");

      if (isClosingTag && tagChain.length > 0 && tagChain[tagChain.length - 1] === tag.slice(1)) {
        tagChain.pop();
      }

      if (isOpeningTag) {
        tagChain.push(tag.slice(1));
      }
    }

    return filterTagChainArray(
      tagChain.filter((tag) => !tag.endsWith(".length")),
      suggestionMap || {}
    );
  }, []);

  /**
   * Loads the completion provider for the editor
   */
  const loadCompletions = useCallback((editor: NonNullable<typeof monaco>, suggestionMap: SuggestionMap) => {
    const completionProvider = editor.languages.registerCompletionItemProvider("mustache", {
      // characters that trigger autocomplete
      triggerCharacters: ["{", "."],
      provideCompletionItems: (model: editor.ITextModel, position) => {
        const textUntilPosition = model.getValueInRange({
          startLineNumber: position.lineNumber,
          startColumn: 1,
          endLineNumber: position.lineNumber,
          endColumn: position.column,
        });

        const fullTextUntilPosition = model.getValueInRange({
          startLineNumber: 1,
          startColumn: 1,
          endLineNumber: position.lineNumber,
          endColumn: position.column,
        });

        const textMatchBefore = textUntilPosition.match(/\{\{([^}]*)$/);

        if (!textMatchBefore) {
          return { suggestions: [] };
        }

        const openingTagsChainUntilPosition = getOpeningTagsChainUntilPosition(fullTextUntilPosition, suggestionMap);
        const typedText = textMatchBefore[1]; // Text after '{{'
        const typedTextAfterBraces = typedText.split(".").slice(0, -1).join("."); // Split and remove last part
        const parts = [...openingTagsChainUntilPosition, ...typedTextAfterBraces.split(".").filter(Boolean)]; // Split and remove empty parts

        // Otherwise, find suggestions based on typed parts
        const filteredSuggestions = findSuggestions(parts, suggestionMap || {});

        // Format suggestions for IntelliSense
        return {
          suggestions: formatSuggestions(
            editor,
            filteredSuggestions,
            position,
            (textMatchBefore.index || 0) + 3,
            parts,
            openingTagsChainUntilPosition.length
          ),
        };
      },
    });

    return completionProvider;
  }, []);

  /**
   * Fetches the content to be displayed in the hover popup
   */
  const fetchContentFromHoverData = useCallback((tagChain: string[], hoverDataMap: HoverDataMap): string => {
    const decorateReturnValue = (value: string): string => `Last run's value: ${value}`;
    if (tagChain.length === 0) {
      return "";
    }

    if (tagChain.length === 1) {
      const value = hoverDataMap[tagChain[0]];

      if (typeof value === "object" || Array.isArray(value)) {
        const valueAsString = JSON.stringify(value, null, 2);
        return decorateReturnValue(valueAsString.length > 200 ? `${valueAsString.slice(0, 200)}...` : valueAsString);
      }

      if (value === undefined) {
        return "";
      }

      return decorateReturnValue(JSON.stringify(value));
    }

    const currentTag = tagChain[0];
    if (hoverDataMap[currentTag] === undefined) {
      return "";
    }

    return fetchContentFromHoverData(tagChain.slice(1), hoverDataMap[currentTag] as HoverDataMap);
  }, []);

  /**
   * Fetches the suggestion docs content to be displayed in the hover popup
   */
  const fetchContentFromSuggestionMap = useCallback((tagChain: string[], suggestionMap: SuggestionMap): string => {
    if (tagChain.length === 0) {
      return "";
    }

    if (tagChain.length === 1) {
      return suggestionMap[tagChain[0]]?.documentation ?? "";
    }

    const currentTag = tagChain[0];
    if (!suggestionMap[currentTag]?.children) {
      return "";
    }

    return fetchContentFromSuggestionMap(tagChain.slice(1), suggestionMap[currentTag].children as SuggestionMap);
  }, []);

  /**
   * Loads the hover provider for the editor
   */
  const loadHoverCompletions = useCallback(
    (editor: NonNullable<typeof monaco>, suggestionMap: SuggestionMap, hoverDataMap: HoverDataMap) => {
      const hoverProvider = editor.languages.registerHoverProvider("mustache", {
        provideHover: (model, position) => {
          const word = model.getWordAtPosition(position);
          if (!word) {
            return { contents: [] };
          }

          const currentLine = model.getLineContent(position.lineNumber);

          // keep going right until we find the closing braces
          let endColumn = word.endColumn;
          let lastTag: string | undefined;
          while (endColumn < currentLine.length + 3) {
            const textUntilPosition = model.getValueInRange({
              startLineNumber: position.lineNumber,
              startColumn: 1,
              endLineNumber: position.lineNumber,
              endColumn: endColumn++,
            });

            if (textUntilPosition.endsWith("{{")) {
              break;
            }

            if (textUntilPosition.endsWith("}}")) {
              const match = textUntilPosition.match(/\{\{[#^/]?([^{}]+)\}\}(?![\s\S]*\{\{)/);
              if (match && match[1]) {
                lastTag = match[1].trim();
              }
              break;
            }
          }

          if (!lastTag) {
            return { contents: [] };
          }

          const fullTextUntilPosition = model.getValueInRange({
            startLineNumber: 1,
            startColumn: 1,
            endLineNumber: position.lineNumber,
            endColumn: endColumn + 1,
          });

          const openingTagsChainUntilPosition = getOpeningTagsChainUntilPosition(
            `${fullTextUntilPosition} {{`,
            suggestionMap
          );
          const tagChain = [...openingTagsChainUntilPosition, ...lastTag.split(".")];
          if (
            tagChain[tagChain.length - 1] === tagChain[tagChain.length - 2] ||
            tagChain[tagChain.length - 1] === "length"
          ) {
            // remove last tag if it's the same as the previous one or if it's the length tag
            tagChain.pop();
          }

          return {
            contents: [
              { value: fetchContentFromHoverData(tagChain, hoverDataMap) },
              { value: fetchContentFromSuggestionMap(tagChain, suggestionMap) },
            ],
          };
        },
      });

      return hoverProvider;
    },
    []
  );

  const onEditorBlur = useCallback(() => {
    // dispose completion and hover providers when editor is blurred
    if (completionProvider) {
      completionProvider.dispose();
      setCompletionProvider(undefined);
    }

    if (hoverProvider) {
      hoverProvider.dispose();
      setHoverProvider(undefined);
    }
  }, [completionProvider]);

  const onEditorFocus = useCallback(() => {
    // make sure there are no completion or hover providers before loading them
    if (completionProvider) {
      completionProvider.dispose();
    }
    if (hoverProvider) {
      hoverProvider.dispose();
    }
    if (!monaco) {
      return;
    }
    // load completion and hover providers when editor is focused
    setCompletionProvider(loadCompletions(monaco, suggestionMap));
    setHoverProvider(loadHoverCompletions(monaco, suggestionMap, hoverDataMap));
  }, [completionProvider, suggestionMap, hoverDataMap, monaco]);

  return (
    <>
      <Box
        onBlur={onEditorBlur}
        onFocus={onEditorFocus}
        style={
          isMaximized
            ? {
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 100,
                backgroundColor: "black",
              }
            : {}
        }
      >
        <Editor
          height={isMaximized ? "100%" : height || "30vh"}
          width={isMaximized ? "100%" : "auto"}
          language="mustache"
          theme="mustache-vs-dark"
          value={value}
          onChange={(value) => onChange(value)}
          options={{
            lineNumbers: "off",
            minimap: { enabled: false },
            wordWrap: "on",
            hover: {
              enabled: true,
            },
            quickSuggestions: true,
          }}
          onMount={onEditorMount}
        />
        <HStack justifyContent={"flex-end"}>
          <IconButton
            variant={"solid"}
            icon={<Icon as={isMaximized ? MdFullscreenExit : MdFullscreen} />}
            aria-label={"execute prompt"}
            size={"xs"}
            onClick={() => setIsMaximized(!isMaximized)}
          />
        </HStack>
      </Box>
    </>
  );
};

export default memo(MustacheEditor);
