import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { JSONSchema7, JSONSchema7Definition } from "@worldwidewebb/client-ai";
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLoaderData } from "react-router";
import useAi from "../api/useAi";
import { SuggestionDataType, SuggestionMap } from "../components/editor/MustacheEditor";
import { WorkflowWithId } from "../models/api/workflow";

interface ContextType {
  currentWorkflowSuggestionMap: SuggestionMap;
  getAiCompletionSchemaQuery: UseQueryResult<JSONSchema7, unknown>;
  setInputSchema: (schema: string) => void;
  inputSchema: string;
}

const Context = createContext<ContextType | null>(null);

const AiCompletionSchemaProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { workflow } = useLoaderData() as { workflow: WorkflowWithId };
  const [inputSchema, setInputSchema] = useState<string>("{}");
  const { getAiCompletionSchema } = useAi();

  const getAiCompletionSchemaQuery = useQuery({
    queryKey: ["aiCompletionSchema", workflow.name],
    queryFn: () => getAiCompletionSchema(workflow.name),
    enabled: !!workflow.name,
  });

  const parseProperties = useCallback((properties: { [key: string]: JSONSchema7Definition }): SuggestionMap => {
    return Object.entries(properties)
      .map(([key, value]) => {
        value.description = value.description ?? "";

        switch (value.type) {
          case "object":
            return {
              name: key,
              detail: "object",
              documentation: `${value.description} \n\n${JSON.stringify(value.properties, null, 2)}`,
              type: SuggestionDataType.Object,
              children: parseProperties(value.properties ?? {}),
            };
          case "array":
            if (value.items?.type === "object") {
              return {
                name: key,
                detail: `Array<object>`,
                documentation: `${value.description} \n\n${JSON.stringify(value.items.properties, null, 2)}`,
                type: SuggestionDataType.ArrayOfObjects,
                children: parseProperties(value.items?.properties ?? {}),
              };
            } else {
              return {
                name: key,
                detail: `Array<${value.items?.type}>`,
                documentation: `${value.description} \n\n${JSON.stringify(value.items, null, 2)}`,
                type: SuggestionDataType.ArrayOfPrimitives,
                children: {
                  items: {
                    type: value.items?.type,
                    description: value.items?.description,
                  },
                },
              };
            }
          case "string":
            return {
              name: key,
              detail: "string",
              documentation: value.description,
              type: SuggestionDataType.String,
            };
          case "number":
          case "integer":
            return {
              name: key,
              detail: "number",
              documentation: value.description,
              type: SuggestionDataType.Number,
            };
          case "boolean":
            return {
              name: key,
              detail: "boolean",
              documentation: value.description,
              type: SuggestionDataType.Boolean,
            };
          default:
            return {
              name: key,
              documentation: value.description,
              detail: "null",
            };
        }
      })
      .reduce((acc, curr) => ({ ...acc, [curr.name]: curr }), {});
  }, []);

  const parseJson = useCallback((json: string) => {
    try {
      return JSON.parse(json);
    } catch (e) {
      return {};
    }
  }, []);

  const suggestionMap = useMemo(() => {
    const completionSchemaProperties = getAiCompletionSchemaQuery.data?.properties ?? {};
    const parsedInputSchema = parseJson(inputSchema ?? "{}");

    return parseProperties({
      ...completionSchemaProperties,
      ...(parsedInputSchema.properties
        ? {
            in: {
              type: "object",
              properties: parsedInputSchema.properties,
            },
          }
        : {
            in: {
              type: parsedInputSchema.type,
            },
          }),
      outputSchema: {
        type: "string",
        description: "The output JSON schema of this AI Completion.",
      },
    });
  }, [getAiCompletionSchemaQuery.data, inputSchema]);

  return (
    <Context.Provider
      value={{
        currentWorkflowSuggestionMap: suggestionMap,
        inputSchema,
        setInputSchema,
        getAiCompletionSchemaQuery,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useAiCompletionSchemaContext = () => {
  const context = useContext(Context);

  if (context == null) {
    throw new Error("out of context");
  }

  return context;
};

export { AiCompletionSchemaProvider, useAiCompletionSchemaContext };
