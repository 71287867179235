import { Text } from "@chakra-ui/react";
import { Editor } from "@monaco-editor/react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import JSONValidator from "ajv";

const jsonValidator = new JSONValidator();

const JSONSchemaEditor = <T extends FieldValues>({
  name,
  control,
  height = "30vh",
}: {
  name: Path<T>;
  control: Control<T>;
  height?: string;
}) => {
  return (
    <Controller
      rules={{
        validate: (value) => {
          try {
            const isValid = jsonValidator.compile(JSON.parse(value ?? ""));

            if (!isValid) {
              throw new Error();
            }

            return true;
          } catch (e) {
            return "Invalid JSON schema";
          }
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <Editor
            height={height}
            language="json"
            theme="vs-dark"
            value={value}
            onChange={(value) => onChange(value || "{}")}
            options={{
              lineNumbers: "off",
              minimap: { enabled: false },
              wordWrap: "on",
              tabSize: 2,
            }}
          />
          {error && <Text color="red.500">{error.message}</Text>}
        </>
      )}
      name={name}
      control={control}
    />
  );
};

export default JSONSchemaEditor;
