import React, { memo, useCallback, useEffect, useState } from "react";
import { NodeProps, useUpdateNodeInternals } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { Box, Button, Checkbox, FormControl, FormLabel, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import MustacheEditor from "../../editor/MustacheEditor";
import SchemaPassThroughNodeWithChildren, { SchemaData } from "./SchemaPassThroughNodeWithChildren";
import useNodeLookup from "../../../hooks/useNodeLookup";
import { useAiCompletionSchemaContext } from "../../../context/AiCompletionSchemaContext";
import { MdAdd } from "react-icons/md";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";

interface CustomConditionData extends SchemaData {
  conditions: string[];
}

const CustomConditionNode: React.FC<NodeProps<NodeType<CustomConditionData>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData, targetHandles = [], sourceHandles = [] },
  } = props;

  const conditions = nodeData?.conditions ?? ["true", "true"];

  const { handleSubmit, setValue, watch } = useForm<CustomConditionData>({
    defaultValues: {
      conditions,
    },
  });
  const { updateNodeData } = useUpdateNodeData(nodeId);

  const handleUpdate = useCallback((data: CustomConditionData) => {
    updateNodeData(data);
  }, []);

  const [hasElse, setHasElse] = useState(true);
  const { getTargetNodeOutputSchema } = useNodeLookup();
  const inputSchema = getTargetNodeOutputSchema(targetHandles.map(({ handleId }) => handleId));

  const { currentWorkflowSuggestionMap, setInputSchema } = useAiCompletionSchemaContext();

  useEffect(() => {
    updateNodeData({
      ...nodeData,
      inputSchema,
      outputSchema: inputSchema,
    });

    setInputSchema(inputSchema ?? "{}");
  }, [inputSchema]);

  const { updateNodeSourceHandles } = useUpdateNodeHandles(nodeId);
  const updateNodeInternals = useUpdateNodeInternals();

  const addFlowOutHandle = () => {
    const currentSourceHandles = sourceHandles.filter(({ handleName }) => handleName === "out");

    updateNodeSourceHandles([
      ...currentSourceHandles,
      {
        label: String(currentSourceHandles.length),
        handleName: "out",
        handleType: "source",
        handleCategory: "flow",
      },
    ]);

    updateNodeInternals(nodeId);
  };

  const removeFlowOutHandle = (index: number) => {
    const currentSourceHandles = sourceHandles.filter(({ handleName }) => handleName === "out");
    updateNodeSourceHandles(
      [...currentSourceHandles.slice(0, index), ...currentSourceHandles.slice(index + 1)].map((handle, index) => ({
        ...handle,
        label: String(index),
      }))
    );
    updateNodeInternals(nodeId);
  };

  const handleHasElseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (conditions.length < 3 && !e.target.checked) {
      return;
    }

    setHasElse(e.target.checked);

    if (e.target.checked) {
      setValue("conditions", [...conditions, "true"]);

      addFlowOutHandle();
    } else {
      setValue("conditions", conditions.slice(0, -1));

      removeFlowOutHandle(conditions.length - 1);
    }

    handleSubmit(handleUpdate)();
  };

  const handleAddCondition = () => {
    addFlowOutHandle();

    setValue("conditions", [...conditions, "true"]);

    handleSubmit(handleUpdate)();
  };

  const handleRemoveCondition = (index: number) => {
    removeFlowOutHandle(index);

    setValue("conditions", [...conditions.slice(0, index), ...conditions.slice(index + 1)]);

    handleSubmit(handleUpdate)();
  };

  return (
    <SchemaPassThroughNodeWithChildren {...props}>
      <FormControl
        className={"nodrag"}
        onSubmit={handleSubmit(handleUpdate)}
        onBlur={handleSubmit(handleUpdate)}
        onChange={handleSubmit(handleUpdate)}
      >
        <VStack spacing={2} alignItems={"flex-start"} w="25rem">
          <Button onClick={() => handleAddCondition()} alignSelf={"flex-end"} color={"green.300"}>
            <Icon as={MdAdd} />
            Add condition
          </Button>
          <HStack alignSelf={"flex-end"}>
            <Checkbox isChecked={hasElse} onChange={(e) => handleHasElseChange(e)}>
              Include else
            </Checkbox>
          </HStack>
          {(hasElse ? conditions.slice(0, -1) : conditions).map((condition, index) => (
            <Box key={index} w="100%">
              <FormLabel>
                <HStack>
                  <HStack>
                    <Text casing={"uppercase"}>{index === 0 ? "If" : "Else If"}</Text>
                    <Text casing={"uppercase"} color="green.300">
                      #{index}
                    </Text>
                  </HStack>

                  {index > 0 && (
                    <Button size={"xs"} onClick={() => handleRemoveCondition(index)} color={"red.300"}>
                      Remove
                    </Button>
                  )}
                </HStack>
              </FormLabel>
              <MustacheEditor
                suggestionMap={currentWorkflowSuggestionMap}
                onChange={(value) => {
                  setValue(`conditions.${index}`, value || "", {
                    shouldDirty: true,
                  });
                }}
                value={watch(`conditions.${index}`) ?? condition}
                hoverDataMap={{}}
                height="5vh"
              />
            </Box>
          ))}
          {hasElse && (
            <HStack pb={4}>
              <Text casing={"uppercase"}>Else</Text>
              <Text casing={"uppercase"} color="green.300">
                #{conditions.length - 1}
              </Text>
            </HStack>
          )}
        </VStack>
      </FormControl>
    </SchemaPassThroughNodeWithChildren>
  );
};

export default memo(CustomConditionNode);
