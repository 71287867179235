import { DragEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactFlow, {
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  ReactFlowInstance,
  SelectionMode,
  updateEdge,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import { edgeTypeDictionary } from "../components/reactFlow/edgeTypes";
import { nodeTypeDictionary } from "../components/reactFlow/nodeTypes";
import { Box, Center, Spinner } from "@chakra-ui/react";
import { ulid } from "ulid";
import StyledBackground from "../components/reactFlow/StyledBackground";
import StyledControls from "../components/reactFlow/StyledControls";
import StyledMiniMap from "../components/reactFlow/StyledMiniMap";
import { ControlToolbar } from "../components/reactFlow/ControlToolbar";
import { useNavigate, useParams } from "react-router-dom";
import { ConnectionLine } from "../components/reactFlow/ConnectionLine";
import { createHandleId, spreadHandleId } from "../utils/handleId";
import useInitialNodeTypes from "../hooks/useInitialNodeTypes";
import { NodeType } from "../models/nodeType";
import { useLoaderData } from "react-router";
import useNodeTypesDiff, { NodeDiff } from "../hooks/useNodeTypesDiff";
import useNodeHandlesValidation from "../hooks/useNodeHandlesValidation";
import { useCopyAndPasteShortcuts } from "../hooks/useCopyAndPasteShortcuts";
import { UserList } from "../components/UserList";
import { useYDoc } from "../hooks/useYDoc";
import { adjectives, animals, colors, uniqueNamesGenerator } from "unique-names-generator";
import { useRandomColor } from "../hooks/useRandomColor";
import useNodeTypeNameLookup from "../hooks/useNodeTypeNameLookup";
import { useConnectionProvider } from "../context/ConnectionContext";
import { useToken } from "@chakra-ui/system";
import { WorkflowWithId } from "../models/api/workflow";
import useWorkflows from "../api/useWorkflows";
import { WorkflowNode, WorkflowNodeProperty } from "@worldwidewebb/client-ai";
import { FlowNodeData } from "../components/reactFlow/nodeTypes/FlowNode";
import { ExtractValueNodeData } from "../components/reactFlow/nodeTypes/CreateExtractValueNode";

// TODO: REFACTOR IN PROGRESS

const WorkflowEditor = () => {
  const { workflow, displayName } = useLoaderData() as { workflow: WorkflowWithId; displayName: string };
  const [currentNodeTypes, setCurrentNodeTypes] = useState<NodeType[]>(workflow.dataDefinitions);
  const initialNodeTypes = useInitialNodeTypes();
  const [nodes, setNodes] = useState<Node<NodeType>[]>(workflow.data.nodes ?? []);
  const [edges, setEdges] = useState<Edge[]>(workflow.data.edges ?? []);
  const { updatedEdges, updatedNodes, updatedNodeDiffs } = useNodeTypesDiff(edges, nodes, initialNodeTypes);
  const hasDefinitionsUpgrade = updatedNodeDiffs.length !== 0;
  const [updatedNodeDiffsLog, setUpdatedNodeDiffsLog] = useState<NodeDiff[]>([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const { getWorkflow, setWorkflow, setWorkflowNodes } = useWorkflows();
  const workflowName = workflow.name;
  const workflowDescription = workflow.description;
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>();
  const [isReady, setIsReady] = useState<boolean>(workflow.isReady ?? false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onInit = useCallback((reactFlowInstance: ReactFlowInstance) => setReactFlowInstance(reactFlowInstance), []);

  const randomUsername = uniqueNamesGenerator({
    dictionaries: [adjectives, colors, animals], // colors can be omitted here as not used
    length: 3,
  });

  const randomColor = useRandomColor();

  const { activeUsers } = useYDoc(id, displayName || randomUsername, randomColor);

  const { onEdgeUpdateStart, onEdgeUpdateEnd } = useConnectionProvider();

  useCopyAndPasteShortcuts();
  const { isValidConnection } = useNodeHandlesValidation();

  const handleDefinitionsUpgrade = useCallback(() => {
    setUpdatedNodeDiffsLog(updatedNodeDiffs);

    // https://github.com/wbkd/react-flow/issues/3198
    setEdges([]);
    setNodes([]);
    setCurrentNodeTypes(initialNodeTypes);
    setTimeout(() => {
      setNodes(updatedNodes);
      setEdges(updatedEdges);
    }, 0);
  }, [updatedEdges, updatedNodes, updatedNodeDiffs, initialNodeTypes]);

  const handleLoad = useCallback(async () => {
    if (id == null) {
      return;
    }

    const workflow = await getWorkflow(id);

    // https://github.com/wbkd/react-flow/issues/3198
    setEdges([]);
    setNodes([]);
    setCurrentNodeTypes(workflow.dataDefinitions);
    setTimeout(() => {
      setNodes(workflow.data.nodes);
      setEdges(workflow.data.edges);
    }, 0);
  }, [getWorkflow, id]);

  const handleSave = useCallback(async () => {
    if (id == null) {
      return;
    }

    await setWorkflow({
      workflowId: id,
      name: workflowName,
      description: workflowDescription,
      data: {
        nodes,
        edges,
      },
      dataDefinitions: currentNodeTypes,
      isReady,
    });
  }, [nodes, edges, workflowName, currentNodeTypes, isReady]);

  const onNodesChange = useCallback((changes: NodeChange[]) => {
    setNodes((changedNodes) => applyNodeChanges(changes, changedNodes));
  }, []);

  const onEdgesChange = useCallback((changes: EdgeChange[]) => {
    setEdges((changedEdges) => applyEdgeChanges(changes, changedEdges));
  }, []);

  const onEdgeUpdate = useCallback((oldEdge: Edge, newConnection: Connection) => {
    setEdges((edges) => updateEdge(oldEdge, newConnection, edges));
  }, []);

  const onConnect = useCallback(
    ({ source, sourceHandle, target, targetHandle }: Connection) =>
      setEdges((currentEdges) => {
        if (!source || !target) return currentEdges;

        if (sourceHandle == null) {
          return currentEdges;
        }

        const { handleCategory } = spreadHandleId(sourceHandle);

        let type = "default";

        if (handleCategory === "start") {
          type = "StartEdge";
        }

        if (handleCategory === "end") {
          type = "EndEdge";
        }

        if (handleCategory === "data") {
          type = "DataEdge";
        }

        if (handleCategory === "flow") {
          type = "FlowEdge";
        }

        if (handleCategory === "element") {
          type = "ElementEdge";
        }

        return currentEdges.concat({
          id: ulid(),
          type,
          source,
          sourceHandle,
          target,
          targetHandle,
        });
      }),
    [setEdges]
  );

  const { project } = useReactFlow();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const onDragOver = (event: DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const { getLatestType } = useNodeTypeNameLookup();

  const onDrop = useCallback(
    (event: DragEvent) => {
      event.preventDefault();

      if (!wrapperRef.current) {
        return;
      }

      const wrapperBounds = wrapperRef.current.getBoundingClientRect();
      const nodeName = event.dataTransfer.getData("application/reactflow");
      const nodePosition = project({ x: event.clientX - wrapperBounds.x, y: event.clientY - wrapperBounds.top });

      const nodeType = initialNodeTypes.find((nodeType) => nodeType.nodeName === nodeName);

      if (nodeType == null) {
        return;
      }

      nodeType.targetHandles?.forEach((nodeHandle) => {
        nodeHandle.handleId = createHandleId(nodeHandle);
      });

      nodeType.sourceHandles?.forEach((nodeHandle) => {
        nodeHandle.handleId = createHandleId(nodeHandle);
      });

      const type = getLatestType(nodeType);

      const node: Node = {
        id: ulid(),
        type,
        position: nodePosition,
        data: structuredClone(nodeType),
      };

      setNodes((nodes) => nodes.concat(node));
    },
    [project, initialNodeTypes, getLatestType]
  );

  const handleUpdateWorkflowName = useCallback(
    async (workflowName: string) => {
      if (workflow == null) {
        return;
      }

      await setWorkflow({
        ...workflow,
        name: workflowName,
      });

      navigate(".", { replace: true });
    },
    [workflow]
  );

  const exportRuntimeData = useCallback((): WorkflowNode[] => {
    const nodeIdToNode = new Map<string, Node<NodeType>>(nodes.map((node) => [node.id, node]));

    return nodes.map(
      ({
        id: nodeId,
        data: { nodeName: nodeType, nodeClass, nodeData = {}, sourceHandles = [], targetHandles = [] },
      }) => {
        try {
          const nodeInputs: WorkflowNodeProperty[] = targetHandles.map(({ handleId, handleName }) => {
            if (handleId == null) {
              throw new Error(`${nodeId} (${nodeType}): handleId is undefined, this should be impossible`);
            }

            const sourceNodeEdges = edges.filter(({ targetHandle }) => targetHandle === handleId);

            if (sourceNodeEdges.length > 1) {
              throw new Error(`${nodeId} (${nodeType}): multiple connections have been made to ${handleName}`);
            }

            if (sourceNodeEdges.length === 0) {
              throw new Error(`${nodeId} (${nodeType}): found no connection to ${handleName}`);
            }

            const [{ source: sourceNodeId }] = sourceNodeEdges;

            const sourceNodeType = nodes.find(({ id }) => id === sourceNodeId)?.data.nodeName;

            if (sourceNodeType == null) {
              throw new Error(`${nodeId} (${nodeType}): found no associated node type for ${handleName}`);
            }

            return {
              nodeId: sourceNodeId,
              nodeType: sourceNodeType,
              propertyName: handleName,
            };
          });

          const nodeOutputs: WorkflowNodeProperty[] = sourceHandles.flatMap(
            ({ handleId, handleCategory, handleName }) => {
              if (handleId == null) {
                throw new Error(`${nodeId} (${nodeType}): handleId is undefined, this should be impossible`);
              }

              const targetNodeEdges = edges.filter(({ sourceHandle }) => sourceHandle === handleId);

              if (
                targetNodeEdges.length > 1 &&
                !["data", "start", "flow"].includes(handleCategory) &&
                !["data", "start", "flow"].includes(nodeType)
              ) {
                throw new Error(`${nodeId} (${nodeType}): multiple connections have been made from ${handleName}`);
              }

              if (targetNodeEdges.length === 0 && nodeType !== "data") {
                throw new Error(`${nodeId} (${nodeType}): found no connection for ${handleName}`);
              }

              return targetNodeEdges.map(({ target: targetNodeId }) => {
                const targetNode = nodeIdToNode.get(targetNodeId);
                const targetNodeType = targetNode?.data.nodeName;

                if (targetNodeType == null) {
                  throw new Error(`${nodeId} (${nodeType}): found no associated node type for ${handleName}`);
                }

                return {
                  nodeId: targetNodeId,
                  nodeType: targetNodeType,
                  propertyName: handleName,
                };
              });
            }
          );

          return {
            workflowId: id,
            nodeId,
            nodeType,
            // eslint-disable-next-line
            nodeClass: nodeClass as any,
            nodeData,
            nodeInputs,
            nodeOutputs,
          };
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    );
  }, [edges, nodes, id]);

  const handleExportToFile = useCallback(() => {
    const exportJSON = JSON.stringify(exportRuntimeData(), null, 2);

    const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(exportJSON)}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `${workflowName}.json`;

    link.click();
    link.remove();
  }, [exportRuntimeData]);

  const handleExportToClipboard = useCallback(() => {
    const exportJSON = JSON.stringify(exportRuntimeData(), null, 2);

    navigator.clipboard.writeText(exportJSON).catch((error) => console.error(error));
  }, [exportRuntimeData]);

  const handleUploadWorkflowNodes = useCallback(async () => {
    if (id == null) {
      return;
    }

    await setWorkflowNodes(id, exportRuntimeData());
  }, [exportRuntimeData, setWorkflowNodes, id]);

  const handleClickNodeInSelectionRectangle = useCallback(
    (event: Event) => {
      const { target, clientX, clientY } = event as unknown as MouseEvent;

      if (!(target instanceof HTMLElement)) {
        return;
      }

      // noinspection SpellCheckingInspection
      if (!target.closest(".react-flow__nodesselection")) {
        return;
      }

      const { left: wrapperX = 0, top: wrapperY = 0 } = wrapperRef.current?.getBoundingClientRect() ?? {};
      const { x: clickX, y: clickY } = project({ x: clientX - wrapperX, y: clientY - wrapperY });

      const selectedNodes = reactFlowInstance?.getNodes().filter(({ selected }) => selected) ?? [];
      const [clickedNode] = selectedNodes.filter(
        ({ position: { x, y }, width, height }) =>
          x <= clickX && clickX < x + (width || 0) && y <= clickY && clickY < y + (height || 0)
      );

      if (clickedNode == null) {
        return;
      }

      reactFlowInstance?.setNodes((nodes) =>
        nodes.map((node) => ({
          ...node,
          selected: clickedNode.id === node.id,
        }))
      );

      reactFlowInstance?.setEdges((edges) =>
        edges.map((edge) => ({
          ...edge,
          selected: false,
        }))
      );

      target.remove();
    },
    [reactFlowInstance, wrapperRef]
  );

  useEffect(() => {
    window.addEventListener("click", handleClickNodeInSelectionRectangle);

    return () => {
      window.removeEventListener("click", handleClickNodeInSelectionRectangle);
    };
  }, [handleClickNodeInSelectionRectangle]);

  const handleClickNodeInSelection = useCallback(
    ({ ctrlKey, metaKey }: MouseEvent, { id: nodeId }: Node) => {
      // corresponds to multiSelectionKeyCode
      if (ctrlKey || metaKey) {
        return;
      }

      const selectedNodes = reactFlowInstance?.getNodes().filter(({ selected }) => selected) ?? [];

      if (selectedNodes.length <= 1) {
        return;
      }

      reactFlowInstance?.setNodes((nodes) =>
        nodes.map((node) => ({
          ...node,
          selected: node.id === nodeId,
        }))
      );

      reactFlowInstance?.setEdges((edges) =>
        edges.map((edge) => ({
          ...edge,
          selected: false,
        }))
      );
    },
    [reactFlowInstance]
  );

  useEffect(() => {
    if (nodes.length !== 0) {
      return;
    }

    const initialStartNodeType = initialNodeTypes.find((nodeType) => nodeType.nodeName === "start");

    initialStartNodeType?.targetHandles?.forEach((nodeHandle) => {
      nodeHandle.handleId = createHandleId(nodeHandle);
    });

    initialStartNodeType?.sourceHandles?.forEach((nodeHandle) => {
      nodeHandle.handleId = createHandleId(nodeHandle);
    });

    const initialStartNode = {
      id: ulid(),
      type: "StartNode",
      position: { x: 0, y: 0 },
      data: structuredClone(initialStartNodeType),
    };

    const initialEndNodeType = initialNodeTypes.find((nodeType) => nodeType.nodeName === "end");

    initialEndNodeType?.targetHandles?.forEach((nodeHandle) => {
      nodeHandle.handleId = createHandleId(nodeHandle);
    });

    initialEndNodeType?.sourceHandles?.forEach((nodeHandle) => {
      nodeHandle.handleId = createHandleId(nodeHandle);
    });

    const initialEndNode = {
      id: ulid(),
      type: "EndNode",
      position: { x: 1000, y: 0 },
      data: structuredClone(initialEndNodeType),
    };

    setNodes([initialStartNode, initialEndNode]);
  }, []);

  useEffect(() => {
    if (!reactFlowInstance) {
      return;
    }

    setTimeout(() => {
      reactFlowInstance.fitView();

      setIsLoading(false);
    }, 100);
  }, [reactFlowInstance]);

  useEffect(() => {
    if (!workflowName) {
      return;
    }

    document.title = `Workflow | ${workflowName}`;

    return () => {
      document.title = "Workflow Editor";
    };
  }, [workflowName]);

  const nodeColors = useToken(
    "colors",
    initialNodeTypes.map(({ color }) => color ?? "white")
  );

  const nodeColorDictionary = useMemo(
    () => Object.fromEntries(initialNodeTypes.map(({ color }, index) => [color ?? "white", nodeColors[index]])),
    [initialNodeTypes, nodeColors]
  );

  const getNodeColor = useCallback(
    (colorToken?: string) => {
      if (colorToken == null) {
        return "#FFF";
      }

      return nodeColorDictionary[colorToken];
    },
    [nodeColorDictionary]
  );

  return (
    <>
      <Box ref={wrapperRef}>
        {isLoading && (
          <Center bg={"mirage.900"} position={"fixed"} top={0} bottom={0} left={0} right={0} zIndex={1000}>
            <Spinner size={"xl"} color={"white"} />
          </Center>
        )}

        <ReactFlow
          onInit={onInit}
          nodeTypes={nodeTypeDictionary}
          edgeTypes={edgeTypeDictionary}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgeUpdate={onEdgeUpdate}
          connectionLineComponent={ConnectionLine}
          onConnect={onConnect}
          onEdgeUpdateStart={(_, edge) => onEdgeUpdateStart(edge)}
          onEdgeUpdateEnd={onEdgeUpdateEnd}
          isValidConnection={isValidConnection}
          proOptions={{ hideAttribution: true }}
          multiSelectionKeyCode={["Meta", "Control"]}
          deleteKeyCode={["Backspace", "Delete"]}
          selectionKeyCode={null}
          selectionMode={SelectionMode.Partial}
          onDragOver={onDragOver}
          onDrop={onDrop}
          panOnDrag={[1]}
          panActivationKeyCode={"Shift"}
          selectionOnDrag={true}
          onNodeClick={handleClickNodeInSelection}
          minZoom={0.125}
          maxZoom={1}
          onlyRenderVisibleElements={true}
        >
          <StyledBackground />
          <StyledControls position="top-right" showInteractive={false} />
          <StyledMiniMap
            position="bottom-right"
            pannable={true}
            zoomable={true}
            nodeColor={(node: Node<NodeType>) => getNodeColor(node.data.color)}
          />
        </ReactFlow>
      </Box>

      <ControlToolbar
        title={workflowName}
        onUpdateTitle={handleUpdateWorkflowName}
        onLoad={handleLoad}
        onSave={handleSave}
        onExportToFile={handleExportToFile}
        onExportToClipboard={handleExportToClipboard}
        onUploadToServer={handleUploadWorkflowNodes}
        hasDefinitionsUpgrade={hasDefinitionsUpgrade}
        onDefinitionsUpgrade={handleDefinitionsUpgrade}
        nodeDiffs={updatedNodeDiffsLog}
        isReady={isReady}
        toggleIsReady={() => setIsReady((isReady) => !isReady)}
      />

      <UserList users={activeUsers} />
    </>
  );
};

export default WorkflowEditor;
