import React, { memo, PropsWithChildren, useCallback, useEffect } from "react";
import { NodeProps, useReactFlow, useUpdateNodeInternals } from "reactflow";
import { NodeType, SourceHandle, TargetHandle } from "../../../models/nodeType";
import { BaseNodeWithChildren } from "./base/BaseNode";
import { useForm } from "react-hook-form";
import JSONSchemaValidator from "ajv";
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import SchemaPassThroughNodeWithChildren, { SchemaData } from "./SchemaPassThroughNodeWithChildren";
import useNodeLookup from "../../../hooks/useNodeLookup";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";

export interface FlowNodeData extends SchemaData {
  dataSourceEnabled: boolean;
  flowTargetCount: number;
}

interface FlowNodeConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  nodeId: string;
  nodeData: FlowNodeData;
  sourceHandles: SourceHandle[];
  targetHandles: TargetHandle[];
  color?: string;
  inputSchema: string;
}

const jsonSchemaValidator = new JSONSchemaValidator();

export const FlowNodeConfigurationModal: React.FC<FlowNodeConfigurationModalProps> = ({
  isOpen,
  onClose,
  nodeId,
  nodeData,
  sourceHandles,
  targetHandles,
  color,
  inputSchema,
}) => {
  const dataSourceEnabled = nodeData?.dataSourceEnabled ?? false;
  const flowTargetCount = nodeData?.flowTargetCount ?? 1;
  const outputSchema = nodeData?.outputSchema ?? "{}";

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FlowNodeData>({
    defaultValues: {
      dataSourceEnabled,
      flowTargetCount,
      outputSchema,
    },
    mode: "onBlur",
  });

  const reactFlow = useReactFlow();
  const { updateNodeTargetHandles, updateNodeSourceHandles } = useUpdateNodeHandles(nodeId);
  const updateNodeInternals = useUpdateNodeInternals();

  const handleUpdate = useCallback(
    ({ dataSourceEnabled, flowTargetCount, inputSchema, outputSchema }: FlowNodeData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FlowNodeData) ?? {};
        nodeData.dataSourceEnabled = dataSourceEnabled;
        nodeData.flowTargetCount = flowTargetCount;
        nodeData.inputSchema = inputSchema;
        nodeData.outputSchema = outputSchema;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });

      onClose();
    },
    [onClose, reactFlow, nodeId]
  );

  useEffect(() => {
    reset({
      dataSourceEnabled: dataSourceEnabled,
      flowTargetCount,
      outputSchema,
    });
  }, [dataSourceEnabled, flowTargetCount, outputSchema]);

  const handleCancel = useCallback(() => {
    reset({
      dataSourceEnabled: dataSourceEnabled,
      flowTargetCount,
      outputSchema,
    });

    onClose();
  }, [onClose, reset, dataSourceEnabled, flowTargetCount, outputSchema]);

  useEffect(() => {
    const currentFlowTargetCount = targetHandles.filter(({ handleName }) => handleName === "in").length;

    if (currentFlowTargetCount === flowTargetCount) {
      return;
    }

    const targetHandlesExcludingIn = targetHandles.filter(({ handleName }) => handleName !== "in");

    const targetHandlesIncludingIn = targetHandles.filter(({ handleName }) => handleName === "in");

    if (currentFlowTargetCount < flowTargetCount) {
      const handlesToInsert = flowTargetCount - currentFlowTargetCount;

      targetHandlesIncludingIn.push(
        ...[...Array(handlesToInsert)].map(() => {
          const targetHandle: TargetHandle = {
            label: "IN",
            handleName: "in",
            handleType: "target",
            handleCategory: "flow",
          };

          return targetHandle;
        })
      );
    } else {
      const handlesToRemove = currentFlowTargetCount - flowTargetCount;

      targetHandlesIncludingIn.splice(-handlesToRemove);
    }

    updateNodeTargetHandles([...targetHandlesIncludingIn, ...targetHandlesExcludingIn]);
  }, [flowTargetCount]);

  useEffect(() => {
    if (dataSourceEnabled) {
      if (sourceHandles.find(({ handleName }) => handleName === "data") == null) {
        updateNodeSourceHandles([
          ...sourceHandles,
          {
            label: "DATA",
            handleName: "data",
            handleType: "source",
            handleCategory: "data",
          },
        ]);
      }
    } else {
      updateNodeSourceHandles(sourceHandles.filter(({ handleName }) => handleName !== "data"));
    }

    updateNodeInternals(nodeId);
  }, [dataSourceEnabled]);

  const isSchemaValid = (schema?: string): boolean => {
    try {
      return !!jsonSchemaValidator.validateSchema(JSON.parse(schema ?? "{}"));
    } catch (e) {
      return false;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={"theme.dark.background"} borderColor={color} borderRadius={0} borderWidth={1}>
        <form onSubmit={handleSubmit(handleUpdate)}>
          <ModalHeader>
            <Text color={color}>Configuration</Text>
          </ModalHeader>

          <ModalBody>
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  IN Port Count
                </Text>
              </FormLabel>
              <NumberInput defaultValue={1} step={1} min={1} max={5}>
                <NumberInputField
                  id={"flowTargetCount"}
                  {...register("flowTargetCount", { valueAsNumber: true, min: 1, max: 5 })}
                  color={color}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Output Data Port Enabled
                </Text>
              </FormLabel>
              <Checkbox {...register("dataSourceEnabled")} color={color} />
            </FormControl>
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Input JSON Schema
                </Text>
              </FormLabel>
              <Textarea color={color} rows={10} value={inputSchema} readOnly />
              <Text color={"red.500"}>{errors.inputSchema && "Invalid JSON Schema"}</Text>
            </FormControl>
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Output JSON Schema
                </Text>
              </FormLabel>
              <Textarea
                {...register("outputSchema", {
                  validate: isSchemaValid,
                })}
                color={color}
                rows={10}
                borderColor={errors.outputSchema ? "red.500" : color}
                focusBorderColor={errors.outputSchema ? "red.500" : color}
              />
              <Text color={"red.500"}>{errors.outputSchema && "Invalid JSON Schema"}</Text>
            </FormControl>
          </ModalBody>

          <ModalFooter gap={1}>
            <Button onClick={handleCancel} color={"white"} variant={"outline"}>
              Cancel
            </Button>
            <Button color={color} type={"submit"} variant={"outline"}>
              Update
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const FlowNode: React.FC<NodeProps<NodeType>> = (props) => {
  return <SchemaPassThroughNodeWithChildren {...props} />;
};

export const OutputFlowNodeWithChildren: React.FC<NodeProps<NodeType> & PropsWithChildren> = ({
  children,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    id: nodeId,
    data: { color, nodeData = {}, sourceHandles = [], targetHandles = [] },
  } = props;

  const { updateNodeData } = useUpdateNodeData(nodeId);
  const { getTargetNodeOutputSchema } = useNodeLookup();

  const inputSchema = getTargetNodeOutputSchema(targetHandles.map(({ handleId }) => handleId));

  useEffect(() => {
    updateNodeData({
      ...nodeData,
      inputSchema,
    });
  }, [inputSchema]);

  return (
    <BaseNodeWithChildren {...props}>
      <Stack p={2}>
        {children && children}

        <Button onClick={onOpen} color={color} textTransform={"uppercase"} w={"100%"} variant={"outline"}>
          Configure Node
        </Button>

        <FlowNodeConfigurationModal
          isOpen={isOpen}
          onClose={onClose}
          nodeId={nodeId}
          nodeData={nodeData as FlowNodeData}
          sourceHandles={sourceHandles}
          targetHandles={targetHandles}
          color={color}
          inputSchema={inputSchema ?? "{}"}
        />
      </Stack>
    </BaseNodeWithChildren>
  );
};

export default memo(FlowNode);
