import React, { memo, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  ListItem,
  ListItemProps,
  Tag,
  TagLeftIcon,
  Text,
  Tooltip,
  useDisclosure
} from "@chakra-ui/react";
import { MdCheckCircle, MdCopyAll, MdDelete, MdEdit, MdOpenInFull, MdRemoveCircle } from "react-icons/md";
import UpdateWorkflowModal from "./modals/UpdateWorkflowModal";
import DeleteWorkflowModal from "./modals/DeleteWorkflowModal";
import { WorkflowWithId } from "../models/api/workflow";

interface WorkflowListItemProps extends ListItemProps {
  index: number;
  workflow: WorkflowWithId;
  onUpdate: (workflow: WorkflowWithId) => void;
  isUpdating: boolean;
  onDelete: (workflowId: string) => void;
  isDeleting: boolean;
  onDuplicate: (workflowId: string) => void;
  isDuplicating: boolean;
  openIndexes: number[];
  onToggleOpenIndex: (index: number) => void;
}

const WorkflowListItem: React.FC<WorkflowListItemProps> = ({
  index,
  workflow,
  onUpdate,
  isUpdating,
  onDelete,
  isDeleting,
  onDuplicate,
  isDuplicating,
  openIndexes,
  onToggleOpenIndex,
  ...listItemProps
}) => {
  const { workflowId, name: workflowName, description: workflowDescription, isReady: isWorkflowReady } = workflow;

  const { isOpen: isOpenUpdate, onOpen: onOpenUpdate, onClose: onCloseUpdate } = useDisclosure();
  const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();

  const handleOpenUpdateWorkflowModal = useCallback(() => {
    onOpenUpdate();
  }, [onOpenUpdate]);

  const handleOpenDeleteWorkflowModal = useCallback(() => {
    onOpenDelete();
  }, [onOpenDelete]);

  const handleUpdateWorkflow = useCallback(
    (workflow: WorkflowWithId) => {
      onUpdate(workflow);

      onCloseUpdate();
    },
    [onUpdate, onCloseUpdate]
  );

  const handleDeleteWorkflow = useCallback(
    (workflowId: string) => {
      onDelete(workflowId);

      onCloseDelete();
    },
    [onDelete, onCloseDelete]
  );

  return (
    <>
      <ListItem {...listItemProps}>
        <Card bg={"gray.800"} borderRadius={0}>
          <CardHeader bg={"indigo.600"} as={RouterLink} to={workflowId}>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Heading size={"md"}>
                <Text color={"white"} isTruncated>
                  {workflowName}
                </Text>
              </Heading>

              <Icon as={MdOpenInFull} color={"white"} />
            </Flex>
          </CardHeader>
          <CardBody>
            <HStack justifyContent={"space-between"}>
              <Text color={"white"}>{workflowDescription || "No description given"}</Text>

              <HStack>
                <Tooltip label={isWorkflowReady ? "ready" : "not ready"}>
                  <Tag colorScheme={"blackAlpha"}>
                    <TagLeftIcon
                      as={isWorkflowReady ? MdCheckCircle : MdRemoveCircle}
                      color={isWorkflowReady ? "green.600" : "red.800"}
                    />
                  </Tag>
                </Tooltip>

                <Tooltip label={"delete workflow"}>
                  <IconButton
                    borderColor={"red.800"}
                    color={"red.800"}
                    variant={"outline"}
                    icon={<Icon as={MdDelete} />}
                    aria-label={"delete workflow"}
                    disabled={isDeleting}
                    onClick={handleOpenDeleteWorkflowModal}
                  />
                </Tooltip>
                <Tooltip label={"duplicate workflow"}>
                  <IconButton
                    color={"white"}
                    variant={"outline"}
                    icon={<Icon as={MdCopyAll} />}
                    aria-label={"duplicate workflow"}
                    disabled={isDuplicating}
                    onClick={() => onDuplicate(workflowId)}
                  />
                </Tooltip>
                <Tooltip label={"update workflow"}>
                  <IconButton
                    color={"white"}
                    variant={"outline"}
                    icon={<Icon as={MdEdit} />}
                    aria-label={"update workflow"}
                    disabled={isUpdating}
                    onClick={handleOpenUpdateWorkflowModal}
                  />
                </Tooltip>
              </HStack>
            </HStack>
          </CardBody>
        </Card>
      </ListItem>

      <UpdateWorkflowModal isOpen={isOpenUpdate} onClose={onCloseUpdate} workflow={workflow} onUpdate={handleUpdateWorkflow} />
      <DeleteWorkflowModal isOpen={isOpenDelete} onClose={onCloseDelete} workflow={workflow} onDelete={handleDeleteWorkflow} />
    </>
  );
};

export default memo(WorkflowListItem);
