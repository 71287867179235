import { EdgeProps, getBezierPath } from "reactflow";
import { useToken } from "@chakra-ui/system";

export const EndEdge = (edgeProps: EdgeProps) => {
  const [edgePath] = getBezierPath(edgeProps);
  const [color] = useToken("colors", ["red.800"]);
  const { id, style, markerStart, markerEnd, selected: isSelected } = edgeProps;

  return (
    <g>
      <path
        style={{
          ...style,
          filter: isSelected ? "drop-shadow(0px 0px 6px white) brightness(200%)" : undefined,
          animation: "dashdraw 1s linear infinite",
          strokeDasharray: "5",
        }}
        d={edgePath}
        fill={"none"}
        stroke={isSelected ? "white" : color}
        strokeWidth={3}
        markerStart={markerStart}
        markerEnd={markerEnd}
      />
      <path
        id={id}
        style={{
          ...style,
        }}
        d={edgePath}
        fill={"none"}
        stroke={"transparent"}
        strokeWidth={20}
      />
    </g>
  );
};
