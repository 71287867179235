import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Card,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdCheckCircle, MdList, MdPlayArrow, MdPlaylistPlay, MdRemoveCircle, MdRestore, MdSave } from "react-icons/md";
import { BsFillArrowUpSquareFill } from "react-icons/bs";
import { AiOutlineCopy, AiOutlineDownload } from "react-icons/ai";
import { TextEdit } from "../base/TextEdit";
import { NodeDiff } from "../../hooks/useNodeTypesDiff";
import { NodeDiffModal } from "./NodeDiffModal";
import { useLoaderData, useNavigate } from "react-router-dom";
import { AiNpcWorkflowContext } from "@worldwidewebb/client-ai";
import { useAiWorkflowRunsContext } from "../../context/AiWorkflowRunsContext";
import { CustomRunModal } from "./CustomRunModal";
import { CollapsibleError } from "./CollapsibleError";
import { WorkflowWithId } from "../../models/api/workflow";

interface ControlToolbarProps {
  title: string;
  onUpdateTitle: (title: string) => Promise<void>;
  onLoad: () => Promise<void>;
  onSave: () => Promise<void>;
  onExportToFile: () => void;
  onExportToClipboard: () => void;
  onUploadToServer: () => Promise<void>;
  hasDefinitionsUpgrade: boolean;
  onDefinitionsUpgrade: () => void;
  nodeDiffs: NodeDiff[];
  isReady: boolean;
  toggleIsReady: () => void;
}

export const ControlToolbar: React.FC<ControlToolbarProps> = ({
  title,
  onUpdateTitle,
  onLoad,
  onSave,
  onExportToFile,
  onExportToClipboard,
  onUploadToServer,
  hasDefinitionsUpgrade,
  onDefinitionsUpgrade,
  nodeDiffs,
  isReady,
  toggleIsReady,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen: isDiffOpen, onOpen: onDiffOpen, onClose: onDiffClose } = useDisclosure();
  const { isOpen: isCustomRunOpen, onOpen: onCustomRunOpen, onClose: onCustomRunClose } = useDisclosure();
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const [error, setError] = useState<{ header: string; message: string; info: string }>();
  const { executeWorkflowMutation, lastWorkflowRun } = useAiWorkflowRunsContext();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { workflow } = useLoaderData() as { workflow: WorkflowWithId };

  const handleReRun = useCallback(() => {
    if (!lastWorkflowRun) {
      return;
    }

    const context = (
      lastWorkflowRun.context.workflowName === workflow.name
        ? lastWorkflowRun.context
        : {
            ...lastWorkflowRun.context,
            workflowName: workflow.name,
          }
    ) as AiNpcWorkflowContext;

    executeWorkflowMutation.mutate({
      runId: lastWorkflowRun.workflowRunId,
      context,
    });
  }, [lastWorkflowRun]);

  const handleAlertClose = useCallback(() => {
    setError(undefined);
    onAlertClose();
  }, []);

  useEffect(() => {
    if (executeWorkflowMutation.isError) {
      const message =
        executeWorkflowMutation.error.response?.status === 404
          ? "Could not find the workflow, make sure it's active and saved"
          : executeWorkflowMutation.error.message;

      setError({
        header: "Custom workflow run failed",
        message,
        info: JSON.stringify(executeWorkflowMutation.error.response?.data || {}, null, 2),
      });
      onAlertOpen();
    }
  }, [executeWorkflowMutation.isError]);

  const handleCustomRun = useCallback(() => onCustomRunOpen(), []);

  const handleUpdateTitle = useCallback(
    async (title: string) => {
      try {
        await onUpdateTitle(title);

        toast({
          title: "Updating of quest successful",
          status: "success",
        });
      } catch (error) {
        toast({
          title: "Updating of quest unsuccessful",
          description: "See developer console for details",
          status: "error",
        });
        console.error(error);
      }
    },
    [toast, onUpdateTitle]
  );

  const handleDefinitionsUpgrade = useCallback(() => {
    try {
      onDefinitionsUpgrade();

      toast({
        title: "Updating of definitions successful",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Updating of definitions unsuccessful",
        description: "See developer console for details",
        status: "error",
      });
      console.error(error);
    }
  }, [toast, onDefinitionsUpgrade]);

  const handleLoad = useCallback(async () => {
    try {
      await onLoad();

      toast({
        title: "Loading of quest data successful",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Loading of quest data unsuccessful",
        description: (error as Error).message,
        status: "error",
      });
      console.error(error);
    }
  }, [toast, onLoad]);

  const handleSave = useCallback(async () => {
    try {
      await onSave();

      toast({
        title: "Saving of quest data successful",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Saving of quest data unsuccessful",
        description: (error as Error).message,
        status: "error",
      });
      console.error(error);
    }

    try {
      await onUploadToServer();

      toast({
        title: "Saving of quest nodes successful",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Saving of quest nodes unsuccessful",
        description: (error as Error).message,
        status: "error",
      });
      console.error(error);
    }

    navigate(".", { replace: true });
  }, [toast, navigate, onSave, onUploadToServer]);

  const handleExportToFile = useCallback(() => {
    try {
      onExportToFile();

      toast({
        title: "Export of quest data successful",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Export of quest data unsuccessful",
        description: (error as Error).message,
        status: "error",
      });
      console.error(error);
    }
  }, [toast, onExportToFile]);

  const handleExportToClipboard = useCallback(() => {
    try {
      onExportToClipboard();

      toast({
        title: "Export of quest data successful",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Export of quest data unsuccessful",
        description: (error as Error).message,
        status: "error",
      });
      console.error(error);
    }
  }, [toast, onExportToClipboard]);

  return (
    <Card p={1} position={"fixed"} right={20} top={4} bg={"theme.dark.background"}>
      <Flex gap={1} align={"center"}>
        <TextEdit value={title} onUpdate={handleUpdateTitle} />
        <Divider orientation={"vertical"} h={8} />

        <Tooltip label={lastWorkflowRun ? "Re-run last" : "No last run yet"}>
          <IconButton
            color={"white"}
            icon={
              executeWorkflowMutation.isPending ? <Spinner size={"sm"} color={"white"} /> : <Icon as={MdPlayArrow} />
            }
            aria-label={"re-run"}
            onClick={handleReRun}
            disabled={!lastWorkflowRun || executeWorkflowMutation.isPending || !isReady}
          />
        </Tooltip>
        <Tooltip label={isReady ? "Run custom" : "Workflow not ready to run"}>
          <IconButton
            color={"white"}
            icon={
              executeWorkflowMutation.isPending ? <Spinner size={"sm"} color={"white"} /> : <Icon as={MdPlaylistPlay} />
            }
            aria-label={"run"}
            onClick={handleCustomRun}
            disabled={executeWorkflowMutation.isPending || !isReady}
          />
        </Tooltip>
        <Divider orientation={"vertical"} h={8} />
        {nodeDiffs.length !== 0 && (
          <>
            <Tooltip label={"upgrade history"}>
              <IconButton
                color={"orange.600"}
                icon={<Icon as={MdList} />}
                aria-label={"upgrade history"}
                onClick={() => onDiffOpen()}
              />
            </Tooltip>

            <Divider orientation={"vertical"} h={8} />
          </>
        )}
        {hasDefinitionsUpgrade && (
          <>
            <Tooltip label={"upgrade definitions"}>
              <IconButton
                color={"orange.600"}
                icon={<Icon as={BsFillArrowUpSquareFill} />}
                aria-label={"upgrade definitions"}
                onClick={handleDefinitionsUpgrade}
              />
            </Tooltip>

            <Divider orientation={"vertical"} h={8} />
          </>
        )}
        <Tooltip label={"load"}>
          <IconButton color={"white"} icon={<Icon as={MdRestore} />} aria-label={"load"} onClick={handleLoad} />
        </Tooltip>
        <Tooltip label={"save"}>
          <IconButton color={"white"} icon={<Icon as={MdSave} />} aria-label={"save"} onClick={handleSave} />
        </Tooltip>
        <Divider orientation={"vertical"} h={8} />
        <Tooltip label={"export to file"}>
          <IconButton
            color={"white"}
            icon={<Icon as={AiOutlineDownload} />}
            aria-label={"export to file"}
            onClick={handleExportToFile}
          />
        </Tooltip>
        <Tooltip label={"export to clipboard"}>
          <IconButton
            color={"white"}
            icon={<Icon as={AiOutlineCopy} />}
            aria-label={"export to clipboard"}
            onClick={handleExportToClipboard}
          />
        </Tooltip>
        <Divider orientation={"vertical"} h={8} />
        <Tooltip label={isReady ? "mark as not ready" : "mark as ready"}>
          <Button aria-label={"readiness toggle"} onClick={toggleIsReady}>
            <Flex alignItems={"center"} gap={2}>
              {isReady ? (
                <Icon as={MdCheckCircle} color={"green.600"} />
              ) : (
                <Icon as={MdRemoveCircle} color={"red.800"} />
              )}
              <Text color={isReady ? "green.600" : "red.800"} textTransform={"uppercase"}>
                {isReady ? "Ready" : "Not Ready"}
              </Text>
            </Flex>
          </Button>
        </Tooltip>
      </Flex>

      <NodeDiffModal isOpen={isDiffOpen} onClose={onDiffClose} nodeDiffs={nodeDiffs} />
      <CustomRunModal isOpen={isCustomRunOpen} onClose={onCustomRunClose} />
      <AlertDialog isOpen={isAlertOpen} leastDestructiveRef={cancelRef} onClose={handleAlertClose}>
        <AlertDialogOverlay>
          <AlertDialogContent bg={"gray.800"} borderWidth={2} borderColor={"red.500"} minW="md" maxW="container.md">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <HStack>
                <Text color="white">{error?.header}</Text>
              </HStack>
            </AlertDialogHeader>

            <AlertDialogBody>
              <CollapsibleError
                title="Error running the workflow with custom data:"
                message={error?.message || "unknown error"}
                info={error?.info || ""}
              />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={handleAlertClose}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Card>
  );
};
