import React, { createContext, PropsWithChildren, useCallback, useContext } from "react";
import { Edge, useReactFlow } from "reactflow";

interface ContextType {
  onEdgeUpdateStart: (edge: Edge) => void;
  onEdgeUpdateEnd: () => void;
}

const Context = createContext<ContextType | null>(null);

const ConnectionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { setEdges } = useReactFlow();

  const onEdgeUpdateStart = useCallback(({ id }: Edge) => {
    setEdges((edges) =>
      edges.map((edge) => {
        if (edge.data == null) {
          edge.data = {};
        }

        if (edge.id !== id) {
          return edge;
        }

        edge.data.isDragging = true;

        return edge;
      })
    );
  }, []);

  const onEdgeUpdateEnd = useCallback(() => {
    setTimeout(() => {
      setEdges((edges) =>
        edges.map((edge) => {
          if (edge.data == null) {
            edge.data = {};
          }

          edge.data.isDragging = false;

          return edge;
        })
      );
    }, 100);
  }, []);

  return (
    <Context.Provider
      value={{
        onEdgeUpdateStart,
        onEdgeUpdateEnd,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useConnectionProvider = () => {
  const context = useContext(Context);

  if (context == null) {
    throw new Error("out of context");
  }

  return context;
};

export { ConnectionProvider, useConnectionProvider };
