import { Box, Checkbox, FormControl, FormLabel, HStack, NumberInput, NumberInputField, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Select } from "chakra-react-select";
import React, { memo, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { NodeProps, useUpdateNodeInternals } from "reactflow";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { NodeType } from "../../../models/nodeType";
import { npcsApi } from "../../../api";
import SchemaPassThroughNodeWithChildren from "./SchemaPassThroughNodeWithChildren";
import MustacheEditor from "../../editor/MustacheEditor";
import { useAiCompletionSchemaContext } from "../../../context/AiCompletionSchemaContext";
import useNodeLookup from "../../../hooks/useNodeLookup";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import JSONSchemaEditor from "../JSONSchemaEditor";

interface FetchDataMetadata {
  npcId: string;
  jsonTemplate: string;
  dataSourceEnabled: boolean;
  outputSchema: string;
}

const FetchDataNode: React.FC<NodeProps<NodeType<FetchDataMetadata>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData, targetHandles = [], sourceHandles = [] },
  } = props;

  const dataSourceEnabled = nodeData?.dataSourceEnabled ?? false;
  const npcId = nodeData?.npcId ?? "";
  const jsonTemplate = nodeData?.jsonTemplate ?? "";
  const outputSchema = nodeData?.outputSchema ?? "";

  const { currentWorkflowSuggestionMap, setInputSchema } = useAiCompletionSchemaContext();
  const { getTargetNodeOutputSchema } = useNodeLookup();
  const inputSchema = getTargetNodeOutputSchema(targetHandles.map(({ handleId }) => handleId));

  const { register, handleSubmit, control, setValue, watch } = useForm<FetchDataMetadata>({
    defaultValues: {
      dataSourceEnabled,
      npcId,
      jsonTemplate,
      outputSchema,
    },
  });

  const getNpcsResult = useQuery({
    queryKey: ["npcs"],
    queryFn: () => npcsApi.getNpcs(),
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);

  const handleUpdate = useCallback((data: FetchDataMetadata) => {
    updateNodeData(data);
  }, []);

  useEffect(() => {
    updateNodeData({
      ...nodeData,
      inputSchema,
    });

    setInputSchema(inputSchema ?? "{}");
  }, [inputSchema]);

  const promptIdOptions = [
    { label: "{{npcId}}", value: "{{npcId}}" },
    ...(getNpcsResult.data?.data.map((npc) => ({
      label: npc.displayName,
      value: npc.npcId,
    })) ?? []),
  ];

  const { updateNodeSourceHandles } = useUpdateNodeHandles(nodeId);
  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    if (dataSourceEnabled) {
      if (sourceHandles.find(({ handleName }) => handleName === "data") == null) {
        updateNodeSourceHandles([
          ...sourceHandles,
          {
            label: "DATA",
            handleName: "data",
            handleType: "source",
            handleCategory: "data",
          },
        ]);
      }
    } else {
      updateNodeSourceHandles(sourceHandles.filter(({ handleName }) => handleName !== "data"));
    }

    updateNodeInternals(nodeId);
  }, [dataSourceEnabled]);

  return (
    <SchemaPassThroughNodeWithChildren {...props}>
      <FormControl className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <Box w="25rem">
          <FormControl>
            <HStack alignItems="baseline">
              <FormLabel>
                <Text casing={"uppercase"}>Output Data Port Enabled</Text>
              </FormLabel>
              <Checkbox {...register("dataSourceEnabled")} />
            </HStack>
          </FormControl>
        </Box>
        <Box w="full">
          <FormLabel color={color}>NPC</FormLabel>
          {getNpcsResult.isError && <Text color={"red.500"}>{getNpcsResult.error.message}</Text>}
          <Controller
            name={"npcId"}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Select
                onBlur={onBlur}
                name={name}
                ref={ref}
                isLoading={getNpcsResult.isFetching}
                isDisabled={getNpcsResult.isFetching || getNpcsResult.isError || !getNpcsResult.data?.data.length}
                value={promptIdOptions && value ? promptIdOptions.find((option) => option.value === value) : null}
                onChange={(option) => option && onChange(option.value)}
                options={promptIdOptions}
              />
            )}
          />
        </Box>
        <Box w="100%" mt={4}>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              JSON Template
            </Text>
          </FormLabel>
          <MustacheEditor
            suggestionMap={currentWorkflowSuggestionMap}
            onChange={(value) =>
              setValue("jsonTemplate", value || "", {
                shouldDirty: true,
              })
            }
            value={watch("jsonTemplate") ?? jsonTemplate}
            hoverDataMap={{}}
            height="15vh"
          />
        </Box>
        <Box py={1}>
          <FormLabel textTransform={"uppercase"} color={color}>
            Output Schema
          </FormLabel>
          <JSONSchemaEditor name="outputSchema" control={control} height="15vh" />
        </Box>
      </FormControl>
    </SchemaPassThroughNodeWithChildren>
  );
};

export default memo(FetchDataNode);
