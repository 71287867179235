import * as React from "react";
import { useMemo } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RootLayout } from "./layouts/RootLayout";
import { ReactFlowLayout } from "./layouts/ReactFlowLayout";
import useWorkflows from "./api/useWorkflows";
import useUsers from "./api/useUsers";
import useWorkflowsLoader from "./hooks/router/useWorkflowsLoader";
import useWorkflowLoader from "./hooks/router/useWorkflowLoader";
import Error from "./pages/Error";
import Workflows from "./pages/Workflows";
import WorkflowEditor from "./pages/WorkflowEditor";
import Login from "./pages/Login";

export const App = () => {
  const { getWorkflows, getWorkflow } = useWorkflows();
  const { getDisplayName } = useUsers();

  const { loader: workflowsLoader } = useWorkflowsLoader(getWorkflows);
  const { loader: workflowLoader } = useWorkflowLoader(getWorkflow, getDisplayName);

  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          element: <RootLayout />,
          errorElement: <Error />,
          children: [
            {
              index: true,
              element: <Workflows />,
              loader: workflowsLoader,
            },
            {
              path: ":id",
              element: <ReactFlowLayout />,
              children: [
                {
                  index: true,
                  element: <WorkflowEditor />,
                  loader: workflowLoader,
                },
              ],
              loader: workflowLoader,
            },
            {
              path: "/login",
              element: <Login />,
            },
          ],
        },
      ]),
    [workflowsLoader, workflowLoader]
  );

  return <RouterProvider router={router} />;
};
