import React, { memo, useCallback } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { Controller, useForm } from "react-hook-form";
import { Box, FormControl, FormLabel, Text } from "@chakra-ui/react";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import SchemaPassThroughNodeWithChildren from "./SchemaPassThroughNodeWithChildren";
import useWorkflows from "../../../api/useWorkflows";
import { useQuery } from "@tanstack/react-query";
import { Select } from "chakra-react-select";
import { useLoaderData } from "react-router";
import { WorkflowWithId } from "../../../models/api/workflow";

interface FormData {
  workflowName: string;
}

const ExecuteWorkflowNode: React.FC<NodeProps<NodeType>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;
  const { workflow } = useLoaderData() as { workflow: WorkflowWithId };

  const workflowName = nodeData?.workflowName ?? "";

  const { getWorkflows } = useWorkflows();
  const getWorkflowsQuery = useQuery({
    queryKey: ["workflows"],
    queryFn: () => getWorkflows(),
  });

  const workflowNameOptions =
    (getWorkflowsQuery.data || [])
      .filter((w) => workflow.name !== w.name)
      .map((workflow) => ({
        label: workflow.name,
        value: workflow.name,
      })) ?? [];

  const { handleSubmit, control } = useForm<FormData>({
    defaultValues: {
      workflowName,
    },
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);

  const handleUpdate = useCallback((data: FormData) => {
    updateNodeData(data);
  }, []);

  return (
    <SchemaPassThroughNodeWithChildren {...props}>
      <FormControl className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <Box w="full">
          <FormLabel color={color}>Workflow Name</FormLabel>
          {getWorkflowsQuery.isError && <Text color={"red.500"}>{getWorkflowsQuery.error.message}</Text>}
          <Controller
            name={"workflowName"}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Select
                onBlur={onBlur}
                name={name}
                ref={ref}
                isLoading={getWorkflowsQuery.isFetching}
                isDisabled={
                  getWorkflowsQuery.isFetching || getWorkflowsQuery.isError || !getWorkflowsQuery.data?.length
                }
                value={
                  workflowNameOptions && value ? workflowNameOptions.find((option) => option.value === value) : null
                }
                onChange={(option) => option && onChange(option.value)}
                options={workflowNameOptions}
              />
            )}
          />
        </Box>
      </FormControl>
    </SchemaPassThroughNodeWithChildren>
  );
};

export default memo(ExecuteWorkflowNode);
