import React, { memo, PropsWithChildren } from "react";
import { Flex, Icon, IconButton, Tag, Tooltip } from "@chakra-ui/react";
import { useToken } from "@chakra-ui/system";
import { Handle, HandleProps, Position } from "reactflow";
import { EditControl } from "../../../base/EditControl";
import { TagEdit } from "../../../base/TagEdit";
import { MdClose } from "react-icons/md";

interface BaseHandleProps extends HandleProps, PropsWithChildren {
  color?: string;
  label?: string;
  style?: React.CSSProperties;
  isSelected?: boolean;
  isEditing?: boolean;
  isEditable?: boolean;
  showIsConnectable?: boolean;
  onUpdate?: (label: string) => void;
  onDelete?: () => void;
  onInsertBefore?: () => void;
  onInsertAfter?: () => void;
}

const BaseHandle: React.FC<BaseHandleProps> = ({
  color: token,
  label,
  style,
  isSelected,
  isEditing,
  isEditable,
  showIsConnectable,
  onUpdate,
  onDelete,
  onInsertBefore,
  onInsertAfter,
  position,
  children,
  ...handleProps
}) => {
  const [color] = useToken("colors", [token ?? "white"]);

  const showTag = !!label;
  const showChildren = !!children && position !== Position.Top && position !== Position.Bottom;

  let direction: "row" | "row-reverse" | "column" | "column-reverse" | undefined = undefined;
  let margins: Record<string, string | number> = {};

  switch (position) {
    case Position.Left: {
      direction = "row";
      margins = { marginLeft: "-2.5rem", marginRight: "1.5rem" };
      break;
    }
    case Position.Right: {
      direction = "row-reverse";
      margins = { marginRight: "-2.5rem", marginLeft: "1.5rem" };
      break;
    }
  }

  return (
    <Flex direction={direction} p={1} alignItems={"center"}>
      <Handle
        position={position}
        style={{
          position: "relative",
          width: "1.5rem",
          height: "1.5rem",
          backgroundColor: "transparent",
          borderColor: isSelected || showIsConnectable ? "white" : color,
          borderWidth: 6,
          top: "unset",
          left: "unset",
          right: "unset",
          transform: "none",
          filter: isSelected || showIsConnectable ? "drop-shadow(0px 0px 2px white)" : undefined,
          ...style,
          ...margins,
        }}
        {...handleProps}
      />
      <Flex direction={direction} gap={2} alignItems={"center"} data-group>
        {isEditable && isEditing && (
          <EditControl
            position={position}
            onDelete={onDelete}
            onInsertBefore={onInsertBefore}
            onInsertAfter={onInsertAfter}
          />
        )}

        {showTag &&
          (isEditable ? (
            <TagEdit className="nodrag" color={color} size={"lg"} value={label} onUpdate={onUpdate} />
          ) : (
            <Tag color={color} size={"lg"}>
              {label?.toUpperCase()}
            </Tag>
          ))}

        {isEditable && !isEditing && (
          <Tooltip label={"delete"}>
            <IconButton
              color={color}
              size={"sm"}
              variant={"ghost"}
              icon={<Icon as={MdClose} h={"100%"} />}
              aria-label={"delete"}
              onClick={onDelete}
              display={"none"}
              _groupHover={{ display: "block" }}
            />
          </Tooltip>
        )}

        {showChildren && children}
      </Flex>
    </Flex>
  );
};

export default memo(BaseHandle);
