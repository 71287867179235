import React, { memo, useEffect, useLayoutEffect, useRef, useState } from "react";
import { HStack, Spinner, Stack, Text, VStack } from "@chakra-ui/react";
import { useAiWorkflowRunsContext } from "../../../context/AiWorkflowRunsContext";
import WorkflowRun from "./WorkflowRun";
import useScrollInfo from "react-element-scroll-hook";
import { AiWorkflowRunListResult } from "@worldwidewebb/client-ai";

const NodeDebugger: React.FC = () => {
  const { workflowRunsQuery } = useAiWorkflowRunsContext();

  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollableHeight, setScrollableHeight] = useState<number>(0);
  const [scrollInfo, scrollableRef] = useScrollInfo();
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  useLayoutEffect(() => {
    const containerRefHeight = containerRef.current?.offsetHeight ?? 0;

    setScrollableHeight(containerRefHeight);

    return () => setScrollableHeight(0);
  }, []);

  useEffect(() => {
    if (scrollInfo.y.percentage > 0.9 && !workflowRunsQuery.isLoading && !isLoadingMore) {
      workflowRunsQuery.loadNext();
      setIsLoadingMore(true);
    }
  }, [scrollableRef.current, scrollInfo.y, workflowRunsQuery.isLoading]);

  if (workflowRunsQuery.error != null) {
    return (
      <HStack p={4}>
        <Text color={"white"} casing={"uppercase"}>
          {workflowRunsQuery.error.message}
        </Text>
      </HStack>
    );
  }

  const getWorkflowRunColor = (workflowRun: AiWorkflowRunListResult, index: number) => {
    if (workflowRun.error !== undefined) {
      return "red.900";
    }

    if (workflowRun.hasCompleted) {
      return index % 2 === 0 ? "gray.800" : "gray.900";
    }

    return "gray.700";
  };

  return (
    <Stack ref={containerRef} flexGrow={1} position={"relative"} pb={8} w="xl">
      <HStack p={4} position={"absolute"} top={0} right={0}>
        {workflowRunsQuery.isLoading && (
          <VStack>
            <Spinner size={"md"} color={"white"} />
          </VStack>
        )}
      </HStack>

      <Stack
        flexGrow={1}
        h={scrollableHeight}
        ref={scrollableRef}
        minW={"md"}
        sx={{
          "::-webkit-scrollbar": {
            width: 2,
          },
          "::-webkit-scrollbar-track": {
            bg: "mirage.900",
          },
          "::-webkit-scrollbar-thumb": {
            bg: "indigo.600",
          },
        }}
        overflowY={"auto"}
        onScroll={() => setIsLoadingMore(false)}
        style={{ marginTop: 0 }}
      >
        {(workflowRunsQuery.data || []).map((run, index) => (
          <Stack
            borderTopWidth={1}
            borderColor={"gray.700"}
            style={{ marginTop: 0 }}
            backgroundColor={getWorkflowRunColor(run, index)}
            key={run.workflowRunId}
          >
            <WorkflowRun runInfo={run} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default memo(NodeDebugger);
