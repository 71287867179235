import { FormControl, FormLabel, Select, Text } from "@chakra-ui/react";
import React, { memo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { NodeProps } from "reactflow";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { NodeType } from "../../../models/nodeType";
import SchemaPassThroughNodeWithChildren from "./SchemaPassThroughNodeWithChildren";

const sessionStatuses: string[] = ["in_progress", "completed", "closed"];

interface SessionStatus {
  sessionStatus: string;
}

const SetSessionStatusNode: React.FC<NodeProps<NodeType<SessionStatus>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const sessionStatus = nodeData?.sessionStatus ?? "";

  const { register, handleSubmit } = useForm<SessionStatus>({
    defaultValues: {
      sessionStatus,
    },
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);

  const handleUpdate = useCallback((sessionStatus: SessionStatus) => {
    updateNodeData(sessionStatus);
  }, []);

  return (
    <SchemaPassThroughNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <FormControl>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              Session Status
            </Text>
          </FormLabel>
          <Select id={"sessionStatus"} textTransform={"uppercase"} {...register("sessionStatus")}>
            {sessionStatuses &&
              sessionStatuses.map((sessionStatus) => (
                <option key={sessionStatus} value={sessionStatus}>
                  {sessionStatus.toUpperCase()}
                </option>
              ))}
          </Select>
        </FormControl>
      </form>
    </SchemaPassThroughNodeWithChildren>
  );
};

export default memo(SetSessionStatusNode);
