import { useCallback } from "react";
import { ulid } from "ulid";
import useInitialNodeTypes from "../hooks/useInitialNodeTypes";
import { useCopyAndPaste } from "../hooks/useCopyAndPaste";
import { Node } from "reactflow";
import { NodeType } from "../models/nodeType";
import { WorkflowWithId } from "../models/api/workflow";
import { JSONSchema7, WorkflowNode } from "@worldwidewebb/client-ai";
import { aiApi } from ".";

const useWorkflows = () => {
  const initialNodeTypes = useInitialNodeTypes();
  const { createDuplicates } = useCopyAndPaste();

  const getWorkflows = useCallback(async (name?: string): Promise<WorkflowWithId[]> => {
    try {
      const response = await aiApi.getAiEditorAis(name);
      const { data: workflows } = response;

      return workflows
        .sort(({ name: a }, { name: b }) => a.localeCompare(b))
        .map((workflow) => transformRawWorkflow(workflow));
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const getWorkflow = useCallback(async (workflowId: string): Promise<WorkflowWithId> => {
    try {
      const response = await aiApi.getAiEditorAi(workflowId);
      const { data: workflow } = response;

      return transformRawWorkflow(workflow);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const getWorkflowCompletionSchema = useCallback(async (workflowName: string): Promise<JSONSchema7> => {
    try {
      const response = await aiApi.getAiCompletionSchema(workflowName);

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const setWorkflow = useCallback(
    async ({
      workflowId,
      name,
      description,
      data,
      dataDefinitions,
      isReady,
      priority,
    }: WorkflowWithId): Promise<WorkflowWithId> => {
      if (data == null) {
        throw new Error("Saving has been prevented to prevent data loss (something is very wrong)");
      }

      try {
        const response = await aiApi.setAiEditorAi(workflowId, {
          npcIds: [],
          version: "",
          name,
          description,
          aiEditorData: data,
          aiEditorDataDefinitions: dataDefinitions,
          isReady,
          priority,
        });
        const { data: workflow } = response;
        return transformRawWorkflow(workflow);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    []
  );

  const setWorkflowNodes = useCallback(async (workflowId: string, workflowNodes: WorkflowNode[]) => {
    try {
      await aiApi.setAiEditorAiNodes(workflowId, workflowNodes);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const createWorkflow = useCallback(async (): Promise<WorkflowWithId> => {
    return setWorkflow({
      workflowId: ulid(),
      name: `untitled-workflow-${ulid()}`,
      description: "",
      data: {
        edges: [],
        nodes: [],
      },
      dataDefinitions: initialNodeTypes,
      isReady: false,
      priority: 0,
    });
  }, [setWorkflow, initialNodeTypes]);

  const updateWorkflow = useCallback(
    async ({ workflowId, name, description, isReady, priority }: WorkflowWithId): Promise<void> => {
      const workflow = await getWorkflow(workflowId);

      await setWorkflow({
        ...workflow,
        name,
        description,
        isReady,
        priority,
      });
    },
    [getWorkflow, setWorkflow]
  );

  const deleteWorkflow = useCallback(async (workflowId: string): Promise<void> => {
    try {
      await aiApi.deleteAiEditorAi(workflowId);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const duplicateWorkflow = useCallback(
    async (workflowId: string): Promise<void> => {
      const {
        name,
        description,
        data: { nodes, edges },
        dataDefinitions,
        priority,
      } = await getWorkflow(workflowId);

      const { nodes: duplicatedNodes, edges: duplicatedEdges } = createDuplicates(nodes, edges);

      await setWorkflow({
        workflowId: ulid(),
        name: `${name} (duplicate)`,
        description,
        data: {
          nodes: duplicatedNodes as Node<NodeType>[],
          edges: duplicatedEdges,
        },
        dataDefinitions,
        isReady: false,
        priority,
      });
    },
    [getWorkflow, setWorkflow, createDuplicates]
  );

  // TODO: replace with intended API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getOwnWorkflowPointers = useCallback(async (workflowId: string): Promise<any[]> => {
    try {
      // TODO: replace with intended API
      // const response = await workflowApi.getOwnWorkflowPointers(workflowId);
      // const { data: workflowPointers } = response;

      // return workflowPointers;
      return [];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  return {
    getWorkflows,
    getWorkflow,
    setWorkflow,
    setWorkflowNodes,
    createWorkflow,
    updateWorkflow,
    deleteWorkflow,
    duplicateWorkflow,
    getOwnWorkflowPointers,
    getWorkflowCompletionSchema,
  };
};

export default useWorkflows;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformRawWorkflow = (rawWorkflow: any): WorkflowWithId => ({
  workflowId: rawWorkflow.aiId,
  data: rawWorkflow.aiEditorData,
  dataDefinitions: rawWorkflow.aiEditorDataDefinitions,
  name: rawWorkflow.name,
  description: rawWorkflow.description,
  isReady: rawWorkflow.isReady,
  priority: rawWorkflow.priority,
});
