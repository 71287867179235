import React, { memo, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { NodeProps, Position, useReactFlow, useStore } from "reactflow";
import {
  Box,
  Card,
  CardBody,
  CardFooterProps,
  CardHeader,
  CardHeaderProps,
  Flex,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useToken } from "@chakra-ui/system";
import { MdEdit, MdSettings } from "react-icons/md";
import BaseHandle from "../../nodeHandles/base/BaseHandle";
import { BaseNodeConfigModal } from "./BaseNodeConfigModal";
import { TextEdit } from "../../../base/TextEdit";
import { getHandleCategoryColor, NodeType, SourceHandle, TargetHandle } from "../../../../models/nodeType";
import { useUpdateNodeHandles } from "../../../../hooks/useUpdateNodeHandles";
import { AiOutlineCopy } from "react-icons/ai";
import useNodeHandlesValidation from "../../../../hooks/useNodeHandlesValidation";
import { useAiWorkflowRunsContext } from "../../../../context/AiWorkflowRunsContext";

interface BaseNodeHeaderProps extends CardHeaderProps {
  label?: string;
  color?: string;
  isReady?: boolean;
  isSelected?: boolean;
  nodeDescription?: string;
}

export const BaseNodeHeader: React.FC<BaseNodeHeaderProps> = ({
  id,
  label,
  color: token,
  isReady,
  isSelected,
  nodeDescription,
  children,
  ...cardHeaderProps
}) => {
  const [color] = useToken("colors", [token ?? "white"]);
  const toast = useToast();

  const inDevelopmentStyle: React.CSSProperties = {
    backgroundImage: "repeating-linear-gradient(-45deg, transparent, transparent 10px, #F6E05E 10px, #F6E05E 20px)",
    position: "absolute",
    left: 0,
    right: 0,
    height: "0.5rem",
  };

  const handleCopyNodeId = useCallback(async () => {
    if (id == null) {
      return;
    }

    await navigator.clipboard.writeText(id);

    toast({
      title: `Copied ID ${id} to clipboard`,
      status: "info",
    });
  }, [id]);

  return (
    <CardHeader
      bg={"theme.dark.background"}
      borderBottomWidth={1}
      borderTopWidth={8}
      borderTopColor={isSelected ? "white" : color}
      {...cardHeaderProps}
      style={{ filter: isSelected ? "drop-shadow(0px -2px 2px white)" : undefined, position: "relative" }}
    >
      <Flex style={isReady ? {} : { ...inDevelopmentStyle, top: 0 }} />
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={2} justifyContent={"space-between"} flexGrow={1}>
          <Tooltip label={nodeDescription}>
            <Text color={isSelected ? "white" : color} fontWeight={700}>
              {label?.toUpperCase()}
            </Text>
          </Tooltip>
          {id && (
            <Tooltip label={"copy ID"}>
              <IconButton
                size={"sm"}
                color={isSelected ? "white" : color}
                variant={"ghost"}
                icon={<Icon as={AiOutlineCopy} />}
                aria-label={"copy id"}
                onClick={handleCopyNodeId}
              />
            </Tooltip>
          )}
        </Flex>
        {children && children}
      </Flex>
    </CardHeader>
  );
};

interface BaseNodeFooterProps extends CardFooterProps {
  color?: string;
  isSelected?: boolean;
}

export const BaseNodeFooter: React.FC<BaseNodeFooterProps> = ({ color: token, isSelected, ...cardFooterProps }) => {
  const [color] = useToken("colors", [token ?? "white"]);

  return (
    <Box
      bg={"theme.dark.background"}
      borderBottomWidth={1}
      borderBottomColor={isSelected ? "white" : color}
      {...cardFooterProps}
      style={{ filter: isSelected ? "drop-shadow(0px 2px 2px white)" : undefined }}
    ></Box>
  );
};

interface SourceHandleComponentProps extends PropsWithChildren {
  id?: string;
  isConnectable: boolean;
  isSelected?: boolean;
  isEditing?: boolean;
  isEditable?: boolean;
  showIsConnectable?: boolean;
  onUpdate?: (label: string) => void;
  onDelete?: () => void;
  onInsertBefore?: () => void;
  onInsertAfter?: () => void;
  label?: string;
  color?: string;
}

const SourceHandleComponent: React.FC<SourceHandleComponentProps> = ({
  id,
  isConnectable,
  isSelected,
  isEditing,
  isEditable,
  showIsConnectable,
  onUpdate,
  onDelete,
  onInsertBefore,
  onInsertAfter,
  label,
  color,
  children,
}) => {
  return (
    <BaseHandle
      id={id}
      isConnectable={isConnectable}
      isSelected={isSelected}
      isEditing={isEditing}
      isEditable={isEditable}
      showIsConnectable={showIsConnectable}
      onUpdate={onUpdate}
      onDelete={onDelete}
      onInsertBefore={onInsertBefore}
      onInsertAfter={onInsertAfter}
      type={"source"}
      position={Position.Right}
      label={label}
      color={color}
    >
      {children && children}
    </BaseHandle>
  );
};

interface TargetHandleComponentProps {
  id?: string;
  isConnectable: boolean;
  isSelected?: boolean;
  isEditing?: boolean;
  isEditable?: boolean;
  showIsConnectable?: boolean;
  onUpdate?: (label: string) => void;
  onDelete?: () => void;
  onInsertBefore?: () => void;
  onInsertAfter?: () => void;
  label?: string;
  color?: string;
}

const TargetHandleComponent: React.FC<TargetHandleComponentProps> = ({
  id,
  isConnectable,
  isSelected,
  isEditing,
  isEditable,
  showIsConnectable,
  onUpdate,
  onDelete,
  onInsertBefore,
  onInsertAfter,
  label,
  color,
}) => {
  return (
    <BaseHandle
      id={id}
      isConnectable={isConnectable}
      isSelected={isSelected}
      isEditing={isEditing}
      isEditable={isEditable}
      showIsConnectable={showIsConnectable}
      onUpdate={onUpdate}
      onDelete={onDelete}
      onInsertBefore={onInsertBefore}
      onInsertAfter={onInsertAfter}
      type={"target"}
      position={Position.Left}
      label={label}
      color={color}
    />
  );
};

const BaseNode: React.FC<NodeProps<NodeType>> = (props) => {
  return <BaseNodeWithChildren {...props} />;
};

interface BaseNodeWithChildrenProps extends NodeProps<NodeType>, PropsWithChildren {
  overview?: React.ReactNode;
}

export const BaseNodeWithChildren: React.FC<BaseNodeWithChildrenProps> = ({
  id: nodeId,
  isConnectable,
  selected: isSelected,
  data: {
    label,
    color,
    targetHandles,
    sourceHandles,
    isEditable,
    isTargetHandlesEditable,
    isSourceHandlesEditable,
    isReady,
    nodeDescription,
  },
  children,
  overview,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { getValidConnectionHandles } = useNodeHandlesValidation();

  const initiatingConnectionHandleId = useStore((state) => state.connectionHandleId);
  const initiatingConnectionHandleType = useStore((state) => state.connectionHandleType);
  const validConnectionHandles = getValidConnectionHandles(
    nodeId,
    initiatingConnectionHandleId,
    initiatingConnectionHandleType
  );

  const validConnectionHandleIds = validConnectionHandles.map(({ handleId }) => handleId);

  const reactFlow = useReactFlow();
  const { updateNodeTargetHandles, updateNodeSourceHandles } = useUpdateNodeHandles(nodeId);

  const handleSourceHandleDataChange = useCallback(
    (sourceHandleIndex: number, sourceHandleValue: string) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;
        const nodeHandleData = nodeDataCloned.sourceHandles?.at(sourceHandleIndex)?.handleData;

        if (nodeHandleData != null) {
          nodeHandleData.value = sourceHandleValue;
        }

        node.data = {
          ...nodeDataCloned,
        };

        return nodes;
      });
    },
    [reactFlow, nodeId]
  );

  const handleDeselectNode = useCallback(() => {
    if (isEditing) {
      return;
    }

    reactFlow.setNodes((nodes) => {
      const node = nodes.find(({ id }) => id === nodeId);

      if (node == null) {
        return nodes;
      }

      node.selected = false;

      return nodes;
    });
  }, [reactFlow, nodeId, isEditing]);

  const hasSourceOrTargetHandles = sourceHandles?.length !== 0 || targetHandles?.length !== 0;

  useEffect(() => {
    if (isSelected) {
      return;
    }

    setIsEditing(false);
  }, [isSelected]);

  const handleUpdateTargetHandle = useCallback(
    (index: number, label: string) => {
      if (!label.trim()) {
        return;
      }

      if (targetHandles == null) {
        return;
      }

      const targetHandlesCloned = structuredClone(targetHandles);

      targetHandlesCloned.at(index).label = label;
      targetHandlesCloned.at(index).handleName = label.toLowerCase().replaceAll(" ", "_");

      updateNodeTargetHandles(targetHandlesCloned);
    },
    [targetHandles, updateNodeTargetHandles]
  );

  const handleUpdateSourceHandle = useCallback(
    (index: number, label: string) => {
      if (!label.trim()) {
        return;
      }

      if (sourceHandles == null) {
        return;
      }

      const sourceHandlesCloned = structuredClone(sourceHandles);

      sourceHandlesCloned.at(index).label = label;
      sourceHandlesCloned.at(index).handleName = label.toLowerCase().replaceAll(" ", "_");

      updateNodeSourceHandles(sourceHandlesCloned);
    },
    [sourceHandles, updateNodeSourceHandles]
  );

  const handleDeleteTargetHandle = useCallback(
    (index: number) => {
      if (targetHandles == null) {
        return;
      }

      const targetHandlesCloned = structuredClone(targetHandles);

      targetHandlesCloned.splice(index, 1);

      updateNodeTargetHandles(targetHandlesCloned);
    },
    [targetHandles, updateNodeTargetHandles]
  );

  const handleDeleteSourceHandle = useCallback(
    (index: number) => {
      if (sourceHandles == null) {
        return;
      }

      const sourceHandlesCloned = structuredClone(sourceHandles);

      sourceHandlesCloned.splice(index, 1);

      updateNodeSourceHandles(sourceHandlesCloned);
    },
    [sourceHandles, updateNodeSourceHandles]
  );

  const handleInsertTargetHandle = useCallback(
    (index: number, targetHandle: TargetHandle) => {
      if (targetHandles == null) {
        return;
      }

      const targetHandlesCloned = structuredClone(targetHandles);

      const targetHandleCloned = structuredClone(targetHandle);
      targetHandleCloned.handleId = undefined;

      updateNodeTargetHandles([
        ...targetHandlesCloned.slice(0, index),
        targetHandleCloned,
        ...targetHandlesCloned.slice(index),
      ]);
    },
    [targetHandles, updateNodeTargetHandles]
  );

  const handleInsertSourceHandle = useCallback(
    (index: number, sourceHandle: SourceHandle) => {
      if (sourceHandles == null) {
        return;
      }

      const sourceHandlesCloned = structuredClone(sourceHandles);

      const sourceHandleCloned = structuredClone(sourceHandle);
      sourceHandleCloned.handleId = undefined;

      updateNodeSourceHandles([
        ...sourceHandlesCloned.slice(0, index),
        sourceHandleCloned,
        ...sourceHandlesCloned.slice(index),
      ]);
    },
    [sourceHandles, updateNodeSourceHandles]
  );

  const { debuggedNode } = useAiWorkflowRunsContext();

  const workflowPointerBorderColor = useMemo(() => {
    if (debuggedNode?.nodeId !== nodeId) {
      return;
    }

    return debuggedNode.hasFailed ? "red.500" : "indigo.600";
  }, [debuggedNode]);

  return (
    <>
      <Flex
        p={1}
        gap={1}
        direction={"column"}
        borderColor={workflowPointerBorderColor}
        borderWidth={workflowPointerBorderColor ? 3 : 0}
        onKeyDown={handleDeselectNode}
      >
        <Card bg={"theme.dark.background"} borderRadius={0} minW={"20rem"}>
          <BaseNodeHeader
            id={nodeId}
            label={label}
            color={color}
            isSelected={isSelected}
            isReady={isReady}
            nodeDescription={nodeDescription}
          >
            <Flex gap={2}>
              {isEditing && (
                <IconButton
                  size={"sm"}
                  variant={"ghost"}
                  color={isSelected ? "white" : color}
                  icon={<Icon as={MdSettings} />}
                  aria-label={"settings"}
                  onClick={onOpen}
                />
              )}
              {isEditable && (
                <IconButton
                  size={"sm"}
                  variant={"ghost"}
                  color={isSelected ? "white" : color}
                  bg={isEditing ? "whiteAlpha.200" : undefined}
                  icon={<Icon as={MdEdit} />}
                  aria-label={"edit"}
                  onClick={() => setIsEditing((isEditing) => !isEditing)}
                />
              )}
            </Flex>
          </BaseNodeHeader>

          {hasSourceOrTargetHandles && (
            <Flex gap={2} py={2} justifyContent={"space-between"}>
              <Flex gap={0.25} direction={"column"}>
                {targetHandles &&
                  targetHandles.map((targetHandle, index) => {
                    const { handleId, handleCategory, label } = targetHandle;

                    return (
                      <TargetHandleComponent
                        key={index}
                        id={handleId}
                        isConnectable={isConnectable}
                        isSelected={isSelected}
                        isEditing={isEditing}
                        isEditable={isTargetHandlesEditable}
                        showIsConnectable={validConnectionHandleIds.includes(handleId)}
                        onUpdate={(label: string) => handleUpdateTargetHandle(index, label)}
                        onDelete={() => handleDeleteTargetHandle(index)}
                        onInsertBefore={() => handleInsertTargetHandle(index, targetHandle)}
                        onInsertAfter={() => handleInsertTargetHandle(index + 1, targetHandle)}
                        label={label}
                        color={getHandleCategoryColor(handleCategory)}
                      />
                    );
                  })}
              </Flex>
              <Flex gap={0.25} direction={"column"}>
                {sourceHandles &&
                  sourceHandles.map((sourceHandle, index) => {
                    const { handleId, handleCategory, handleData, label } = sourceHandle;

                    return (
                      <SourceHandleComponent
                        key={index}
                        id={handleId}
                        isConnectable={isConnectable}
                        isSelected={isSelected}
                        isEditing={isEditing}
                        isEditable={isSourceHandlesEditable}
                        showIsConnectable={validConnectionHandleIds.includes(handleId)}
                        onUpdate={(label: string) => handleUpdateSourceHandle(index, label)}
                        onDelete={() => handleDeleteSourceHandle(index)}
                        onInsertBefore={() => handleInsertSourceHandle(index, sourceHandle)}
                        onInsertAfter={() => handleInsertSourceHandle(index + 1, sourceHandle)}
                        label={label}
                        color={getHandleCategoryColor(handleCategory)}
                      >
                        {handleData && (
                          <TextEdit
                            className="nodrag"
                            size={"sm"}
                            color={getHandleCategoryColor(handleCategory)}
                            value={handleData.value}
                            onUpdate={(value: string) => handleSourceHandleDataChange(index, value)}
                          />
                        )}
                      </SourceHandleComponent>
                    );
                  })}
              </Flex>
            </Flex>
          )}

          {children && (
            <CardBody p={2}>
              {overview}
              {children}
            </CardBody>
          )}

          <BaseNodeFooter color={color} isSelected={isSelected} />
        </Card>
      </Flex>

      <BaseNodeConfigModal
        isOpen={isOpen}
        onClose={onClose}
        targetHandles={targetHandles}
        sourceHandles={sourceHandles}
        onUpdateTargetHandles={updateNodeTargetHandles}
        onUpdateSourceHandles={updateNodeSourceHandles}
        color={color}
      />
    </>
  );
};

export default memo(BaseNode);
