import { Box, FormLabel, Input, Stack } from "@chakra-ui/react";
import React, { memo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { NodeProps } from "reactflow";
import { useAiCompletionSchemaContext } from "../../../context/AiCompletionSchemaContext";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { NodeType } from "../../../models/nodeType";
import NpcSelect from "../../base/NpcSelect";
import MustacheEditor from "../../editor/MustacheEditor";
import SchemaPassThroughNodeWithChildren, { SchemaData } from "./SchemaPassThroughNodeWithChildren";

interface KeyValueUpdateData extends SchemaData {
  npcId: string;
  key: string;
  value: string;
}

const KeyValueUpdateNode: React.FC<NodeProps<NodeType<KeyValueUpdateData>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData, targetHandles = [] },
  } = props;

  const key = nodeData?.key ?? "";
  const value = nodeData?.value ?? "";
  const npcId = nodeData?.npcId ?? "";

  const { control, register, handleSubmit, setValue, watch } = useForm<KeyValueUpdateData>({
    defaultValues: {
      key,
      value,
      npcId,
    },
  });

  const { currentWorkflowSuggestionMap } = useAiCompletionSchemaContext();

  const { updateNodeData } = useUpdateNodeData(nodeId);

  const handleUpdate = useCallback((data: KeyValueUpdateData) => {
    updateNodeData(data);
  }, []);

  return (
    <SchemaPassThroughNodeWithChildren {...props}>
      <form
        className={"nodrag"}
        onSubmit={handleSubmit(handleUpdate)}
        onBlur={handleSubmit(handleUpdate)}
        onChange={handleSubmit(handleUpdate)}
      >
        <NpcSelect control={control} color={color} />
        <Stack w="25rem">
          <Box py={1}>
            <FormLabel textTransform={"uppercase"} color={color}>
              Key
            </FormLabel>
            <Input type={"text"} placeholder="key" {...register("key")} />
          </Box>
          <Box py={1}>
            <FormLabel textTransform={"uppercase"} color={color}>
              Value
            </FormLabel>
            <MustacheEditor
              suggestionMap={currentWorkflowSuggestionMap}
              onChange={(value) =>
                setValue("value", value || "", {
                  shouldDirty: true,
                })
              }
              value={watch("value") ?? value}
              hoverDataMap={{}}
              height="15vh"
            />
          </Box>
        </Stack>
      </form>
    </SchemaPassThroughNodeWithChildren>
  );
};

export default memo(KeyValueUpdateNode);
