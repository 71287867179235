import { KeyboardEvent, useCallback, useEffect } from "react";
import { useReactFlow } from "reactflow";
import { useCopyAndPaste } from "./useCopyAndPaste";

export const useCopyAndPasteShortcuts = () => {
  const { copy, paste } = useCopyAndPaste();
  const { getNodes, setNodes, getEdges, setEdges } = useReactFlow();

  const handleShortcutCopy = useCallback(
    (event: Event) => {
      const { ctrlKey, metaKey, key, target } = event as unknown as KeyboardEvent;

      if (!((ctrlKey || metaKey) && key === "c")) {
        return;
      }

      if (!(target instanceof HTMLElement)) {
        return;
      }

      if (target.closest("form")) {
        return;
      }

      copy(getNodes(), getEdges());
    },
    [getNodes, getEdges, copy]
  );

  const handleShortcutPaste = useCallback(
    (event: Event) => {
      const { ctrlKey, metaKey, key, target } = event as unknown as KeyboardEvent;

      if (!((ctrlKey || metaKey) && key === "v")) {
        return;
      }

      if (!(target instanceof HTMLElement)) {
        return;
      }

      if (target.closest("form")) {
        return;
      }

      const { nodes: newNodes, edges: newEdges } = paste();

      setNodes((nodes) => [...nodes.map((node) => ({ ...node, selected: false })), ...newNodes]);
      setEdges((edges) => [...edges.map((edge) => ({ ...edge, selected: false })), ...newEdges]);
    },
    [setNodes, setEdges, paste]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleShortcutCopy);
    document.addEventListener("keydown", handleShortcutPaste);

    return () => {
      document.removeEventListener("keydown", handleShortcutCopy);
      document.removeEventListener("keydown", handleShortcutPaste);
    };
  }, [handleShortcutCopy, handleShortcutPaste]);
};
