import React, { memo, useCallback, useEffect, useState } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { FormControl, FormLabel, Select, Text } from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { SchemaObject } from "ajv";
import { BaseNodeWithChildren } from "./base/BaseNode";
import useNodeLookup from "../../../hooks/useNodeLookup";
import { Editor } from "@monaco-editor/react";

export interface ExtractValueNodeData {
  inputSchema: string;
  field: string;
}

const CreateExtractValueNode: React.FC<NodeProps<NodeType<ExtractValueNodeData>>> = (props) => {
  const [fields, setFields] = useState<string[]>([]);

  const {
    id: nodeId,
    data: { color, nodeData, targetHandles = [] },
  } = props;

  const field = nodeData?.field ?? "";

  const { register, handleSubmit, control } = useForm<ExtractValueNodeData>({
    defaultValues: {
      field,
    },
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);
  const { getTargetNodeOutputSchema } = useNodeLookup();

  const nodeSchema = getTargetNodeOutputSchema(targetHandles.map(({ handleId }) => handleId));

  useEffect(() => {
    updateNodeData({
      ...nodeData,
      inputSchema: nodeSchema,
    });

    parseSchema(nodeSchema ?? "{}");
  }, [nodeSchema]);

  const extractFields = (schema: SchemaObject, prefix = "") => {
    let fields: string[] = [];
    if (schema.type === "object" && schema.properties) {
      for (const key in schema.properties) {
        const path = prefix ? `${prefix}.${key}` : key;
        fields.push(path);
        fields = fields.concat(extractFields(schema.properties[key], path));
      }
    }
    return fields;
  };

  const parseSchema = useCallback(
    (rawSchema: string) => {
      try {
        const schema = JSON.parse(rawSchema);
        const fields = extractFields(schema);
        setFields(fields);
      } catch (e) {
        console.error(e);
        setFields([]);
      }
    },
    [setFields]
  );

  useEffect(() => {
    if ((!field || field === "") && fields.length > 0) {
      updateNodeData({
        ...nodeData,
        field: fields[0],
      });
    }
  }, [fields]);

  const handleUpdate = useCallback((data: ExtractValueNodeData) => {
    updateNodeData(data);
  }, []);

  return (
    <BaseNodeWithChildren {...props}>
      <form
        className={"nodrag"}
        onSubmit={handleSubmit(handleUpdate)}
        onBlur={handleSubmit(handleUpdate)}
        onChange={handleSubmit(handleUpdate)}
      >
        <FormControl>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              Input JSON Schema
            </Text>
          </FormLabel>
          <Editor
            theme="vs-dark"
            height={"10vh"}
            language={"json"}
            options={{
              readOnly: true,
              lineNumbers: "off",
              minimap: { enabled: false },
              wordWrap: "on",
            }}
            value={nodeSchema}
          />
        </FormControl>
        <FormControl mt={2}>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              Field to Extract
            </Text>
          </FormLabel>
          <Controller
            name={"field"}
            control={control}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Select
                onBlur={onBlur}
                name={name}
                ref={ref}
                value={value}
                onChange={(e) => {
                  return onChange(e.target.value);
                }}
              >
                {fields.map((field) => (
                  <option key={field} value={field}>
                    {field}
                  </option>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </form>
    </BaseNodeWithChildren>
  );
};

export default memo(CreateExtractValueNode);
