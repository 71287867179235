import React, { memo, useCallback, useEffect } from "react";
import { NodeProps, useUpdateNodeInternals } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Select,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import MustacheEditor from "../../editor/MustacheEditor";
import { SchemaData } from "./SchemaPassThroughNodeWithChildren";
import useNodeLookup from "../../../hooks/useNodeLookup";
import { BaseNodeWithChildren } from "./base/BaseNode";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import { useAiCompletionSchemaContext } from "../../../context/AiCompletionSchemaContext";
import NpcSelect from "../../base/NpcSelect";
import { Editor } from "@monaco-editor/react";
import { JSONSchema7 } from "@worldwidewebb/client-ai";
import LoreTopicSelect from "../../base/LoreTopicSelect";

enum SemanticDataType {
  Lore = "lore",
  LoreWithCriteria = "lore-with-criteria",
  MemoryWithAccess = "memory-with-access",
  MemoryWithNoAccess = "memory-with-no-access",
  ItemIntrinsic = "item-intrinsic",
}

type Thresholds = { [key: string]: { min?: number; max?: number } };

interface LoreCriteria {
  relevanceThresholds?: Thresholds;
  maxNumOfLore?: number;
}

interface SemanticSearchData extends SchemaData {
  dataSourceEnabled: boolean;
  type: SemanticDataType;
  query: string;
  npcId: string;
  topicId: string;
  loreCriteria?: LoreCriteria;
}

const typeOptions = [
  SemanticDataType.Lore,
  SemanticDataType.LoreWithCriteria,
  SemanticDataType.MemoryWithAccess,
  SemanticDataType.MemoryWithNoAccess,
  SemanticDataType.ItemIntrinsic,
];

const loreEntrySchema: JSONSchema7 = {
  type: "object",
  properties: {
    entry: {
      type: "string",
    },
    topicId: {
      type: "string",
    },
    createdAt: {
      type: "object",
    },
    loreId: {
      type: "string",
    },
    relevance: {
      type: "string",
    },
    score: {
      type: "number",
    },
  },
};

const memoryEntrySchema: JSONSchema7 = {
  type: "object",
  properties: {
    type: {
      type: "string",
    },
    memory: {
      type: "string",
    },
    importance: {
      type: "number",
    },
    npcId: {
      type: "string",
    },
    memoryId: {
      type: "string",
    },
    accessedAt: {
      type: "object",
    },
    createdAt: {
      type: "object",
    },
    relevance: {
      type: "number",
    },
  },
};

const accessedMemorySchema: JSONSchema7 = {
  type: "object",
  properties: {
    memory: {
      type: "string",
    },
    type: {
      type: "string",
    },
    importance: {
      type: "string",
    },
    relevance: {
      type: "string",
    },
    accessInfo: {
      type: "string",
    },
    createdInfo: {
      type: "string",
    },
    accessedAt: {
      type: "object",
    },
    createdAt: {
      type: "object",
    },
  },
};

const itemIntrinsicSchema: JSONSchema7 = {
  type: "object",
  properties: {
    itemName: {
      type: "string",
    },
    description: {
      type: "string",
    },
    type: {
      type: "string",
    },
    entry: {
      type: "string",
    },
    constraints: {
      type: "object",
    },
    itemId: {
      type: "string",
    },
    createdAt: {
      type: "string",
    },
    updatedAt: {
      type: "string",
    },
    score: {
      type: "number",
    },
  },
};

const SemanticSearchNode: React.FC<NodeProps<NodeType<SemanticSearchData>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData, targetHandles = [], sourceHandles = [] },
  } = props;
  const dataSourceEnabled = nodeData?.dataSourceEnabled ?? false;
  const type = nodeData?.type ?? SemanticDataType.Lore;
  const query = nodeData?.query ?? "";
  const outputSchema = nodeData?.outputSchema ?? "{}";
  const npcId = nodeData?.npcId ?? "";
  const topicId = nodeData?.topicId ?? "_all";
  const loreCriteria = nodeData?.loreCriteria;

  const { register, handleSubmit, setValue, watch, control, getValues } = useForm<SemanticSearchData>({
    defaultValues: {
      dataSourceEnabled,
      type,
      query,
      npcId,
      topicId,
      loreCriteria,
      outputSchema,
    },
  });
  const { updateNodeData } = useUpdateNodeData(nodeId);
  const { updateNodeSourceHandles } = useUpdateNodeHandles(nodeId);
  const updateNodeInternals = useUpdateNodeInternals();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleUpdate = useCallback((data: SemanticSearchData) => {
    updateNodeData(data);
  }, []);

  const { getTargetNodeOutputSchema } = useNodeLookup();
  const inputSchema = getTargetNodeOutputSchema(targetHandles.map(({ handleId }) => handleId));

  const { currentWorkflowSuggestionMap, setInputSchema } = useAiCompletionSchemaContext();

  useEffect(() => {
    updateNodeData({
      ...nodeData,
      inputSchema,
    });

    setInputSchema(inputSchema ?? "{}");
  }, [inputSchema]);

  const getSemanticDataSchema = useCallback((data: JSONSchema7) => {
    return {
      type: "object",
      properties: {
        entries: {
          type: "array",
          items: {
            type: "object",
            properties: {
              id: {
                type: "string",
              },
              entry: {
                type: "string",
              },
              createdAt: {
                type: "string",
              },
              data,
            },
            require: ["id", "entry", "createdAt", "data"],
          },
        },
      },
    };
  }, []);

  useEffect(() => {
    switch (type) {
      case SemanticDataType.MemoryWithAccess:
        setValue("outputSchema", JSON.stringify(getSemanticDataSchema(accessedMemorySchema), null, 2));
        break;
      case SemanticDataType.MemoryWithNoAccess:
        setValue("outputSchema", JSON.stringify(getSemanticDataSchema(memoryEntrySchema), null, 2));
        break;
      case SemanticDataType.Lore:
        setValue("loreCriteria", undefined);
        setValue("outputSchema", JSON.stringify(getSemanticDataSchema(loreEntrySchema), null, 2));
        break;
      case SemanticDataType.LoreWithCriteria:
        setValue("outputSchema", JSON.stringify(getSemanticDataSchema(loreEntrySchema), null, 2));
        break;
      case SemanticDataType.ItemIntrinsic:
        setValue("outputSchema", JSON.stringify(getSemanticDataSchema(itemIntrinsicSchema), null, 2));
        break;
    }
  }, [type]);

  useEffect(() => {
    if (dataSourceEnabled) {
      if (sourceHandles.find(({ handleName }) => handleName === "data") == null) {
        updateNodeSourceHandles([
          ...sourceHandles,
          {
            label: "DATA",
            handleName: "data",
            handleType: "source",
            handleCategory: "data",
          },
        ]);
      }
    } else {
      updateNodeSourceHandles(sourceHandles.filter(({ handleName }) => handleName !== "data"));
    }

    updateNodeInternals(nodeId);
  }, [dataSourceEnabled]);

  const parseJson = useCallback((value: string) => {
    try {
      return JSON.parse(value);
    } catch (e) {
      return {};
    }
  }, []);

  return (
    <BaseNodeWithChildren {...props}>
      <FormControl
        className={"nodrag"}
        onSubmit={handleSubmit(handleUpdate)}
        onBlur={handleSubmit(handleUpdate)}
        onChange={handleSubmit(handleUpdate)}
      >
        <VStack spacing={2} alignItems={"flex-start"}>
          <FormControl>
            <HStack alignItems="baseline">
              <FormLabel>
                <Text casing={"uppercase"}>Output Data Port Enabled</Text>
              </FormLabel>
              <Checkbox {...register("dataSourceEnabled")} />
            </HStack>
          </FormControl>
          <Box w="100%">
            <FormLabel>
              <Text casing={"uppercase"}>Data type</Text>
            </FormLabel>
            <Select {...register("type")} color={"white"}>
              {typeOptions.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Select>
          </Box>
          {[SemanticDataType.MemoryWithAccess, SemanticDataType.MemoryWithNoAccess].includes(type) && (
            <Box w="100%">
              <NpcSelect control={control} color={color} />
            </Box>
          )}
          {type === SemanticDataType.LoreWithCriteria && (
            <>
              <Box w="100%">
                <FormLabel>Max num of lore entries</FormLabel>
                <NumberInput min={0} max={1000} defaultValue={20}>
                  <NumberInputField {...register("loreCriteria.maxNumOfLore", { valueAsNumber: true })} color={color} />
                </NumberInput>
              </Box>
              <Box w="100%">
                <FormLabel>
                  <Text casing={"uppercase"}>Relevance Thresholds</Text>
                </FormLabel>
                <Editor
                  height="15vh"
                  language="json"
                  theme="vs-dark"
                  value={JSON.stringify(
                    loreCriteria?.relevanceThresholds ?? {
                      "not relevant": { max: 0.4 },
                      "vaguely relevant": { min: 0.4, max: 0.47 },
                      "somehow relevant": { min: 0.47, max: 0.55 },
                      relevant: { min: 0.55, max: 0.75 },
                      "very relevant": { min: 0.75 },
                    }
                  )}
                  onChange={(value) =>
                    setValue("loreCriteria.relevanceThresholds", parseJson(value || "{}"), { shouldDirty: true })
                  }
                  options={{
                    lineNumbers: "off",
                    minimap: { enabled: false },
                    wordWrap: "on",
                  }}
                />
              </Box>
            </>
          )}
          {[SemanticDataType.Lore, SemanticDataType.LoreWithCriteria].includes(type) && (
            <Box w="100%">
              <LoreTopicSelect control={control} color={color} />
            </Box>
          )}
          <Box w="100%">
            <FormLabel>
              <Text casing={"uppercase"}>Query</Text>
            </FormLabel>
            <MustacheEditor
              suggestionMap={currentWorkflowSuggestionMap}
              onChange={(value) =>
                setValue("query", value || "", {
                  shouldDirty: true,
                })
              }
              value={watch("query") ?? query}
              hoverDataMap={{}}
              height="15vh"
            />
          </Box>
        </VStack>
        <Button onClick={onOpen} textTransform={"uppercase"} w={"100%"} mt={2}>
          Preview Output Schema
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent
            bg={"theme.dark.background"}
            borderColor="yellow.500"
            borderRadius={0}
            borderWidth={1}
            minW="md"
            maxW="container.md"
          >
            <ModalHeader>
              <Text color={color}>Output Schema Preview</Text>
            </ModalHeader>

            <ModalBody>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Output JSON Schema
                </Text>
              </FormLabel>
              <Editor
                height="50vh"
                language="json"
                theme="vs-dark"
                value={watch("outputSchema")}
                options={{ readOnly: true }}
              />
            </ModalBody>

            <ModalFooter gap={1}>
              <Button onClick={onClose} color={"white"} variant={"outline"}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </FormControl>
    </BaseNodeWithChildren>
  );
};

export default memo(SemanticSearchNode);
