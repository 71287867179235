import React, { useCallback, useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import { WorkflowWithId } from "../../models/api/workflow";

interface DeleteWorkflowModalProps {
  isOpen: boolean;
  onClose: () => void;
  workflow: WorkflowWithId;
  onDelete: (workflowId: string) => void;
}

const DeleteWorkflowModal: React.FC<DeleteWorkflowModalProps> = ({ isOpen, onClose, workflow, onDelete }) => {
  const leastDestructiveRef = useRef<HTMLButtonElement>(null);

  const handleDelete = useCallback(() => {
    onDelete(workflow.workflowId);
  }, [onDelete, workflow]);

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={leastDestructiveRef}>
      <AlertDialogOverlay />

      <AlertDialogContent bg={"gray.800"} borderWidth={2} borderColor={"indigo.600"}>
        <AlertDialogHeader bg={"indigo.600"}>
          <Heading size={"md"}>
            <Text color={"white"}>Delete Workflow</Text>
          </Heading>
        </AlertDialogHeader>

        <AlertDialogBody>
          <Stack gap={4}>
            <Heading size={"md"} mt={2}>
              <Text color={"red.800"}>{workflow.name}</Text>
            </Heading>

            <Text color={"white"}>Are you sure? This action cannot be undone!</Text>
          </Stack>
        </AlertDialogBody>

        <AlertDialogFooter gap={2}>
          <Button ref={leastDestructiveRef} onClick={onClose} variant={"outline"}>
            Cancel
          </Button>
          <Button onClick={handleDelete} variant={"outline"}>
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteWorkflowModal;
