import { useCallback } from "react";
import { authApi } from ".";

const useUsers = () => {
  const getDisplayName = useCallback(async (): Promise<string> => {
    try {
      const response = await authApi.getDisplayName();
      const { data: displayName } = response;

      return displayName;
    } catch (error) {
      console.error(error);
      return "";
    }
  }, []);

  return {
    getDisplayName,
  };
};

export default useUsers;
