import axios from "axios";
import buildClientApiUrl from "../utils/buildClientApiUrl";
import { AIApi } from "@worldwidewebb/client-ai";
import { AuthApi } from "@worldwidewebb/client-auth";
import { NpcsApi } from "@worldwidewebb/client-npcs";

const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    apikey: "d52fb3f1-104d-4fe3-9d18-2c49da197950",
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}) as any;

export const aiApi = new AIApi(undefined, buildClientApiUrl("ai"), axiosInstance);
export const authApi = new AuthApi(undefined, buildClientApiUrl("auth"), axiosInstance);
export const npcsApi = new NpcsApi(undefined, buildClientApiUrl("npcs"), axiosInstance);