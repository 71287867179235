import { ReactFlowProvider } from "reactflow";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { ConnectionProvider } from "../context/ConnectionContext";
import Sidebar, { SidebarItem } from "../components/navigation/Sidebar";
import { CgDebug, CgListTree } from "react-icons/cg";
import { Flex, Grid, GridItem, Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { MdHome } from "react-icons/md";
import { useCallback, useState } from "react";
import MultiProvider from "../context/MultiProvider";
import NodeSelector from "../components/navigation/elements/NodeSelector";
import NodeDebugger from "../components/navigation/elements/NodeDebugger";
import { AiWorkflowRunsProvider } from "../context/AiWorkflowRunsContext";
import { AiCompletionSchemaProvider } from "../context/AiCompletionSchemaContext";

export const ReactFlowLayout = () => {
  const { id: aiId = "" } = useParams();

  const navigate = useNavigate();

  const [isNavigating, setIsNavigating] = useState<boolean>(false);

  const handleNavigateToHome = useCallback(() => {
    setIsNavigating(true);

    navigate("/");
  }, [navigate]);

  const items: SidebarItem[] = [
    {
      icon: CgListTree,
      label: "node selection",
      content: <NodeSelector />,
    },
    {
      icon: CgDebug,
      label: "debug",
      content: <NodeDebugger />,
    },
  ];

  return (
    <MultiProvider
      providers={[
        <ReactFlowProvider />,
        <ConnectionProvider />,
        <AiWorkflowRunsProvider aiId={aiId} />,
        <AiCompletionSchemaProvider />,
      ]}
    >
      <Grid as={"main"} minH={"100vh"} templateColumns={"1fr"}>
        <GridItem display={"grid"}>
          <Outlet />
        </GridItem>

        <GridItem
          display={"flex"}
          flexDirection={"column"}
          bg={"theme.dark.background"}
          position={"fixed"}
          top={0}
          left={0}
          bottom={0}
        >
          <Flex bg={"indigo.600"}>
            <Tooltip label={"return home"} placement={"right"}>
              <IconButton
                color={"white"}
                icon={<Icon as={MdHome} />}
                aria-label={"return home"}
                variant={"ghost"}
                borderRadius={0}
                isLoading={isNavigating}
                onClick={handleNavigateToHome}
              />
            </Tooltip>
          </Flex>

          <Sidebar flexGrow={1} bg={"theme.dark.background"} color={"white"} items={items} />
        </GridItem>
      </Grid>
    </MultiProvider>
  );
};
