import { HandleType } from "reactflow";

export type HandleCategory = "start" | "end" | "flow" | "data" | "element";

export const getHandleCategoryColor = (handleCategory: HandleCategory) => {
  switch (handleCategory) {
    case "start": {
      return "red.800";
    }
    case "end": {
      return "red.800";
    }
    case "flow": {
      return "green.600";
    }
    case "data": {
      return "blue.600";
    }
    case "element": {
      return "yellow.600";
    }
    default: {
      return undefined;
    }
  }
};

export type NodeCategory = "Flow" | "Start/End" | "Logic" | "Data" | "Element";

export const nodeCategories: NodeCategory[] = ["Flow", "Start/End", "Logic", "Data", "Element"];

export interface HandleData {
  value: string;
}

export interface NodeHandle {
  label?: string;
  color?: string;
  handleName: string;
  handleId?: string;
  handleType: HandleType;
  handleCategory: HandleCategory;
  handleData?: HandleData;
  linkedNodeDataId?: string;
}

export interface TargetHandle extends NodeHandle {
  handleType: "target";
  handleCategory: "end" | "flow" | "data" | "element";
}

export interface SourceHandle extends NodeHandle {
  handleType: "source";
  handleCategory: "start" | "flow" | "data" | "element";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface NodeType<T = any> {
  label?: string;
  color?: string;
  nodeName: string;
  nodeDescription?: string;
  nodeCategory?: NodeCategory;
  nodeData?: T;
  nodeClass: string;
  nodeComponentName?: string;
  isOrigin?: boolean;
  isEditable?: boolean;
  targetHandles?: TargetHandle[];
  isTargetHandlesEditable?: boolean;
  ignoreTargetHandlesDiff?: boolean;
  sourceHandles?: SourceHandle[];
  isSourceHandlesEditable?: boolean;
  ignoreSourceHandlesDiff?: boolean;
  shouldValidate?: boolean;
  isReady?: boolean;
}
