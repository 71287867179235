import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Text } from "@chakra-ui/react";
import React from "react";
import { MdDangerous } from "react-icons/md";

export const CollapsibleError: React.FC<{ title: string; message: string; info: string }> = ({
  title,
  info,
  message,
}) => {
  return (
    <Accordion allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <MdDangerous color="red.300" />
            <Box as="span" mx={2} textAlign="left" color="white">
              {title}
            </Box>
            <Text fontWeight={"bold"} color="red.300" align={"left"}>
              {message}
            </Text>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <pre>{info}</pre>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
