import { ComponentType } from "react";
import { EdgeProps } from "reactflow";
import { StartEdge } from "./StartEdge";
import { EndEdge } from "./EndEdge";
import { FlowEdge } from "./FlowEdge";
import { DataEdge } from "./DataEdge";
import { ElementEdge } from "./ElementEdge";

export const edgeTypes: Record<string, ComponentType<EdgeProps>> = {
  StartEdge,
  EndEdge,
  FlowEdge,
  DataEdge,
  ElementEdge,
};

export const edgeTypeDictionary: Record<string, ComponentType<EdgeProps>> = Object.fromEntries(
  Object.entries(edgeTypes).map(([edgeTypeName, edgeType]) => [edgeTypeName, edgeType])
);
