import React, { useCallback, useRef, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { WorkflowWithId } from "../../models/api/workflow";

interface UpdateWorkflowModalProps {
  isOpen: boolean;
  onClose: () => void;
  workflow: WorkflowWithId;
  onUpdate: (workflow: WorkflowWithId) => void;
}

const UpdateWorkflowModal: React.FC<UpdateWorkflowModalProps> = ({ isOpen, onClose, workflow, onUpdate }) => {
  const initialFocusRef = useRef<HTMLInputElement>(null);

  const [name, setName] = useState<string>(workflow.name);
  const [description, setDescription] = useState<string>(workflow.description);
  const [priority, setPriority] = useState<number>(workflow.priority ?? 0);

  const handleUpdate = useCallback(() => {
    onUpdate({
      ...workflow,
      name,
      description,
      priority,
    });
  }, [onUpdate, workflow, name, description, priority]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialFocusRef}>
      <ModalOverlay />

      <ModalContent bg={"gray.800"} borderWidth={2} borderColor={"indigo.600"}>
        <ModalHeader bg={"indigo.600"}>
          <Heading size={"md"}>
            <Text color={"white"}>Update Workflow</Text>
          </Heading>
        </ModalHeader>

        <ModalBody>
          <Stack>
            <FormControl>
              <FormLabel>Workflow Name</FormLabel>
              <Input ref={initialFocusRef} value={name} onChange={({ target: { value } }) => setName(value)} />
            </FormControl>
            <FormControl>
              <FormLabel>Workflow Description</FormLabel>
              <Textarea value={description} onChange={({ target: { value } }) => setDescription(value)} />
            </FormControl>
            <FormControl>
              <FormLabel>Workflow Priority</FormLabel>
              <NumberInput
                defaultValue={0}
                step={1}
                min={0}
                value={priority}
                onChange={(value) => setPriority(Number(value))}
              >
                <NumberInputField color={"white"} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter gap={2}>
          <Button onClick={onClose} variant={"outline"}>
            Cancel
          </Button>
          <Button onClick={handleUpdate} variant={"outline"}>
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateWorkflowModal;
