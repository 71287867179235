import { useCallback, useState } from "react";
import { Edge, getConnectedEdges, Node } from "reactflow";
import { ulid } from "ulid";
import { NodeType } from "../models/nodeType";
import { createHandleId } from "../utils/handleId";

export const useCopyAndPaste = () => {
  const [bufferedNodes, setBufferedNodes] = useState<Node<NodeType>[]>([]);
  const [bufferedEdges, setBufferedEdges] = useState<Edge[]>([]);

  const [pasteCount, setPasteCount] = useState<number>(0);

  const copy = useCallback(
    (nodes: Node<NodeType>[], edges: Edge[]) => {
      const selectedNodes = nodes.filter(({ selected }) => selected);
      const selectedEdges = getConnectedEdges(selectedNodes, edges).filter(({ source, target }) => {
        const isExternalSource = selectedNodes.every(({ id }) => id !== source);
        const isExternalTarget = selectedNodes.every(({ id }) => id !== target);

        return !(isExternalSource || isExternalTarget);
      });

      setBufferedNodes(selectedNodes);
      setBufferedEdges(selectedEdges);

      setPasteCount(0);
    },
    [getConnectedEdges]
  );

  const paste = useCallback(
    (nodes?: Node<NodeType>[], edges?: Edge[]) => {
      const nodesToDuplicate = nodes ?? bufferedNodes;
      const edgesToDuplicate = edges ?? bufferedEdges;

      if (nodesToDuplicate.length === 0 && edgesToDuplicate.length === 0) {
        return {
          nodes: [],
          edges: [],
        };
      }

      const oldToNewNodeIds: Record<string, string> = {};
      const oldToNewNodeHandleIds: Record<string, string> = {};
      const pasteOffset: number = 100 * (pasteCount + 1);

      const newNodes = nodesToDuplicate.map((node) => {
        const oldNodeId = node.id;
        const newNodeId = ulid();

        oldToNewNodeIds[oldNodeId] = newNodeId;

        return {
          ...node,
          data: {
            ...node.data,
            sourceHandles: node.data.sourceHandles?.map((sourceHandle) => {
              if (sourceHandle.handleId == null) {
                return;
              }

              const newHandleId = createHandleId(sourceHandle);

              oldToNewNodeHandleIds[sourceHandle.handleId] = newHandleId;

              return {
                ...sourceHandle,
                handleId: newHandleId,
              };
            }),
            targetHandles: node.data.targetHandles?.map((targetHandle) => {
              if (targetHandle.handleId == null) {
                return;
              }

              const newHandleId = createHandleId(targetHandle);

              oldToNewNodeHandleIds[targetHandle.handleId] = newHandleId;

              return {
                ...targetHandle,
                handleId: newHandleId,
              };
            }),
          },
          id: newNodeId,
          selected: true,
          position: {
            x: node.position.x + pasteOffset,
            y: node.position.y + pasteOffset,
          },
        };
      });

      const newEdges = edgesToDuplicate.map((edge) => {
        const newSource = oldToNewNodeIds[edge.source];
        const newSourceHandle = edge.sourceHandle ? oldToNewNodeHandleIds[edge.sourceHandle] : undefined;
        const newTarget = oldToNewNodeIds[edge.target];
        const newTargetHandle = edge.targetHandle ? oldToNewNodeHandleIds[edge.targetHandle] : undefined;

        return {
          ...edge,
          id: ulid(),
          selected: true,
          source: newSource,
          sourceHandle: newSourceHandle,
          target: newTarget,
          targetHandle: newTargetHandle,
        };
      });

      setPasteCount((pasteCount) => pasteCount + 1);

      return {
        nodes: newNodes,
        edges: newEdges,
      };
    },
    [bufferedNodes, bufferedEdges, pasteCount]
  );

  const createDuplicates = useCallback(
    (nodes: Node<NodeType>[], edges: Edge[]) => {
      const { nodes: duplicatedNodes, edges: duplicatedEdges } = paste(nodes, edges);

      return {
        nodes: duplicatedNodes.map((node) => ({ ...node, selected: false })),
        edges: duplicatedEdges.map((edge) => ({ ...edge, selected: false })),
      };
    },
    [paste]
  );

  return {
    copy,
    paste,
    createDuplicates,
  };
};
