import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { CopyFromPreviousRunsSelect, useAiWorkflowRunsContext } from "../../context/AiWorkflowRunsContext";
import { Editor } from "@monaco-editor/react";
import { Select } from "chakra-react-select";
import { useMutation, useQuery } from "@tanstack/react-query";
import { aiApi } from "../../api";
import { useLoaderData } from "react-router";
import { WorkflowWithId } from "../../models/api/workflow";
import { AiRunTemplateWithId, AiRunTemplate } from "@worldwidewebb/client-ai";
import { MdClose, MdDangerous, MdDelete, MdEdit, MdPlayArrow, MdRestore, MdSave } from "react-icons/md";
import { ulid } from "ulid";
import { CollapsibleError } from "./CollapsibleError";

interface CustomRunModalModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CustomRunModal: React.FC<CustomRunModalModalProps> = ({ isOpen, onClose }) => {
  const { workflow } = useLoaderData() as { workflow: WorkflowWithId };
  const workflowName = workflow.name;
  const defaultEmptyContext = JSON.stringify(
    {
      workflowName,
      shardId: "ai-editor",
      currentRoom: "y2kDemoStreet",
      contextId: "01H8C7R185FN3BZ4KA28PBRR3V",
      npcId: "01H8C7R185FN3BZ4KA28PBRR3V",
      internalState: {
        currentStatus: "idle",
        currentActivity: "idle",
        attributes: [],
      },
      areaOfInterest: [],
      currentObservations: [],
      data: {
        recentChat: [],
      },
      actors: [],
    },
    null,
    2
  );

  const { workflowRunQuery, executeWorkflowMutation } = useAiWorkflowRunsContext();
  const [context, setContext] = useState<string>(defaultEmptyContext);
  const [selectedRunTemplate, setSelectedRunTemplate] = useState<AiRunTemplateWithId | undefined>();
  const [newTemplateName, setNewTemplateName] = useState<string>("");
  const {
    isOpen: isDeleteConfirmationOpen,
    onOpen: onDeleteConfirmationOpen,
    onClose: onDeleteConfirmationClose,
  } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const getRunTemplatesQuery = useQuery({
    queryKey: ["runTemplates", workflow],
    queryFn: () => aiApi.getAiRunTemplates(workflowName),
  });

  const withRefresh = useCallback(async <T,>(operation: () => Promise<T>) => {
    const result = await operation();
    getRunTemplatesQuery.refetch();
    return result;
  }, []);

  const createRunTemplateQueryMutation = useMutation({
    mutationFn: (runTemplate: AiRunTemplate) => withRefresh(() => aiApi.createAiRunTemplate(workflowName, runTemplate)),
  });

  const updateRunTemplateQueryMutation = useMutation({
    mutationFn: (runTemplate: AiRunTemplateWithId) =>
      withRefresh(() => aiApi.updateAiRunTemplate(workflowName, runTemplate.templateId, runTemplate.context)),
  });

  const deleteRunTemplateQueryMutation = useMutation({
    mutationFn: (runTemplate: AiRunTemplateWithId) =>
      withRefresh(() => aiApi.deleteAiRunTemplate(workflowName, runTemplate.templateId)),
  });

  const handleSetContext = useCallback(
    (value: string) => {
      setContext(value);
      if (createRunTemplateQueryMutation.isError) {
        createRunTemplateQueryMutation.reset();
      }

      if (updateRunTemplateQueryMutation.isError) {
        updateRunTemplateQueryMutation.reset();
      }
    },
    [createRunTemplateQueryMutation.isError, updateRunTemplateQueryMutation.isError]
  );

  const resetToDefaultEmptyContext = useCallback(() => {
    setNewTemplateName("");
    setSelectedRunTemplate(undefined);
    workflowRunQuery.setWorkflowRunId(undefined);
    setContext(defaultEmptyContext);
  }, []);

  useEffect(() => {
    if (!workflowRunQuery.data) {
      return;
    }
    handleSetContext(JSON.stringify(workflowRunQuery.data?.context || {}, null, 2));
  }, [workflowRunQuery.data]);

  useEffect(() => {
    const foundTemplate = getRunTemplatesQuery.data?.data.find((t) => t.templateName === newTemplateName);

    if (
      createRunTemplateQueryMutation.isSuccess &&
      newTemplateName !== "" &&
      newTemplateName === foundTemplate?.templateName
    ) {
      setNewTemplateName("");
      setSelectedRunTemplate(foundTemplate);
      workflowRunQuery.setWorkflowRunId(undefined);
    }
  }, [createRunTemplateQueryMutation.isSuccess, getRunTemplatesQuery.data, newTemplateName]);

  useEffect(() => {
    onDeleteConfirmationClose();
    deleteRunTemplateQueryMutation.reset();
    resetToDefaultEmptyContext();
  }, [deleteRunTemplateQueryMutation.isSuccess]);

  const runTemplateOptions = (getRunTemplatesQuery.data?.data || []).map((template) => ({
    label: template.templateName,
    value: template,
  }));

  const updateTemplateError = updateRunTemplateQueryMutation.error || createRunTemplateQueryMutation.error;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
      <ModalOverlay />
      <ModalContent bg={"theme.dark.background"} borderColor={"purple.600"} borderRadius={0} borderWidth={1}>
        <ModalHeader>
          <Text color={"purple.600"}>Custom Run</Text>
        </ModalHeader>
        <ModalBody>
          <Stack>
            <HStack justifyContent="space-between" pb={2}>
              <Box w="50%">
                <FormLabel>
                  <Text casing={"uppercase"}>Copy from previous runs</Text>
                </FormLabel>
                <CopyFromPreviousRunsSelect onChange={() => setSelectedRunTemplate(undefined)} />
              </Box>
              <Box w="50%">
                <FormLabel>
                  <Text casing={"uppercase"}>Copy from template</Text>
                </FormLabel>
                <Select
                  onChange={(option) => {
                    if (!option) {
                      return;
                    }
                    workflowRunQuery.setWorkflowRunId(undefined);
                    setSelectedRunTemplate(option.value);
                    handleSetContext(JSON.stringify(option.value?.context || {}, null, 2));
                  }}
                  isLoading={getRunTemplatesQuery.isLoading}
                  isDisabled={getRunTemplatesQuery.isLoading}
                  options={runTemplateOptions}
                  value={
                    selectedRunTemplate
                      ? {
                          label: selectedRunTemplate.templateName,
                          value: selectedRunTemplate,
                        }
                      : {
                          label: "Select template",
                          value: undefined,
                        }
                  }
                  onMenuOpen={() => getRunTemplatesQuery.refetch()}
                />
              </Box>
            </HStack>
            <HStack justifyContent={"flex-end"}>
              <Button onClick={resetToDefaultEmptyContext}>
                <MdRestore color="white" />
                &nbsp; Reset to Default Empty Context
              </Button>
            </HStack>
            <Box w="100%" pb={2}>
              <FormLabel>
                <Text casing={"uppercase"}>Context</Text>
                {updateTemplateError && (
                  <>
                    <CollapsibleError
                      title="Error saving the template:"
                      message={updateTemplateError.message}
                      info={JSON.stringify(updateTemplateError.response?.data, null, 2)}
                    />
                  </>
                )}
              </FormLabel>
              <Editor
                height="30vh"
                language="json"
                theme="vs-dark"
                value={context}
                onChange={(value) => handleSetContext(value ?? "")}
                options={{
                  lineNumbers: "off",
                  minimap: { enabled: false },
                  wordWrap: "on",
                }}
              />
            </Box>
            <HStack w="100%" justifyContent="flex-end">
              {!selectedRunTemplate && (
                <>
                  <Box>
                    <Input
                      value={newTemplateName}
                      onChange={(e) => setNewTemplateName(e.target.value)}
                      placeholder={"Template name"}
                    />
                  </Box>
                  <Box>
                    <Button
                      aria-label="new-template"
                      mr={2}
                      onClick={() =>
                        createRunTemplateQueryMutation.mutate({
                          context: JSON.parse(context),
                          templateName: newTemplateName,
                          workflowName,
                        })
                      }
                      disabled={createRunTemplateQueryMutation.isPending}
                    >
                      {createRunTemplateQueryMutation.isPending ? (
                        <Spinner size="sm" color="white" />
                      ) : (
                        <MdSave color="white" />
                      )}
                      <Text ml={2} color="green.300">
                        Save as new template
                      </Text>
                    </Button>
                  </Box>
                </>
              )}
              {selectedRunTemplate && (
                <>
                  <Button
                    mr={2}
                    onClick={() =>
                      updateRunTemplateQueryMutation.mutate({
                        ...selectedRunTemplate,
                        context: JSON.parse(context),
                      })
                    }
                    disabled={updateRunTemplateQueryMutation.isPending}
                  >
                    {updateRunTemplateQueryMutation.isPending ? (
                      <Spinner size="sm" color="white" />
                    ) : (
                      <MdEdit color="white" />
                    )}
                    <Text ml={2} color="yellow.300">
                      Update "{selectedRunTemplate.templateName}" template
                    </Text>
                  </Button>
                  <Button mr={2} onClick={onDeleteConfirmationOpen}>
                    <MdDelete color="white" />
                    <Text ml={2} color="red.300">
                      Delete "{selectedRunTemplate.templateName}" template
                    </Text>
                  </Button>
                  <AlertDialog
                    isOpen={isDeleteConfirmationOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onDeleteConfirmationClose}
                  >
                    <AlertDialogOverlay>
                      <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                          Delete "{selectedRunTemplate.templateName}" Template
                        </AlertDialogHeader>

                        <AlertDialogBody>Are you sure? You can't undo this action afterwards.</AlertDialogBody>

                        <AlertDialogFooter>
                          <Button ref={cancelRef} onClick={onDeleteConfirmationClose}>
                            Cancel
                          </Button>
                          <Button
                            colorScheme="red"
                            onClick={() => deleteRunTemplateQueryMutation.mutate(selectedRunTemplate)}
                            ml={3}
                          >
                            {deleteRunTemplateQueryMutation.isPending && <Spinner size="sm" color="white" />}
                            Delete
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialog>
                </>
              )}
            </HStack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            mr={2}
            disabled={executeWorkflowMutation.isPending}
            onClick={() => {
              executeWorkflowMutation.mutate({
                runId: ulid(),
                context: JSON.parse(context),
              });
              onClose();
            }}
          >
            <MdPlayArrow color="white" />
            <Text ml={2} color="green.300">
              Run with current context
            </Text>
          </Button>
          <Button onClick={onClose} color={"white"}>
            <MdClose color="white" />
            <Text ml={2}>Close</Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
