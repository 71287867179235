import { useCallback } from "react";
import { NodeType } from "../models/nodeType";

const useNodeTypeNameLookup = () => {
  const getLatestType = useCallback((nodeType: NodeType) => {
    return nodeType.nodeComponentName ?? "BaseNode";
  }, []);

  return {
    getLatestType,
  };
};

export default useNodeTypeNameLookup;
