import React, { useCallback, useEffect, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { MdAdd, MdDelete } from "react-icons/md";
import { HandleCategory, SourceHandle, TargetHandle } from "../../../../models/nodeType";

interface InputErrorProps {
  errorMessage?: string;
}

const InputError: React.FC<InputErrorProps> = ({ errorMessage }) => {
  return <Text color={"red.800"}>{errorMessage}</Text>;
};

interface HandleForm {
  targetHandles: TargetHandle[];
  sourceHandles: SourceHandle[];
}

interface BaseNodeConfigModalProps {
  isOpen: boolean;
  onClose: () => void;
  targetHandles?: TargetHandle[];
  sourceHandles?: SourceHandle[];
  onUpdateTargetHandles: (targetHandles: TargetHandle[]) => void;
  onUpdateSourceHandles: (sourceHandles: SourceHandle[]) => void;
  color?: string;
}

export const BaseNodeConfigModal: React.FC<BaseNodeConfigModalProps> = ({
  isOpen,
  onClose,
  targetHandles,
  sourceHandles,
  onUpdateTargetHandles,
  onUpdateSourceHandles,
  color,
}) => {
  const {
    reset,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<HandleForm>({
    defaultValues: useMemo(
      () => ({
        targetHandles: structuredClone(targetHandles),
        sourceHandles: structuredClone(sourceHandles),
      }),
      [targetHandles, sourceHandles]
    ),
    mode: "onBlur",
  });

  useEffect(() => {
    reset({
      targetHandles,
      sourceHandles,
    });
  }, [targetHandles, sourceHandles]);

  const {
    fields: targetHandleFields,
    insert: insertTargetHandle,
    remove: removeTargetHandle,
    move: moveTargetHandle,
  } = useFieldArray({
    name: "targetHandles",
    control,
  });

  const {
    fields: sourceHandleFields,
    insert: insertSourceHandle,
    remove: removeSourceHandle,
    move: moveSourceHandle,
  } = useFieldArray({
    name: "sourceHandles",
    control,
  });

  const handleUpdate = useCallback(
    ({ targetHandles, sourceHandles }: HandleForm) => {
      onUpdateTargetHandles(targetHandles);
      onUpdateSourceHandles(sourceHandles);

      onClose();
    },
    [onClose, onUpdateTargetHandles, onUpdateSourceHandles]
  );

  const handleInsertTargetHandle = useCallback(
    (index: number) => {
      insertTargetHandle(index + 1, {
        label: "",
        color: "",
        handleName: "",
        handleType: "target",
        handleCategory: "flow",
      });
    },
    [insertTargetHandle]
  );

  const handleRemoveTargetHandle = useCallback(
    (index: number) => {
      removeTargetHandle(index);
    },
    [removeTargetHandle]
  );

  const handleMoveTargetHandle = useCallback(
    (oldIndex: number, newIndex?: string) => {
      moveTargetHandle(oldIndex, isFinite(Number(newIndex)) ? Number(newIndex) : oldIndex);
    },
    [moveTargetHandle]
  );

  const handleInsertSourceHandle = useCallback(
    (index: number) => {
      insertSourceHandle(index + 1, {
        label: "",
        color: "",
        handleName: "",
        handleType: "source",
        handleCategory: "flow",
      });
    },
    [insertSourceHandle]
  );

  const handleRemoveSourceHandle = useCallback(
    (index: number) => {
      removeSourceHandle(index);
    },
    [removeSourceHandle]
  );

  const handleMoveSourceHandle = useCallback(
    (oldIndex: number, newIndex?: string) => {
      moveSourceHandle(oldIndex, isFinite(Number(newIndex)) ? Number(newIndex) : oldIndex);
    },
    [moveSourceHandle]
  );

  const handleCancel = useCallback(() => {
    reset({
      targetHandles,
      sourceHandles,
    });

    onClose();
  }, [onClose, reset, targetHandles, sourceHandles]);

  const handleCategories: HandleCategory[] = ["start", "end", "flow"];

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
      <ModalOverlay />
      <ModalContent bg={"theme.dark.background"} borderColor={color} borderRadius={0} borderWidth={1}>
        <form onSubmit={handleSubmit(handleUpdate)}>
          <ModalHeader>
            <Text color={color}>Configuration</Text>
          </ModalHeader>

          <ModalBody>
            <Tabs variant={"enclosed"}>
              <TabList>
                <Tab>
                  <Text size={"md"} color={"white"}>
                    Inputs
                  </Text>
                </Tab>
                <Tab>
                  <Text size={"md"} color={"white"}>
                    Outputs
                  </Text>
                </Tab>
                <Tab>
                  <Text size={"md"} color={"white"}>
                    Hooks
                  </Text>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  {targetHandleFields.map(({ id }, index) => {
                    let fieldErrors = undefined;

                    if (errors?.targetHandles?.at) {
                      fieldErrors = errors.targetHandles.at(index);
                    }

                    return (
                      <Flex key={id}>
                        <FormControl>
                          <FormLabel>
                            {index === 0 && <Text color={color}>Label</Text>}
                            <Input
                              id={`targetHandles.${index}.label`}
                              isRequired
                              {...register(`targetHandles.${index}.label`, {
                                required: "This is required",
                                minLength: { value: 2, message: "Minimum length should be 2" },
                              })}
                            />
                            {fieldErrors?.label && <InputError errorMessage={fieldErrors?.label.message} />}
                          </FormLabel>
                        </FormControl>
                        <FormControl>
                          <FormLabel>
                            {index === 0 && <Text color={color}>Name</Text>}
                            <Input
                              id={`targetHandles.${index}.handleName`}
                              isRequired
                              {...register(`targetHandles.${index}.handleName`, {
                                required: "This is required",
                                minLength: { value: 2, message: "Minimum length should be 2" },
                              })}
                            />
                            {fieldErrors?.handleName && <InputError errorMessage={fieldErrors?.handleName.message} />}
                          </FormLabel>
                        </FormControl>
                        <FormControl>
                          <FormLabel>
                            {index === 0 && <Text color={color}>Category</Text>}
                            <Select
                              id={`targetHandles.${index}.handleCategory`}
                              isRequired
                              {...register(`targetHandles.${index}.handleCategory`, {
                                required: "This is required",
                              })}
                            >
                              {handleCategories.map((handleCategory, key) => (
                                <option key={key} value={handleCategory}>
                                  {handleCategory}
                                </option>
                              ))}
                            </Select>
                            {fieldErrors?.handleCategory && (
                              <InputError errorMessage={fieldErrors?.handleCategory.message} />
                            )}
                          </FormLabel>
                        </FormControl>

                        <FormControl>
                          <FormLabel>
                            {index === 0 && <Text color={color}>Index</Text>}
                            <Select
                              value={index}
                              onChange={({ target: { value } }) => handleMoveTargetHandle(index, value)}
                            >
                              {[...Array(targetHandleFields.length)].map((_, index) => (
                                <option key={index} value={index}>
                                  {index}
                                </option>
                              ))}
                            </Select>
                          </FormLabel>
                        </FormControl>

                        <FormControl w={"min"}>
                          <FormLabel>
                            {index === 0 && <Text color={color}>&nbsp;</Text>}
                            <Flex gap={2}>
                              <IconButton
                                size={"md"}
                                color={"red.800"}
                                icon={<Icon as={MdDelete} />}
                                aria-label={"remove handle"}
                                onClick={() => handleRemoveTargetHandle(index)}
                              />
                              <IconButton
                                size={"md"}
                                color={"green.600"}
                                icon={<Icon as={MdAdd} />}
                                aria-label={"insert handle"}
                                onClick={() => handleInsertTargetHandle(index)}
                              />
                            </Flex>
                          </FormLabel>
                        </FormControl>
                      </Flex>
                    );
                  })}

                  <Flex justifyContent={"flex-end"}>
                    <FormControl w={"min"}>
                      <FormLabel>
                        <IconButton
                          size={"md"}
                          color={"green.600"}
                          icon={<Icon as={MdAdd} />}
                          aria-label={"insert handle"}
                          onClick={() => handleInsertTargetHandle(targetHandleFields.length)}
                        />
                      </FormLabel>
                    </FormControl>
                  </Flex>
                </TabPanel>
                <TabPanel>
                  {sourceHandleFields.map(({ id }, index) => {
                    let fieldErrors = undefined;

                    if (errors?.sourceHandles?.at) {
                      fieldErrors = errors.sourceHandles.at(index);
                    }

                    return (
                      <Flex key={id}>
                        <FormControl>
                          <FormLabel>
                            {index === 0 && <Text color={color}>Label</Text>}
                            <Input
                              id={`sourceHandles.${index}.label`}
                              isRequired
                              {...register(`sourceHandles.${index}.label`, {
                                required: "This is required",
                                minLength: { value: 2, message: "Minimum length should be 2" },
                              })}
                            />
                            {fieldErrors?.label && <InputError errorMessage={fieldErrors?.label.message} />}
                          </FormLabel>
                        </FormControl>
                        <FormControl>
                          <FormLabel>
                            {index === 0 && <Text color={color}>Name</Text>}
                            <Input
                              id={`sourceHandles.${index}.handleName`}
                              isRequired
                              {...register(`sourceHandles.${index}.handleName`, {
                                required: "This is required",
                                minLength: { value: 2, message: "Minimum length should be 2" },
                              })}
                            />
                            {fieldErrors?.handleName && <InputError errorMessage={fieldErrors?.handleName.message} />}
                          </FormLabel>
                        </FormControl>
                        <FormControl>
                          <FormLabel>
                            {index === 0 && <Text color={color}>Category</Text>}
                            <Select
                              id={`sourceHandles.${index}.handleCategory`}
                              isRequired
                              {...register(`sourceHandles.${index}.handleCategory`, {
                                required: "This is required",
                              })}
                            >
                              {handleCategories.map((handleCategory, key) => (
                                <option key={key} value={handleCategory}>
                                  {handleCategory}
                                </option>
                              ))}
                            </Select>
                            {fieldErrors?.handleCategory && (
                              <InputError errorMessage={fieldErrors?.handleCategory.message} />
                            )}
                          </FormLabel>
                        </FormControl>

                        <FormControl>
                          <FormLabel>
                            {index === 0 && <Text color={color}>Index</Text>}
                            <Select
                              value={index}
                              onChange={({ target: { value } }) => handleMoveSourceHandle(index, value)}
                            >
                              {[...Array(sourceHandleFields.length)].map((_, index) => (
                                <option key={index} value={index}>
                                  {index}
                                </option>
                              ))}
                            </Select>
                          </FormLabel>
                        </FormControl>

                        <FormControl w={"min"}>
                          <FormLabel>
                            {index === 0 && <Text color={color}>&nbsp;</Text>}
                            <Flex gap={2}>
                              <IconButton
                                size={"md"}
                                color={"red.800"}
                                icon={<Icon as={MdDelete} />}
                                aria-label={"remove handle"}
                                onClick={() => handleRemoveSourceHandle(index)}
                              />
                              <IconButton
                                size={"md"}
                                color={"green.600"}
                                icon={<Icon as={MdAdd} />}
                                aria-label={"insert handle"}
                                onClick={() => handleInsertSourceHandle(index)}
                              />
                            </Flex>
                          </FormLabel>
                        </FormControl>
                      </Flex>
                    );
                  })}

                  <Flex justifyContent={"flex-end"}>
                    <FormControl w={"min"}>
                      <FormLabel>
                        <IconButton
                          size={"md"}
                          color={"green.600"}
                          icon={<Icon as={MdAdd} />}
                          aria-label={"insert handle"}
                          onClick={() => handleInsertSourceHandle(sourceHandleFields.length)}
                        />
                      </FormLabel>
                    </FormControl>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter gap={1}>
            <Button onClick={handleCancel} color={"white"} variant={"outline"}>
              Cancel
            </Button>
            <Button color={color} type={"submit"} variant={"outline"}>
              Update
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
