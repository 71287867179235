import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { AiNpcWorkflowContext, AiWorkflowRunListResult, WorkflowExecutionMetadata } from "@worldwidewebb/client-ai";
import { AxiosError } from "axios";
import { Select } from "chakra-react-select";
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { decodeTime } from "ulid";
import useAi, { AiEditorWorkflowRun } from "../api/useAi";

const REFRESH_INTERVAL_MS = 10_000;

export interface WorkflowMutationOptions {
  context: AiNpcWorkflowContext;
  runId: string;
}

export interface DebuggedNode {
  nodeId: string;
  hasFailed: boolean;
}

interface ContextType {
  workflowRunsQuery: {
    isLoading: boolean;
    error: Error | null;
    loadNext: (limit?: number) => void;
    data: AiWorkflowRunListResult[];
  };
  lastWorkflowRun?: AiEditorWorkflowRun;
  executeWorkflowMutation: UseMutationResult<WorkflowExecutionMetadata, AxiosError, WorkflowMutationOptions>;
  workflowRunQuery: {
    setWorkflowRunId: (runId: string | undefined) => void;
    workflowRunId?: string;
  } & UseQueryResult<AiEditorWorkflowRun, Error>;
  debuggedNode?: DebuggedNode;
  setDebbugedNode: (nodeId: DebuggedNode | undefined) => void;
}

const Context = createContext<ContextType | null>(null);

interface AiWorkflowRunsProviderProps extends PropsWithChildren {
  aiId: string;
  limit?: number;
}

export const CopyFromPreviousRunsSelect: React.FC<{ onChange?: (value?: string) => void }> = ({ onChange }) => {
  const {
    workflowRunsQuery: workflowRunIdsQuery,
    workflowRunQuery: { setWorkflowRunId, workflowRunId },
  } = useAiWorkflowRunsContext();

  const [isWorkflowRunIdsSelectOpen, setIsWorkflowRunIdsSelectOpen] = useState(false);

  const getRunIdLabel = (runId: string) => `${new Date(decodeTime(runId)).toISOString()}: ${runId}`;

  const workflowRunIdOptions = workflowRunIdsQuery.data.map(({ workflowRunId }) => ({
    label: getRunIdLabel(workflowRunId),
    value: workflowRunId,
  }));

  return (
    <Select
      onChange={(option) => {
        if (!option) {
          return;
        }
        setIsWorkflowRunIdsSelectOpen(false);
        setWorkflowRunId(option.value);
        if (onChange) {
          onChange(option.value);
        }
      }}
      isLoading={workflowRunIdsQuery.isLoading}
      isDisabled={workflowRunIdsQuery.isLoading}
      options={workflowRunIdOptions}
      value={
        workflowRunId
          ? {
              label: getRunIdLabel(workflowRunId),
              value: workflowRunId,
            }
          : {
              label: "Select a run",
              value: undefined,
            }
      }
      captureMenuScroll={true}
      onMenuScrollToBottom={() => {
        setIsWorkflowRunIdsSelectOpen(true);
        workflowRunIdsQuery.loadNext();
      }}
      menuIsOpen={isWorkflowRunIdsSelectOpen}
      onMenuOpen={() => setIsWorkflowRunIdsSelectOpen(true)}
      onMenuClose={() => !workflowRunIdsQuery.isLoading && setIsWorkflowRunIdsSelectOpen(false)}
    />
  );
};

const AiWorkflowRunsProvider: React.FC<AiWorkflowRunsProviderProps> = ({ aiId, children, limit = 20 }) => {
  const { getWorkflowRuns, getWorkflowRun, executeWorkflow } = useAi();
  const [debuggedNode, setDebbugedNode] = useState<DebuggedNode | undefined>(undefined);
  const [runId, setRunId] = useState<string | undefined>(undefined);
  const [offset, setOffset] = useState<number>(0);
  const [runs, setRuns] = useState<AiWorkflowRunListResult[]>([]);

  const getLastWorkflowRunsQuery = useQuery({
    queryKey: ["lastWorkflowRuns", aiId],
    queryFn: () => getWorkflowRuns(aiId, 0, limit),
    enabled: Boolean(aiId) && aiId.length > 0 && offset === 0,
  });

  const getWorkflowRunsQuery = useQuery({
    queryKey: ["workflowRuns", offset],
    queryFn: () => getWorkflowRuns(aiId, limit, limit + offset),
    enabled: Boolean(aiId) && aiId.length > 0 && offset > 0,
  });

  const lastWorkflowRunId = getLastWorkflowRunsQuery.data?.[0]?.workflowRunId;

  const getLastWorkflowRunQuery = useQuery({
    queryKey: ["workflowRun"],
    queryFn: () => getWorkflowRun(lastWorkflowRunId as string),
    enabled: lastWorkflowRunId !== undefined,
  });

  const lastWorkflowRun = lastWorkflowRunId ? getLastWorkflowRunQuery.data : undefined;

  const getWorkflowRunQuery = useQuery({
    queryKey: ["workflowRun", runId],
    queryFn: () => getWorkflowRun(runId as string),
    enabled: runId !== undefined,
  });

  useEffect(() => {
    const id = setInterval(async () => {
      await getLastWorkflowRunsQuery.refetch();
    }, REFRESH_INTERVAL_MS);

    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    if (getWorkflowRunsQuery.status === "success" && lastWorkflowRunId !== undefined) {
      getLastWorkflowRunQuery.refetch();
    }
  }, [getWorkflowRunsQuery.status, lastWorkflowRunId]);

  useEffect(() => {
    if (!getLastWorkflowRunsQuery.data || getLastWorkflowRunsQuery.isLoading || getWorkflowRunsQuery.isLoading) {
      return;
    }

    const newRuns = [];
    if (getLastWorkflowRunsQuery.data) {
      newRuns.push(...getLastWorkflowRunsQuery.data);
    }

    if (getWorkflowRunsQuery.data) {
      newRuns.push(...getWorkflowRunsQuery.data);
    }

    setRuns(newRuns);
  }, [getLastWorkflowRunsQuery.data, getWorkflowRunsQuery.data]);

  const executeWorkflowMutation = useMutation<WorkflowExecutionMetadata, AxiosError, WorkflowMutationOptions>({
    mutationFn: async ({ context }: WorkflowMutationOptions) => {
      const { data } = await executeWorkflow(context);

      // requires refetch after a second due to mongo write delay
      setTimeout(() => getLastWorkflowRunsQuery.refetch(), 1000);

      return data;
    },
  });

  const loadNext = useCallback((idsToLoad = limit) => {
    setOffset((prev) => {
      return prev + idsToLoad;
    });
  }, []);

  return (
    <Context.Provider
      value={{
        workflowRunsQuery: {
          ...getWorkflowRunsQuery,
          isLoading: getLastWorkflowRunsQuery.isLoading || getWorkflowRunsQuery.isLoading,
          data: runs,
          loadNext,
        },
        lastWorkflowRun,
        debuggedNode,
        setDebbugedNode,
        executeWorkflowMutation,
        workflowRunQuery: {
          ...getWorkflowRunQuery,
          setWorkflowRunId: setRunId,
          workflowRunId: runId,
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useAiWorkflowRunsContext = () => {
  const context = useContext(Context);

  if (context == null) {
    throw new Error("out of context");
  }

  return context;
};

export { AiWorkflowRunsProvider, useAiWorkflowRunsContext };
