import { useState } from "react";

const Collapsible: React.FC<{
  renderHeader: (opts: { isExpanded: boolean; toggle: () => void }) => React.ReactElement;
  body: React.ReactElement;
  defaultExpanded?: boolean;
}> = ({ renderHeader, body, defaultExpanded = false }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpanded);

  return (
    <>
      {renderHeader({ isExpanded, toggle: () => setIsExpanded(!isExpanded) })}
      {isExpanded && body}
    </>
  );
};

export default Collapsible;
