import React, { memo, useCallback, useEffect, useState } from "react";
import { NodeProps, useEdges, useNodes, useReactFlow, useUpdateNodeInternals } from "reactflow";
import { NodeType, TargetHandle } from "../../../models/nodeType";
import { SchemaData } from "./SchemaPassThroughNodeWithChildren";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  UnorderedList,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { BaseNodeWithChildren } from "./base/BaseNode";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import Editor, { useMonaco } from "@monaco-editor/react";
import { schema2ts } from "@puffmeow/schema2ts";
import { JSONSchemaFaker } from "json-schema-faker";
import toJsonSchema from "to-json-schema";
import { JSONSchema7, JSONSchema7Items } from "@worldwidewebb/client-ai";

export interface TransformDataNodeData extends SchemaData {
  dataInputCount: number;
  flowInputCount: number;
  hasDataOutput: boolean;
  hasFlowOutput: boolean;
  transformationCode: string;
}

interface JsonSchemaDefinitions {
  ins: JSONSchema7[];
  data: JSONSchema7[];
}

const TransformDataNode: React.FC<NodeProps<NodeType<TransformDataNodeData>>> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editorTypeDefinition, setEditorTypeDefinition] = useState("");
  const [jsonSchemas, setJsonSchemas] = useState<JsonSchemaDefinitions>({
    ins: [],
    data: [],
  });
  const { getNode } = useReactFlow();
  const monaco = useMonaco();
  const edges = useEdges();
  const nodes = useNodes();

  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const sourceHandles = props.data.sourceHandles ?? [];
  const targetHandles = props.data.targetHandles ?? [];

  const dataInputCount = nodeData?.dataInputCount ?? 0;
  const flowInputCount = nodeData?.flowInputCount ?? 1;
  const hasDataOutput = nodeData?.hasDataOutput ?? false;
  const hasFlowOutput = nodeData?.hasFlowOutput ?? true;
  const transformationCode = nodeData?.transformationCode ?? "";
  const outputSchema = nodeData?.outputSchema ?? "";

  const minFlowInputCount = dataInputCount > 0 ? 0 : 1;
  const minDataInputCount = flowInputCount > 0 ? 0 : 1;

  const { register, handleSubmit, formState, setValue, watch } = useForm<TransformDataNodeData>({
    defaultValues: {
      dataInputCount,
      flowInputCount,
      hasDataOutput,
      hasFlowOutput,
      outputSchema,
    },
    mode: "onChange",
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);
  const { updateNodeTargetHandles, updateNodeSourceHandles } = useUpdateNodeHandles(nodeId);
  const updateNodeInternals = useUpdateNodeInternals();

  const handleUpdate = useCallback((data: TransformDataNodeData) => {
    updateNodeData({
      ...data,
    });
  }, []);

  useEffect(() => {
    if (monaco && editorTypeDefinition !== "") {
      monaco.languages.typescript.javascriptDefaults.setExtraLibs([{ content: editorTypeDefinition }]);
    }
  }, [monaco, editorTypeDefinition]);

  useEffect(() => {
    const targetHandleIdToSourceNodeData = new Map<string, NodeType>();
    const newJsonSchemas: JsonSchemaDefinitions = {
      ins: [],
      data: [],
    };
    const inInterfaces = [] as string[];
    const dataInterfaces = [] as string[];
    let newEditorTypeDefinition = "";

    for (const edge of edges) {
      if (edge.targetHandle) {
        const sourceNode = getNode(edge.source);
        targetHandleIdToSourceNodeData.set(edge.targetHandle, sourceNode?.data);
      }
    }

    for (const targetHandle of targetHandles) {
      if (!targetHandle.handleId) {
        continue;
      }

      const node = targetHandleIdToSourceNodeData.get(targetHandle.handleId);

      if (node?.nodeData?.outputSchema) {
        const interfaceName = `Schema${targetHandle.handleId.split(":")[2]}`;

        if (targetHandle.handleCategory === "data") {
          dataInterfaces.push(interfaceName);
          newJsonSchemas.data.push(JSON.parse(node.nodeData.outputSchema));
        } else {
          inInterfaces.push(interfaceName);
          newJsonSchemas.ins.push(JSON.parse(node.nodeData.outputSchema));
        }

        newEditorTypeDefinition = `${newEditorTypeDefinition}

        ${schema2ts(JSON.stringify({ ...JSON.parse(node.nodeData.outputSchema), title: interfaceName }), {
          preffix: "",
          optional: false,
        })}`;
      }
    }

    setEditorTypeDefinition(
      `declare var data: [${dataInterfaces.join(", ")}];\n declare var ins: [${inInterfaces.join(
        ", "
      )}]; \n${newEditorTypeDefinition.replaceAll("export interface", "interface")}`
    );

    setJsonSchemas(newJsonSchemas);
  }, [sourceHandles, targetHandles, edges, nodes]);

  useEffect(() => {
    if (hasFlowOutput) {
      if (sourceHandles.find(({ handleName }) => handleName === "out") == null) {
        updateNodeSourceHandles([
          ...sourceHandles,
          {
            label: "OUT",
            handleName: "out",
            handleType: "source",
            handleCategory: "flow",
          },
        ]);
      }
    } else {
      updateNodeSourceHandles(sourceHandles.filter(({ handleName }) => handleName !== "out"));
    }

    if (hasDataOutput) {
      if (sourceHandles.find(({ handleName }) => handleName === "data") == null) {
        updateNodeSourceHandles([
          ...sourceHandles,
          {
            label: "DATA",
            handleName: "data",
            handleType: "source",
            handleCategory: "data",
          },
        ]);
      }
    } else {
      updateNodeSourceHandles(sourceHandles.filter(({ handleName }) => handleName !== "data"));
    }

    updateNodeInternals(nodeId);
  }, [hasFlowOutput, hasDataOutput]);

  useEffect(() => {
    const currentFlowTargetCount = targetHandles.filter(({ handleName }) => handleName === "in").length;

    if (currentFlowTargetCount === flowInputCount) {
      return;
    }

    const targetHandlesExcludingIn = targetHandles.filter(({ handleName }) => handleName !== "in");

    const targetHandlesIncludingIn = targetHandles.filter(({ handleName }) => handleName === "in");

    if (currentFlowTargetCount < flowInputCount) {
      const handlesToInsert = flowInputCount - currentFlowTargetCount;

      targetHandlesIncludingIn.push(
        ...[...Array(handlesToInsert)].map(() => {
          const targetHandle: TargetHandle = {
            label: "IN",
            handleName: "in",
            handleType: "target",
            handleCategory: "flow",
          };

          return targetHandle;
        })
      );
    } else {
      const handlesToRemove = currentFlowTargetCount - flowInputCount;

      targetHandlesIncludingIn.splice(-handlesToRemove);
    }

    updateNodeTargetHandles([...targetHandlesIncludingIn, ...targetHandlesExcludingIn]);
  }, [flowInputCount]);

  useEffect(() => {
    const currentDataTargetCount = targetHandles.filter(({ handleName }) => handleName === "data").length;

    if (currentDataTargetCount === dataInputCount) {
      return;
    }

    const targetHandlesExcludingData = targetHandles.filter(({ handleName }) => handleName !== "data");

    const targetHandlesIncludingData = targetHandles.filter(({ handleName }) => handleName === "data");

    if (currentDataTargetCount < dataInputCount) {
      const handlesToInsert = dataInputCount - currentDataTargetCount;

      targetHandlesIncludingData.push(
        ...[...Array(handlesToInsert)].map(() => {
          const targetHandle: TargetHandle = {
            label: "DATA",
            handleName: "data",
            handleType: "target",
            handleCategory: "data",
          };

          return targetHandle;
        })
      );
    } else {
      const handlesToRemove = currentDataTargetCount - dataInputCount;

      targetHandlesIncludingData.splice(-handlesToRemove);
    }

    updateNodeTargetHandles([...targetHandlesIncludingData, ...targetHandlesExcludingData]);
  }, [dataInputCount]);

  const prepareJsonSchemaForObjectGeneration = useCallback((schema: JSONSchema7): JSONSchema7 => {
    if (schema.type === "object" && schema.properties) {
      schema.required = Object.keys(schema.properties);
      (schema.additionalProperties as unknown as boolean) = false;

      for (const key of Object.keys(schema.properties)) {
        schema.properties[key] = prepareJsonSchemaForObjectGeneration(schema.properties[key]) as {
          [key: string]: JSONSchema7;
        };
      }
    }

    if (schema.type === "array") {
      if (Array.isArray(schema.items)) {
        schema.items = schema.items.map((item) => prepareJsonSchemaForObjectGeneration(item)) as JSONSchema7Items;
      } else if (schema.items) {
        schema.items = prepareJsonSchemaForObjectGeneration(schema.items as JSONSchema7);
        (schema.additionalItems as unknown as boolean) = false;
        (schema.additionalProperties as unknown as boolean) = false;
      }
    }

    return schema;
  }, []);

  const generateOutputSchema = useCallback(
    (transformationCode: string) => {
      const inputObject = {
        ins: jsonSchemas.ins.map((schema) => JSONSchemaFaker.generate(prepareJsonSchemaForObjectGeneration(schema))),
        data: jsonSchemas.data.map((schema) => JSONSchemaFaker.generate(prepareJsonSchemaForObjectGeneration(schema))),
      };

      const executeTransformation = new Function(...Object.keys(inputObject), transformationCode);

      const outputObject = executeTransformation(...Object.values(inputObject));

      setValue(
        "outputSchema",
        JSON.stringify(
          toJsonSchema(outputObject === undefined ? {} : outputObject, {
            strings: {
              detectFormat: false,
            },
            objects: {
              additionalProperties: false,
              postProcessFnc: (schema) => {
                return {
                  ...schema,
                  required: Object.keys(schema.properties ?? {}),
                };
              },
            },
          }),
          null,
          2
        )
      );

      handleSubmit(handleUpdate)();
    },
    [jsonSchemas]
  );

  return (
    <BaseNodeWithChildren {...props}>
      <form
        className={"nodrag"}
        onSubmit={handleSubmit(handleUpdate)}
        onBlur={handleSubmit(handleUpdate)}
        onChange={handleSubmit(handleUpdate)}
      >
        <FormControl>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              Input Flow Port Count
            </Text>
          </FormLabel>
          <NumberInput defaultValue={0} step={1} min={minFlowInputCount} max={20}>
            <NumberInputField
              {...register("flowInputCount", { valueAsNumber: true, min: minFlowInputCount, max: 20 })}
              color={color}
            />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              Input Data Port Count
            </Text>
          </FormLabel>
          <NumberInput defaultValue={1} step={1} min={minDataInputCount} max={20}>
            <NumberInputField
              {...register("dataInputCount", { valueAsNumber: true, min: minDataInputCount, max: 20 })}
              color={color}
            />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              Output Data Port Enabled
            </Text>
          </FormLabel>
          <Checkbox {...register("hasDataOutput")} disabled={hasDataOutput && !hasFlowOutput} color={color} />
        </FormControl>
        <FormControl>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              Output Flow Port Enabled
            </Text>
          </FormLabel>
          <Checkbox {...register("hasFlowOutput")} disabled={hasFlowOutput && !hasDataOutput} color={color} />
        </FormControl>
        <Button onClick={onOpen} textTransform={"uppercase"} w={"100%"}>
          Configure Transformation
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent minW="md" maxW="container.lg" bg={"theme.dark.background"}>
            <ModalHeader>Configure Transformation</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isInvalid={!formState.isValid}>
                <VStack
                  spacing={4}
                  flex={1}
                  flexDir={"row"}
                  alignItems="flex-start"
                  justifyContent="space-between"
                  w="full"
                >
                  <Box w="58%">
                    <FormLabel>
                      <Text casing={"uppercase"}>JavaScript Code</Text>
                    </FormLabel>
                    <Text fontSize={"md"}>
                      You can access the input data using "ins" and "data" array variables f.e.
                    </Text>
                    <UnorderedList pb={4} fontSize={"sm"}>
                      <ListItem>
                        ins[0].field - field property on an object returned by the node connected to the first IN input
                      </ListItem>
                      <ListItem>
                        data[1].field - field property on an object returned by the node connected to the second DATA
                        input
                      </ListItem>
                    </UnorderedList>
                    <Editor
                      height="46vh"
                      language="javascript"
                      theme="vs-dark"
                      value={transformationCode}
                      onChange={(value) => setValue("transformationCode", value || "")}
                      options={{
                        lineNumbersMinChars: 3,
                        minimap: { enabled: false },
                        wordWrap: "on",
                      }}
                    />
                  </Box>
                  <Box w="38%">
                    <FormLabel>
                      <Text casing={"uppercase"}>Output JSON Schema</Text>
                    </FormLabel>
                    <Editor
                      height="59vh"
                      language="json"
                      theme="vs-dark"
                      value={outputSchema}
                      onChange={(value) => setValue("outputSchema", value || "{}")}
                      options={{
                        lineNumbers: "off",
                        minimap: { enabled: false },
                        wordWrap: "on",
                      }}
                    />
                  </Box>
                </VStack>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                mr={3}
                isDisabled={!formState.isValid}
                onClick={() => generateOutputSchema(watch("transformationCode") || transformationCode)}
                variant={"outline"}
              >
                Auto-suggest schema
              </Button>
              <Button mr={3} isDisabled={!formState.isValid} onClick={onClose} variant={"outline"}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </form>
    </BaseNodeWithChildren>
  );
};

export default memo(TransformDataNode);
