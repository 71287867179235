import React, { memo, PropsWithChildren, useEffect } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { BaseNodeWithChildren } from "./base/BaseNode";
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import useNodeLookup from "../../../hooks/useNodeLookup";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { Editor } from "@monaco-editor/react";
import { useAiCompletionSchemaContext } from "../../../context/AiCompletionSchemaContext";

export type SchemaData = {
  inputSchema?: string;
  outputSchema?: string;
};

interface PassThroughFlowNodeInputPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  color?: string;
  inputSchema?: string;
  outputOnly?: boolean;
}

interface PassThroughFlowNodeOutputOnlyPreviewModalProps extends PassThroughFlowNodeInputPreviewModalProps {
  outputSchema?: string;
}

export const PassThroughNodeInputPreviewModal: React.FC<PassThroughFlowNodeInputPreviewModalProps> = ({
  isOpen,
  onClose,
  color,
  inputSchema,
  outputOnly,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={"theme.dark.background"} borderColor={color} borderRadius={0} borderWidth={1}>
        <ModalHeader>
          <Text color={color}>Schema Preview</Text>
        </ModalHeader>

        <ModalBody>
          <FormControl>
            <FormLabel>
              <Text casing={"uppercase"} color={color}>
                {`${!outputOnly ? "Input &" : ""} Output JSON Schema`}
              </Text>
            </FormLabel>
            <Editor
              theme="vs-dark"
              height={"50vh"}
              language={"json"}
              options={{ readOnly: true }}
              value={inputSchema}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter gap={1}>
          <Button onClick={onClose} color={"white"} variant={"outline"}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const PassThroughNodeOutputOnlyPreviewModal: React.FC<PassThroughFlowNodeOutputOnlyPreviewModalProps> = ({
  isOpen,
  onClose,
  color,
  outputSchema,
}) => {
  return (
    <PassThroughNodeInputPreviewModal
      isOpen={isOpen}
      onClose={onClose}
      color={color}
      inputSchema={outputSchema}
      outputOnly={true}
    />
  );
};

const SchemaPassThroughNodeWithChildren: React.FC<NodeProps<NodeType> & PropsWithChildren> = ({
  children,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getTargetNodeOutputSchema } = useNodeLookup();
  const { updateNodeData } = useUpdateNodeData(props.id);

  const {
    data: { targetHandles = [], color, nodeClass, nodeData = {} },
  } = props;

  const { setInputSchema } = useAiCompletionSchemaContext();

  const nodeSchema = getTargetNodeOutputSchema(
    targetHandles.map(({ handleId }) => handleId),
    { nodeClass }
  );

  useEffect(() => {
    setInputSchema(nodeSchema ?? "{}");

    updateNodeData({
      ...nodeData,
      inputSchema: nodeSchema,
      outputSchema: nodeSchema,
    });
  }, [nodeSchema]);

  return (
    <BaseNodeWithChildren {...props}>
      <Stack p={2}>
        {children}

        <Button onClick={onOpen} color={color} textTransform={"uppercase"} w={"100%"} variant={"outline"}>
          Preview Schema
        </Button>

        <PassThroughNodeInputPreviewModal isOpen={isOpen} onClose={onClose} color={color} inputSchema={nodeSchema} />
      </Stack>
    </BaseNodeWithChildren>
  );
};

export default memo(SchemaPassThroughNodeWithChildren);
